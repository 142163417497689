@media (max-width: 1920px) {
  #forgotPw {
    width: -moz-fit-content;
    width: fit-content;
    min-height: calc(100vh - 338px);
    padding-top: 100px;
  }
  #forgotPw .page-title {
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 20px;
  }
  #forgotPw .page-desc {
    font-size: 14px;
    color: #7e7e7e;
    padding-bottom: 42px;
  }
  #forgotPw .InputBox {
    position: relative;
    height: 90px;
    margin-bottom: 40px;
  }
  #forgotPw .InputBox label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  #forgotPw .InputBox input {
    width: 400px;
    height: 50px;
    display: block;
    border: 1px solid #dcdcdc;
    border-radius: 133.333px;
    padding: 0 20px;
  }
  #forgotPw button {
    width: 400px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #forgotPw .text-danger {
    position: relative;
    top: 4px;
    left: 20px;
    font-size: 12px;
    color: #ce0000;
  }
}