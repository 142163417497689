@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@charset "UTF-8";
.tour-card {
  width: 100%;
  min-height: 308px;
}
.tour-card .card-box {
  position: relative;
  margin-top: 8px;
}

.tour-card .imgArea-link {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}
.tour-card .imgArea {
  border-radius: 12px;
  margin-bottom: 8px;
}
.tour-card .heart-div {
  top: 12px;
  right: 12px;
}
.tour-card .heart-div input {
  display: none;
}
.tour-card .heart-div label {
  cursor: pointer;
}
.tour-card .heart-div label svg {
  width: 28px;
  height: 28px;
}
.tour-card .cardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: keep-all;
  line-height: 22px;
  max-height: 44px;
  font-size: 16px;
  color: #1b1c13;
  font-weight: bold;
  margin-bottom: 6px;
}
.tour-card .location-row {
  width: 218px;
}
.tour-card .location-row p {
  display: block;
  width: calc(100% - 22px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 20px;
}
.tour-card .location-with-comment {
  position: relative;
}
.tour-card .star-with-comment {
  align-items: center;
}
.tour-card .star-with-comment *:not(svg) {
  display: flex;
  align-items: center;
  height: 20px;
  line-height: 20px;
}
.tour-card .star-with-comment .card-comment {
  grid-gap: 4px;
  gap: 4px;
}
.tour-card .star-with-comment .res-star-list {
  grid-gap: 2px;
  gap: 2px;
}
.tour-card .star-with-comment .res-star-list svg {
  margin-bottom: 1.5px;
}
.tour-card .star-with-comment .star-reviews {
  font-size: 14px;
  color: #7e7e7e;
  line-height: 20px;
}
.tour-card .createdBy {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tour-card .tour-total-host {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  right: 0;
}
.tour-card .date-tour-card {
  font-size: 14px;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 20px;
}
@media (max-width: 767px) {
  .tour-card .tour-card {
    grid-template-columns: repeat(auto-fit, minmax(328px, 1fr));
  }
}

.roboto-text .tour-card .cardTitle {
  line-height: 20px;
  max-height: 40px;
}
.roboto-text .tour-card .location-row {
  width: 186px;
}
.roboto-text .tour-card .location-row p {
  position: relative;
  top: 1.2px;
}
@media (max-width: 767px) {
  .roboto-text .tour-card .location-row svg {
    position: relative;
    bottom: 1px;
  }
  .roboto-text .tour-card .location-row p {
    top: 0.6px;
  }
}
.roboto-text .tour-card .res-star-list p {
  position: relative;
  top: 1px;
}
@media (max-width: 767px) {
  .roboto-text .tour-card .res-star-list svg {
    position: relative;
    bottom: 1px;
  }
  .roboto-text .tour-card .res-star-list p {
    top: 0.6px;
  }
}
.roboto-text .tour-card .createdBy {
  width: 259px;
}
@media (max-width: 1920px) {
  .login-modal .modal-content,
  .forgot-modal .modal-content {
    padding: 50px 30px !important;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }
  .login-modal .modal-content .modal-header,
  .forgot-modal .modal-content .modal-header {
    padding: 0;
    height: 24px;
  }
  .login-modal .modal-content .modal-header .mobileClose,
  .forgot-modal .modal-content .modal-header .mobileClose {
    display: none;
  }
  .login-modal .modal-content .modal-header .modal-title,
  .forgot-modal .modal-content .modal-header .modal-title {
    text-align: center;
  }
  .login-modal .modal-content .input-title,
  .forgot-modal .modal-content .input-title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 20px;
    display: block;
  }
  .login-modal .modal-content .input-group,
  .forgot-modal .modal-content .input-group {
    height: 88px;
  }
  .login-modal .modal-content .input-group:not(.mb-30),
  .forgot-modal .modal-content .input-group:not(.mb-30) {
    margin-bottom: 40px;
  }
  .login-modal .modal-content .input-group.mb-30,
  .forgot-modal .modal-content .input-group.mb-30 {
    margin-bottom: 30px;
  }
  .login-modal .modal-content input,
  .forgot-modal .modal-content input {
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    width: 100%;
    padding: 0 20px;
    height: 50px;
    font-size: 14px;
  }
  .login-modal .modal-content input::-webkit-input-placeholder, .forgot-modal .modal-content input::-webkit-input-placeholder {
    color: #aeaeae;
  }
  .login-modal .modal-content input::placeholder,
  .forgot-modal .modal-content input::placeholder {
    color: #aeaeae;
  }
  .login-modal .modal-content .radio-row,
  .forgot-modal .modal-content .radio-row {
    margin: 30px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 60px;
    gap: 60px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    font-size: 13px;
    font-weight: 700;
    color: #666;
  }
  .login-modal .modal-content .radio-row input,
  .forgot-modal .modal-content .radio-row input {
    display: none;
  }
  .login-modal .modal-content .radio-row .checkmark,
  .forgot-modal .modal-content .radio-row .checkmark {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-modal .modal-content .radio-row .checkmark svg,
  .forgot-modal .modal-content .radio-row .checkmark svg {
    width: 18px;
    height: 18px;
  }
  .login-modal .modal-content .radio-row > label,
  .forgot-modal .modal-content .radio-row > label {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
  }
  .login-modal .modal-content button,
  .forgot-modal .modal-content button {
    font-size: 14px;
    font-weight: 700;
  }
  .login-modal .modal-content .loginBtn,
  .forgot-modal .modal-content .loginBtn {
    margin: 40px 0;
    height: 50px;
  }
  .login-modal .modal-content .have-account-row,
  .forgot-modal .modal-content .have-account-row {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .login-modal .modal-content .body-content,
  .forgot-modal .modal-content .body-content {
    text-align: center;
    margin-bottom: 20px;
    padding: 28px 20px !important;
  }
  .login-modal .modal-content .login-form-error,
  .login-modal .modal-content .forgot-form-error,
  .forgot-modal .modal-content .login-form-error,
  .forgot-modal .modal-content .forgot-form-error {
    position: relative;
    color: #ce0000;
    font-size: 12px;
    margin-top: 4px;
    left: 20px;
  }
  .login-modal .modal-content .loading-dim,
  .forgot-modal .modal-content .loading-dim {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
  }
  .login-modal .modal-content .loading-dim .img-box,
  .forgot-modal .modal-content .loading-dim .img-box {
    position: relative;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .login-modal .modal-content .loading-dim .img-box h5,
  .forgot-modal .modal-content .loading-dim .img-box h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #42b6e6;
    text-align: center;
  }
  .login-modal .btn-successBtn,
  .forgot-modal .btn-successBtn {
    margin-top: 20px !important;
  }
  .login-modal .btn-no-line,
  .forgot-modal .btn-no-line {
    height: 16px;
    padding: 0;
  }
  .login-modal button:not(.btn-no-line),
  .forgot-modal button:not(.btn-no-line) {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .modal.login-modal .custom-modal {
    margin: 0;
    padding: 0 !important;
  }
  .modal.login-modal .custom-modal .modal-content {
    width: 100vw;
    height: 100vh;
    padding: 0 16px !important;
    border-radius: 0;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header {
    height: 112px;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .pcClose {
    display: none;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .mobileClose {
    display: flex;
    height: 56px;
    align-items: center;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .custom-modal-title {
    text-align: left;
    display: flex;
    align-items: center;
    height: 56px;
  }
  .modal.login-modal .custom-modal .modal-content .input-title {
    padding-bottom: 8px;
  }
  .modal.login-modal .custom-modal .modal-content .input-group {
    height: 72px;
  }
  .modal.login-modal .custom-modal .modal-content .input-group:not(.mb-30) {
    margin-bottom: 24px;
  }
  .modal.login-modal .custom-modal .modal-content .radio-row {
    margin: 24px auto;
    grid-gap: 40px;
    gap: 40px;
  }
  .modal.login-modal .custom-modal .modal-content .radio-row label {
    grid-gap: 8px;
    gap: 8px;
  }
  .modal.login-modal .custom-modal .modal-content .forgotBtn {
    height: 20px;
  }
  .modal.login-modal .custom-modal .modal-content .loginBtn {
    margin: 24px 0;
  }
  .modal.login-modal .custom-modal .modal-content .login-form-error,
  .modal.login-modal .custom-modal .modal-content .forgot-form-error {
    font-size: 10px;
  }
  .modal.login-modal .custom-modal .modal-content .modal-body {
    height: -moz-fit-content !important;
    height: -webkit-fit-content !important;
    height: fit-content !important;
  }
  .modal.login-modal .custom-modal .modal-content input {
    height: 44px;
  }
  .modal.login-modal .custom-modal .modal-content button:not(.btn-no-line) {
    height: 48px;
  }
  .modal.login-modal .custom-modal .modal-content .body-content {
    padding: 20px !important;
  }
}
@media (max-width: 1920px) {
  #Dashboard {
    margin-top: -70px;
  }
  #Dashboard .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
  }
  #Dashboard .main-banner {
    min-height: 330px;
    padding-top: 118px;
    padding-bottom: 54px;
    background-size: cover;
    background-position: center;
  }
  #Dashboard .main-banner h2 {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
    color: #fff;
    font-weight: 700;
    font-size: 46px;
    margin-bottom: 32px;
    line-height: 56px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Dashboard #searchBar {
    margin: 0 auto;
    max-width: 748px;
    min-height: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    border-radius: 133.333px;
    padding: 7px 7px 7px 24px;
  }
  #Dashboard #searchBar input {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  #Dashboard #searchBar input::-webkit-input-placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar input::placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar .find-city-location {
    max-width: 349px;
    width: 50%;
  }
  #Dashboard #searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #Dashboard #searchBar .find-city-location svg {
    min-width: 20px;
    min-height: 20px;
  }
  #Dashboard #searchBar .find-city-location svg:last-child {
    margin-right: 30px;
  }
  #Dashboard #searchBar .find-city-location input {
    width: calc(100% - 70px);
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #Dashboard #searchBar .search-calendar {
    min-width: 255px;
  }
  #Dashboard #searchBar .search-calendar svg {
    min-width: 20px;
    min-height: 20px;
    margin-left: 30px;
  }
  #Dashboard #searchBar .search-calendar .dateArea {
    min-width: calc(100% - 50px);
  }
  #Dashboard #searchBar .search-calendar .dateArea input {
    width: 100%;
    padding: 0 8px;
  }
  #Dashboard #searchBar .searchBtn {
    min-width: 112px;
    min-height: 56px;
    border-radius: 133.333px;
    background-color: #42b6e6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    grid-gap: 6px;
    gap: 6px;
  }
  #Dashboard .main-contents-btn {
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
  }
  #Dashboard .main-contents-btn svg {
    width: 28px;
    height: 28px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents-btn button {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #ffffff;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 133.333px;
    min-height: 50px;
    padding: 0 20px;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
  }
  #Dashboard .mobileBtn {
    display: none;
  }
  #Dashboard .host-my-own.pcBtn {
    grid-gap: 4px;
    gap: 4px;
    position: absolute;
    border: 1px solid #ffffff;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 133.333px;
    width: 172px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    top: 50%;
    left: calc(50% + 472px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 14px;
  }
  #Dashboard .viewHostBtn {
    width: 83px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    border-radius: 133.333px;
  }
  #Dashboard .main-contents {
    background-color: #fafafa;
    padding-top: 28px;
    padding-bottom: 90px;
  }
  #Dashboard .main-contents .layout {
    grid-gap: 40px;
    gap: 40px;
  }
  #Dashboard .main-contents .layout .main-left {
    color: #1b1c13;
    min-width: 264px;
    max-width: 264px;
  }
  #Dashboard .main-contents .layout .main-left > * {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
    padding: 20px;
  }
  #Dashboard .main-contents .layout .main-left .heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }
  #Dashboard .main-contents .layout .main-left .container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0;
    grid-gap: 16px;
    gap: 16px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-number {
    font-size: 14px;
    font-weight: 700;
    width: 28px;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-pro-content {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    grid-gap: 16px;
    gap: 16px;
    color: #1b1c13;
    height: 40px;
    cursor: pointer;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-img {
    width: 36px;
    height: 36px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content {
    width: calc(100% - 92px);
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h6 {
    font-size: 12px;
    color: #7e7e7e;
    line-height: 16px;
    max-height: 16px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #Dashboard .main-contents .layout .main-left .trending-now-section {
    margin-bottom: 20px;
  }
  #Dashboard .main-contents .layout .main-left .popular-host-section .position-absolute {
    right: 0;
  }
  #Dashboard .main-contents .layout .main-right {
    width: calc(100% - 286px);
  }
  #Dashboard .main-contents .layout .main-right .pagination {
    margin: 0 auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    grid-gap: 4px;
    gap: 4px;
  }
  #Dashboard .main-contents .layout .main-right .pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Dashboard .main-contents .layout .main-right .pagination li a {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    color: #7e7e7e;
    box-sizing: border-box;
  }
  #Dashboard .main-contents .layout .main-right .pagination li.active {
    border-radius: 100%;
    border: 1px solid #42b6e6;
  }
  #Dashboard .main-contents .layout .main-right .pagination li.active a {
    color: #42b6e6;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll {
    margin-bottom: 28px;
    height: 36px;
    overflow: hidden;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn,
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn {
    position: absolute;
    padding: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn:before {
    content: "";
    display: block;
    position: absolute;
    width: 98px;
    height: 36px;
    left: -1px;
    top: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 41.11%);
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
            transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn svg {
    z-index: 1;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn {
    right: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn:before {
    content: "";
    display: block;
    position: absolute;
    width: 98px;
    height: 36px;
    right: -1px;
    top: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 41.11%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn svg {
    z-index: 1;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list {
    grid-gap: 8px;
    gap: 8px;
    position: absolute;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    transition: all 3s ease 0s;
    left: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
    box-sizing: border-box;
    font-weight: 400;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list button.active {
    background-color: #1b1c13;
    color: #fff;
    font-weight: 700;
    border: none;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider {
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-prev {
    display: none !important;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-next {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 0;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list {
    overflow: hidden;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 98px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) -10%, #ffffff 55%);
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track {
    display: flex;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track .slick-slide {
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
    width: fit-content !important;
    margin-right: 8px;
    cursor: pointer;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track .slick-slide.slick-active button {
    background-color: #1b1c13 !important;
    color: #fff;
    opacity: 1;
  }
  #Dashboard .main-contents .layout .main-right .content-card-tour-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members-item {
    width: 100%;
    min-height: 160px;
    text-align: center;
    justify-content: center;
  }

  #Dashboard .main-contents .layout .main-right .content-card-tour-list:not(:last-child) {
    margin-bottom: 50px;
  }
  #Dashboard .searchContainer {
    z-index: 2;
    padding: 40px;
  }
  #Dashboard .searchContainer .custom-container {
    grid-gap: 38px;
    gap: 38px;
  }
}
@media (max-width: 767px) {
  #Dashboard {
    margin-top: 0;
  }
  #Dashboard #searchBar {
    width: 80%;
    max-width: 80%;
    padding: 6px 6px 6px 12px;
  }
  #Dashboard #searchBar.searchBar {
    min-width: 328px;
    position: relative;
    min-height: 48px;
    margin-bottom: 20px;
  }
  #Dashboard #searchBar.searchBar input {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #1b1c13;
  }
  #Dashboard #searchBar.searchBar input::-webkit-input-placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar.searchBar input::placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar.searchBar .find-city-location {
    min-width: 138px;
    width: 50%;
  }
  #Dashboard #searchBar.searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #Dashboard #searchBar.searchBar .find-city-location svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #Dashboard #searchBar.searchBar .find-city-location svg:last-child {
    margin-right: 12px;
  }
  #Dashboard #searchBar.searchBar .find-city-location input {
    width: calc(100% - 48px);
    margin-left: 4px;
    padding: 0;
  }
  #Dashboard #searchBar.searchBar .search-calendar {
    min-width: 138px !important;
    width: 50%;
  }
  #Dashboard #searchBar.searchBar .search-calendar svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
    margin-left: 12px;
    margin-right: 4px;
  }
  #Dashboard #searchBar.searchBar .search-calendar .dateArea {
    width: calc(100% - 32px);
  }
  #Dashboard #searchBar.searchBar .search-calendar .dateArea input {
    padding: 0;
    min-width: unset;
  }
  #Dashboard #searchBar.searchBar .searchIcon {
    min-width: 36px !important;
    min-height: 36px !important;
    max-width: 36px !important;
    max-height: 36px !important;
    background-color: #42b6e6;
    border-radius: 100%;
  }
  #Dashboard #searchBar.searchBar .searchIcon span {
    display: none;
  }
  #Dashboard #searchBar.searchBar .searchIcon svg {
    width: 30px;
    height: 30px;
    aspect-ratio: 1/1;
  }
  #Dashboard #searchBar.searchBar .searchBtn {
    min-width: 56px;
  }
  #Dashboard .main-banner-contents h2 {
    display: none;
  }
  #Dashboard .main-banner {
    padding: 56px 0 36px;
    min-height: unset;
  }
  #Dashboard .main-contents-btn {
    grid-gap: 8px;
    gap: 8px;
    justify-content: center;
  }
  #Dashboard .main-contents-btn svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents-btn button {
    width: 130px;
    grid-gap: 2px;
    gap: 2px;
    min-height: 32px;
    font-size: 12px;
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    border: 0.8px solid #ffffff;
    -webkit-backdrop-filter: blur(1.65335px);
            backdrop-filter: blur(1.65335px);
  }
  #Dashboard .main-contents {
    padding: 0;
  }
  #Dashboard .main-contents .layout {
    background-color: #f9f9f9;
    width: 100%;
    flex-direction: column-reverse;
    grid-gap: 12px;
    gap: 12px;
  }
  #Dashboard .main-contents .layout .main-left,
  #Dashboard .main-contents .layout .main-right {
    width: 100%;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll {
    padding: 16px !important;
    padding-right: 0;
    background-color: #fff;
    width: 100vw;
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    z-index: 1;
    margin-bottom: 0;
    height: 68px;
    grid-gap: 8px;
    gap: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .d-flex {
    grid-gap: 8px;
    gap: 8px;
    width: -webkit-max-content;
    width: max-content;
    padding-right: 16px;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .d-flex .btn-mainTab.active {
    background-color: #1b1c13;
    color: #fff;
    font-weight: 700;
    border: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .slick-next {
    right: 16px;
    display: none !important;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .slick-list:after {
    display: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class {
    overflow-x: auto;
    height: 58px;
    padding: 16px 16px 0;
    background-color: #fff;
    grid-gap: 24px;
    grid-gap: 24px;
    gap: 24px;
    z-index: 1;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class::-webkit-scrollbar {
    display: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class li {
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    padding-bottom: 16px;
    font-size: 14px;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class li.tab-card-active:after {
    content: "";
    bottom: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-line {
    margin-top: -2px;
  }
  #Dashboard .main-contents .layout .main-right .mobile-card {
    width: 100%;
    padding: 0 16px 20px;
    background-color: #fff;
  }
  #Dashboard .main-contents .layout .main-right .mobile-card .content-card-tour-list {
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    grid-gap: 32px;
    gap: 32px;
  }

  .popular-members {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members-item {
    width: 100%;
    min-height: 60px;
    text-align: center;
    justify-content: center;
  }

  #Dashboard .main-contents .layout .main-right .mobile-card .content-card-tour-list .tour-card {
    min-height: unset;
  }
  #Dashboard .main-contents .layout .main-left {
    width: 100%;
    max-width: unset;
  }
  #Dashboard .main-contents .layout .main-left > * {
    border-radius: 0;
    padding: 20px 16px;
  }
  #Dashboard .main-contents .layout .main-left .trending-now-section {
    margin-bottom: 12px;
  }
  #Dashboard .main-contents .layout .main-left .heading {
    padding: 0;
    border-bottom: none;
  }
  #Dashboard .main-contents .layout .main-left .container {
    padding: 0;
    margin-top: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row,
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    border-radius: 20px;
    border-radius: 8px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row {
    padding: 0;
    grid-gap: 12px;
    gap: 12px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-number {
    font-size: 16px;
    width: 30px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-pro-content {
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    padding: 0;
    height: 40px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-img {
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-flag {
    width: 25.45px;
    height: 25.45px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content {
    width: calc(100% - 96.45px);
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h6 {
    font-size: 14px;
  }
  #Dashboard .main-contents .layout .mobile-more-btn {
    width: 100%;
    height: 44px;
    font-weight: 700;
    font-size: 14px;
    margin: 20px 0 12px;
  }
  #Dashboard .searchContainer {
    width: 100%;
    min-width: unset;
    top: 50px;
    padding: 20px;
  }
  #Dashboard .searchContainer .country_list label:hover {
    color: inherit !important;
    border-color: #dcdcdc;
    font-weight: inherit;
  }
  #Dashboard .pc-calendar {
    display: none;
  }
  #Dashboard .mobile-calendar {
    display: block !important;
  }
  #Dashboard .mobileBtn {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    margin: 0 auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
  }
  #Dashboard .mobileBtn button {
    border: 1px solid #fff;
    -webkit-backdrop-filter: blur(1.65335px);
            backdrop-filter: blur(1.65335px);
    width: 130px;
    height: 32px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
  }
  #Dashboard .pcBtn {
    display: none !important;
  }
}
@media (max-width: 1920px) {
  #Footer {
    background-color: #f9f9f9;
    padding: 60px;
    border-top: 1px solid #dcdcdc;
    height: 168px;
  }
  #Footer.main-footer {
    padding: 60px 0;
  }
  #Footer.main-footer .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
    padding-left: 304px;
  }
  #Footer h6 {
    font-weight: 400;
  }
  #Footer .footer-inner {
    grid-gap: 48px;
    gap: 48px;
  }
  #Footer .footer-inner .logo {
    width: 120px;
    display: block;
  }
  #Footer .footer-inner .logo svg path {
    fill: #42b6e6;
  }
  #Footer .footer-inner .list-of-links {
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 8px;
  }
  #Footer .footer-inner .list-of-links * {
    font-size: 14px;
    color: #1b1c13;
  }
  #Footer .footer-inner .list-of-links h6 {
    cursor: pointer;
  }
  #Footer .footer-inner .copy-section {
    font-size: 14px;
    color: #7e7e7e;
  }
}
@media (max-width: 767px) {
  #Footer {
    padding: 28px 16px 60px;
  }
  #Footer.main-footer {
    padding: 28px 16px 60px;
  }
  #Footer.main-footer .wrap {
    width: 100%;
    padding-left: 0;
  }
  #Footer .footer-inner {
    flex-wrap: wrap;
    margin: 0;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }
  #Footer .footer-inner .logo {
    width: 94px;
  }
  #Footer .footer-inner .copy-section {
    font-size: 12px;
  }
  #Footer .footer-inner .list-of-links {
    grid-gap: 5px;
    gap: 5px;
  }
}
#HostMyOwn input[type=checkbox],
#HostMyOwn input[type=radio] {
  display: none;
}
@media (max-width: 767px) {
  #HostMyOwn {
    padding: 8px 0 24px !important;
  }
  #HostMyOwn .wrap {
    padding: 0 !important;
    margin: 0;
    width: 100%;
  }
  #HostMyOwn .wrap > * {
    padding: 0 16px;
  }
  #HostMyOwn .wrap .tourTitle {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
    align-items: center;
  }
  #HostMyOwn .wrap .tourTitle img {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
  #HostMyOwn .wrap input[type=checkbox],
  #HostMyOwn .wrap input[type=radio] {
    display: none;
  }
  #HostMyOwn .wrap .host-title-with-add {
    flex-direction: column;
    padding-bottom: 24px;
  }
  #HostMyOwn .wrap .host-title-with-add h3 {
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 8px !important;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group {
    grid-gap: 8px;
    gap: 8px;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group button {
    font-size: 14px;
    border-radius: 133.333px;
    height: 44px;
    min-width: 107px;
    padding: 0;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group .add-to-my-wishlist {
    min-width: 213px;
  }
  #HostMyOwn .wrap .add-places,
  #HostMyOwn .wrap .mainAddEventBorder {
    padding: 24px 0 !important;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .wrap .mainAddEventBorder {
    grid-gap: 4px;
    gap: 4px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses {
    height: 40px;
    padding: 0 16px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .num-with-title {
    grid-gap: 8px;
    gap: 8px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .num-with-title .round-number {
    width: 24px;
    height: 24px;
    background-color: #42b6e6;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .selectedLabelInfo {
    display: none !important;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn {
    flex-direction: row-reverse;
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .btn {
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    min-width: unset;
    padding: 0;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .mobile-btn {
    display: block !important;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .pc-btn {
    display: none !important;
  }
  #HostMyOwn .wrap .selected-img {
    padding: 24px 16px;
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .wrap .selected-img .imgArea {
    width: 100%;
    aspect-ratio: 4/3;
    border-radius: 8px;
    overflow: hidden;
  }
  #HostMyOwn .wrap .thumbnail-upload {
    margin: 24px 16px 0;
    padding: 0;
    height: 44px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    width: calc(100% - 32px);
  }
  #HostMyOwn .wrap .thumbnail-upload input {
    display: none;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name {
    width: 100% !important;
  }
  #HostMyOwn .wrap .thumbnail-upload .fileUploadLabel {
    background-color: #42b6e6;
    color: #fff;
    font-size: 12px;
    height: 44px;
    line-height: 14px;
    font-weight: 700;
    max-width: 140px;
    padding: 7.5px 9px;
    display: inline !important;
  }
  #HostMyOwn .wrap .thumbnail-upload .fileUploadLabel p {
    display: inline !important;
    font-weight: 400;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name {
    width: calc(100% - 140px) !important;
    padding: 0 8px;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name a {
    color: #42b6e6;
    font-weight: 700;
  }
  #HostMyOwn .wrap .optional-label {
    color: #42b6e6;
    font-size: 12px;
    padding: 8px 16px 24px;
  }
  #HostMyOwn .wrap .label-groups {
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  #HostMyOwn .wrap .label-groups label {
    height: 40px;
    border-radius: 133.333px;
    min-width: 158px;
    border: 1px solid #dcdcdc;
    color: #7e7e7e;
  }
  #HostMyOwn .wrap .user-basic-info {
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 24px;
  }
  #HostMyOwn .wrap .user-basic-info .user-img {
    width: 72px;
    aspect-ratio: 1/1;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info {
    grid-gap: 4px;
    gap: 4px;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info .info-title h6 {
    font-size: 14px;
    color: #7e7e7e;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info .info-title p {
    font-size: 14px;
    color: #1b1c13;
    font-weight: 700;
  }
  #HostMyOwn .wrap .search-calendar {
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .wrap .search-calendar input {
    margin-bottom: 0;
  }
  #HostMyOwn .wrap .search-calendar .input-calendar {
    width: 100%;
    max-width: none;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    height: 44px;
    overflow: hidden;
    padding-left: 12px;
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .wrap .search-calendar .input-calendar input {
    width: 100%;
    border: none;
    margin-bottom: 0;
    padding: 0;
  }
  #HostMyOwn .wrap .search-calendar input[name=startsAt] {
    width: 100%;
    max-width: none;
  }
  #HostMyOwn .wrap .search-calendar .select {
    width: calc(50% - 6px);
    min-width: 158px;
  }
  #HostMyOwn .wrap .search-calendar .select .dateArea-select__control {
    width: 100%;
    margin: 0 !important;
  }
  #HostMyOwn .wrap .search-calendar .select .dateArea-select__control .dateArea-select__indicator-separator {
    display: none;
  }
  #HostMyOwn .wrap .search-calendar .divder {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  #HostMyOwn .wrap .trasporttaion {
    width: 100%;
  }
  #HostMyOwn .wrap .trasporttaion input {
    width: 100% !important;
  }
  #HostMyOwn input:not([type=text]) {
    display: none;
  }
  #HostMyOwn input:not([type=text]):checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #HostMyOwn input[name=startsAt] {
    width: 100%;
  }
  #HostMyOwn .single-day-hosting {
    color: #42b6e6;
    padding: 4px 16px 24px;
  }
  #HostMyOwn .paxnumber {
    width: 100% !important;
  }
  #HostMyOwn .paxnumber input {
    width: 100% !important;
  }
  #HostMyOwn .paxnumber .paxnumText {
    right: 16px;
  }
  #HostMyOwn textarea {
    width: 100%;
    resize: none;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    border-color: #dcdcdc;
  }
  #HostMyOwn .max-length {
    bottom: 40px !important;
    right: 16px;
  }
  #HostMyOwn .editorinfomation {
    margin-bottom: 24px;
  }
  #HostMyOwn .btn-groups {
    grid-gap: 12px;
    gap: 12px;
    padding: 0 !important;
  }
  #HostMyOwn .btn-groups button {
    width: calc(50% - 6px);
    min-width: unset !important;
    border-radius: 133.333px;
    height: 48px;
  }
  #HostMyOwn .user-info {
    border-top: 1px solid #ebebeb;
    padding: 24px 0 0 !important;
  }
  #HostMyOwn .tourAbout {
    padding-bottom: 0 !important;
  }
  #HostMyOwn .tour-info {
    padding: 24px 0 0 !important;
    border-bottom: none !important;
  }
  #HostMyOwn .tour-introduction {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1920px) {
  #HostMyOwn {
    padding: 75px 0 80px;
  }
  #HostMyOwn .wrap > * {
    width: 100%;
  }
  #HostMyOwn .wrap .divder {
    font-size: 20px;
    width: 12px;
    display: block;
    text-align: center;
  }
  #HostMyOwn label,
  #HostMyOwn .schedule,
  #HostMyOwn .input-calendar {
    cursor: pointer;
  }
  #HostMyOwn input:checked ~ label {
    background-color: #ecf8fd;
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
  }
  #HostMyOwn input[type=text],
  #HostMyOwn textarea {
    resize: none;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn input[type=text][name=pax],
  #HostMyOwn textarea[name=pax] {
    border: none !important;
  }
  #HostMyOwn .dateArea-select__placeholder,
  #HostMyOwn .paxnumText {
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn input[name=title] {
    height: 50px;
    margin-bottom: 40px;
  }
  #HostMyOwn textarea {
    padding: 16px;
    width: 100%;
  }
  #HostMyOwn textarea:focus {
    outline: none;
  }
  #HostMyOwn textarea::-webkit-scrollbar {
    display: none;
  }
  #HostMyOwn .host-title-with-add {
    padding-bottom: 20px;
  }
  #HostMyOwn .host-title-with-add h3 {
    font-weight: 700;
    font-size: 30px;
  }
  #HostMyOwn .add-btns-group {
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .add-btns-group button {
    min-width: 110px;
    padding: 0 25px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 133.333px;
  }
  #HostMyOwn .view-section-itinerary {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .view-section-itinerary .add-places {
    padding: 100px 0;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
  }
  #HostMyOwn .view-section-itinerary .add-places img {
    margin-left: 4px;
  }
  #HostMyOwn .mainAddEventBorder {
    grid-gap: 16px;
    gap: 16px;
    padding: 40px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title {
    grid-gap: 16px;
    gap: 16px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title .round-number {
    width: 24px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    color: #fff;
    background-color: #42b6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title .addEventTitle {
    font-size: 14px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn {
    grid-gap: 8px;
    gap: 8px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .mobile-btn {
    display: none !important;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button,
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo {
    border-radius: 133.333px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button img,
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo img {
    display: none;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo {
    color: #42b6e6;
    border: 1px solid #42b6e6;
    font-weight: 700;
    padding: 0 16px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button {
    min-width: 74px;
  }
  #HostMyOwn .selected-img {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(166px, 1fr));
    width: 100%;
    padding: 30px 0;
    grid-gap: 20px;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .selected-img .imgArea {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 4/3;
  }
  #HostMyOwn .thumbnail-upload {
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0 0;
  }
  #HostMyOwn .thumbnail-upload input {
    display: none;
  }
  #HostMyOwn .thumbnail-upload label {
    width: 158px;
    height: 50px;
    background-color: #42b6e6;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    align-items: center;
  }
  #HostMyOwn .thumbnail-upload label p {
    font-weight: 400;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name {
    width: calc(100% - 158px);
    border: 1px solid #e7e7e7;
    border-radius: 0 8px 8px 0;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 700;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name a {
    color: #42b6e6;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #HostMyOwn .optional-label {
    padding: 4px 0 40px;
    font-size: 14px;
    color: #42b6e6;
  }
  #HostMyOwn .tourTitle {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  #HostMyOwn .tourTitle img {
    margin-left: 4px;
  }
  #HostMyOwn .tourTitle span {
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
    color: #42b6e6;
  }
  #HostMyOwn .tourAbout {
    padding-bottom: 40px;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .tourAbout input {
    width: 100%;
    height: 50px;
    border: 1px solid #dcdcdc;
    margin-bottom: 40px;
  }
  #HostMyOwn .label-groups {
    grid-gap: 12px;
    gap: 12px;
    color: #7e7e7e;
  }
  #HostMyOwn .label-groups label {
    min-width: 160px;
    max-width: 160px;
    height: 46px;
    border: 1px solid #c9c9c9;
    border-radius: 133.333px;
    font-size: 14px;
  }
  #HostMyOwn .label-groups .trasporttaion {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
  }
  #HostMyOwn .label-groups .trasporttaion input {
    height: 50px;
  }
  #HostMyOwn .create-my-itinerary .user-info {
    padding: 40px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info {
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 40px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .user-img {
    width: 80px;
    aspect-ratio: 1/1;
    overflow: hidden;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info {
    grid-gap: 12px;
    gap: 12px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title {
    font-size: 14px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title h6 {
    color: #7e7e7e;
    min-width: 90px;
    font-weight: 400;
    font-size: 14px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title p {
    font-size: 14px;
    font-weight: 700;
  }
  #HostMyOwn .create-my-itinerary .tour-info {
    padding: 40px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .create-my-itinerary .tour-info input {
    height: 50px;
    border: 1px solid #c9c9c9;
  }
  #HostMyOwn .create-my-itinerary .tour-info .createContainer {
    top: 55px;
  }
  #HostMyOwn .search-calendar {
    width: 100%;
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: wrap;
  }
  #HostMyOwn .search-calendar .input-calendar,
  #HostMyOwn .search-calendar .dateArea-select__control,
  #HostMyOwn .search-calendar input[name=startsAt] {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    height: 50px;
  }
  #HostMyOwn .search-calendar .input-calendar {
    min-width: 268px;
    grid-gap: 12px;
    gap: 12px;
    padding: 0 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    overflow: hidden;
  }
  #HostMyOwn .search-calendar .input-calendar input {
    max-width: 186px;
    padding: 0;
    border: none;
    box-sizing: border-box;
  }
  #HostMyOwn .search-calendar .select {
    width: 160px;
  }
  #HostMyOwn .search-calendar .select * {
    cursor: pointer;
  }
  #HostMyOwn .search-calendar .select .dateArea-select__indicator-separator {
    display: none;
  }
  #HostMyOwn .search-calendar input[name=startsAt] {
    width: calc(100% - 648px);
    min-width: 328px;
    max-width: 452px;
    padding: 0 16px;
  }
  #HostMyOwn .single-day-hosting {
    width: 100%;
    color: #42b6e6;
    padding: 4px 0 40px;
    font-size: 14px;
  }
  #HostMyOwn .tour-transportation .label-groups {
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  #HostMyOwn .tour-transportation label {
    padding: 0 10px;
    min-width: 150px;
    max-width: 150px;
    height: 50px;
    text-align: center;
  }
  #HostMyOwn .tour-transportation .trasporttaion {
    width: 452px;
    border-radius: 8px;
    padding: 0 16px;
    border-color: #dcdcdc !important;
  }
  #HostMyOwn .paxnumber {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin-bottom: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #HostMyOwn .paxnumber input {
    width: 440px;
    height: 50px;
  }
  #HostMyOwn .paxnumber .paxnumText {
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  #HostMyOwn .tour-introduction {
    margin-bottom: 40px;
  }
  #HostMyOwn .tour-introduction .max-length {
    right: 16px;
    bottom: 16px;
    font-size: 14px;
  }
  #HostMyOwn .editorinfomation p {
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn .btn-groups {
    padding: 40px 0 80px;
    grid-gap: 20px;
    gap: 20px;
  }
  #HostMyOwn .btn-groups button {
    min-width: 296px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
  }
  #HostMyOwn .react-datepicker__day--disabled {
    opacity: 0.4;
  }
  #HostMyOwn .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #HostMyOwn .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: #000;
  }
}
#HostMyOwn.admin-create .tour-info {
  border-bottom: none;
  padding-bottom: 0;
}
#HostMyOwn.admin-create .btn-groups {
  padding-top: 0;
}

.create-hosting {
  margin-top: 40px;
}
.create-hosting #HostMyOwn {
  padding: 0;
}
.create-hosting #HostMyOwn .wrap {
  margin: 0;
  width: 100%;
  padding: 0;
}

.roboto-text #HostMyOwn input {
  font-size: 14px;
}
.roboto-text #HostMyOwn .info-title h6 {
  font-size: 14px;
  font-weight: 400;
}
.roboto-text #HostMyOwn .info-title p {
  font-size: 14px;
}
@media (max-width: 1920px) {
  #ViewHost {
    padding: 75px 0 80px;
  }
  #ViewHost .layout {
    grid-gap: 54px;
    gap: 54px;
  }
  #ViewHost .layout .left {
    min-width: 264px;
    max-width: 264px;
  }
  #ViewHost .layout .left .page-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  #ViewHost .layout .left .filter-tab-view-host {
    padding: 32px 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  #ViewHost .layout .left .filter-tab-view-host .tab-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 16px;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input {
    display: none;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup label {
    border: 1px solid #c9c9c9;
    color: #7e7e7e;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
    height: 46px;
    font-size: 14px;
    cursor: pointer;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #ViewHost .layout .right {
    margin-top: 60px;
    min-width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    border-top: 1px solid #e7e7e7;
  }
  #ViewHost .layout .right .view-host-list {
    display: flex;
    flex-wrap: wrap;
  }
  #ViewHost .layout .right .view-host-list .single-local-host {
    width: 50%;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
    grid-gap: 16px;
    gap: 16px;
    cursor: pointer;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .host-img {
    width: 54px;
    height: 54px;
    aspect-ratio: 1/1;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .flag-wrap {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #ViewHost .layout .right .view-host-list .single-local-host .name-with-flag {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah {
    grid-gap: 4px;
    gap: 4px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah > div {
    padding: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 133.333px;
    border: 1px solid #7e7e7e;
    font-weight: 700;
    font-size: 10px;
  }
  #ViewHost .layout .right .pagination {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin: 40px auto 0;
  }
  #ViewHost .layout .right .pagination li {
    min-width: 28px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
  }
  #ViewHost .layout .right .pagination li a {
    color: #7e7e7e;
    font-size: 14px;
  }
  #ViewHost .layout .right .pagination li.active {
    border-radius: 100%;
    border: 1px solid #42b6e6;
  }
  #ViewHost .layout .right .pagination li.active a {
    color: #42b6e6;
    font-weight: 700;
  }
  #ViewHost .layout .right .pagination li:not(.disabled) svg path {
    stroke: #42b6e6;
  }
  #ViewHost .layout .right .pagination li.disabled a {
    cursor: default !important;
  }
  #ViewHost .layout .right .pagination li svg {
    width: 20px;
    height: 20px;
  }
  #ViewHost .layout .right .pagination .page-first-arrow,
  #ViewHost .layout .right .pagination .page-prev-arrow {
    margin-right: 8px;
  }
  #ViewHost .layout .right .pagination .page-next-arrow,
  #ViewHost .layout .right .pagination .page-last-arrow {
    margin-left: 8px;
  }
}
@media (max-width: 767px) {
  #ViewHost {
    padding: 0;
  }
  #ViewHost .layout {
    min-height: calc(100vh - 233px);
    grid-gap: 0;
    gap: 0;
    flex-direction: column;
  }
  #ViewHost .layout .left {
    width: 100vw;
    margin-left: -16px;
    max-width: unset;
  }
  #ViewHost .layout .left .page-title {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding: 0 16px;
    margin-top: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host {
    padding: 20px 24px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    box-shadow: none;
    border-radius: 0;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  #ViewHost .layout .left .filter-tab-view-host > div {
    display: flex;
    justify-content: space-between;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox {
    display: flex;
    flex-direction: row !important;
    width: 220px;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox .inputGroup {
    width: calc(50% - 4px);
  }
  #ViewHost .layout .left .filter-tab-view-host .tab-title {
    font-size: 14px;
    padding-bottom: 0;
    line-height: 32px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input {
    display: none;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup label {
    font-size: 14px;
    height: 32px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #ViewHost .layout .right {
    margin-top: 0;
    width: 100%;
    max-width: unset;
    border-top: 1px solid #e7e7e7;
  }
  #ViewHost .layout .right .view-host-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 8px;
    grid-gap: 20px;
    gap: 20px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host {
    width: 100%;
    padding: 0;
    border-bottom: none;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .host-img {
    width: 48px;
    height: 48px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .name-with-flag {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah {
    grid-gap: 4px;
    gap: 4px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah > div {
    padding: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 133.333px;
    border: 1px solid #7e7e7e;
    font-weight: 700;
    font-size: 12px;
  }
  #ViewHost .layout .right .res-more-viewhost button {
    height: 44px;
  }
}
@media (max-width: 1920px) {
  #ItineraryDetail {
    padding: 45px 0 80px;
  }
  #ItineraryDetail .gm-style-iw p {
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: center !important;
  }
  #ItineraryDetail .gm-style-iw button {
    display: none !important;
  }
  #ItineraryDetail .img-area {
    width: 100%;
    max-height: 440px;
    grid-gap: 8px;
    gap: 8px;
    overflow: hidden;
    border-radius: 20px;
    aspect-ratio: 16/9;
    margin-bottom: 10px;
  }
  #ItineraryDetail .img-area .big-img {
    width: calc(100% - 368px);
    position: relative;
  }
  #ItineraryDetail .img-area .big-img .itinerary-viewAll-btn {
    position: absolute;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    right: 20px;
    bottom: 30px;
    width: 140px;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .img-area .small-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 8px;
    gap: 8px;
    width: 360px;
  }
  #ItineraryDetail .img-area .small-img .imgArea {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #ItineraryDetail .layout {
    grid-gap: 40px;
    gap: 40px;
  }
  #ItineraryDetail .layout .left {
    width: 75%;
    max-width: calc(100% - 400px);
  }
  #ItineraryDetail .layout .left .ratingStarArea {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .mobile-host-list {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .sidebar-host-button button,
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host button {
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .itinerary-title {
    width: 584px;
    line-height: 40px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    word-break: keep-all;
  }
  #ItineraryDetail .layout .left .heart-section input {
    display: none;
  }
  #ItineraryDetail .layout .left .heart-section svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 16px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event svg {
    width: 18px;
    height: 18px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event span {
    position: relative;
    bottom: 1.5px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event {
    grid-gap: 6px;
    gap: 6px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar {
    width: 108px;
    grid-gap: 2px;
    gap: 2px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol {
    display: flex !important;
    align-items: center;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar *,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol *,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol * {
    display: flex;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar svg,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol svg,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar span,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol span,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol span {
    width: 20px;
    height: 20px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total {
    font-size: 16px;
    line-height: 19.2px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total a {
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .date-start-end {
    display: block;
    font-size: 16px;
    color: #7e7e7e;
    margin-bottom: 6px;
    line-height: 19.2px;
  }
  #ItineraryDetail .layout .left .createdby {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
    line-height: 25.6px;
  }
  #ItineraryDetail .layout .left .itinerary-subtitle {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 25.6px;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    grid-gap: 20px;
    gap: 20px;
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 48px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    grid-gap: 16px;
    gap: 16px;
    width: calc(100% - 20px);
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-num {
    width: 24px;
    height: 24px;
    background-color: #42b6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 45px);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc {
    padding: 32px 0;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    grid-gap: 19px;
    gap: 19px;
    margin-top: -6px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc > div {
    width: calc(100% - 78px);
    margin: 0 auto;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-left {
    width: 128px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right {
    width: calc(100% - 128px);
    font-size: 14px;
    line-height: 20px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right a {
    display: block;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 14px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
    grid-gap: 12px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary .imgArea {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 4/3;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row {
    text-align: center;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    min-width: 160px;
    height: 40px;
    font-weight: 700;
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .about-tour-event {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .about-map {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .about-map h6 {
    font-size: 14px;
    color: #1b1c13;
    margin-bottom: 12px;
    line-height: 20px;
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab {
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab.only-for-host-review {
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab.only-for-host-review .title-deactive {
    border-bottom: none;
    text-align: left;
    padding-bottom: 0;
    height: 46px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3 {
    height: 58px;
    line-height: 20px;
    width: 50%;
    padding: 20px 0 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-active {
    border-bottom: 2px solid #42b6e6;
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-deactive {
    border-bottom: 2px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea {
    width: 100%;
    resize: none;
    padding: 16px;
    border-radius: 8px;
    border-color: #dcdcdc;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea:focus {
    outline: none;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea::-webkit-input-placeholder {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea::placeholder {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment {
    justify-content: end;
    grid-gap: 10px;
    gap: 10px;
    padding-bottom: 24px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment button {
    width: 110px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn:not(:last-child) {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn button {
    height: 40px;
    width: 110px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .chat-reply-inner .single-reply-inner {
    width: calc(100% - 112px) !important;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply {
    padding: 10px 0;
    grid-gap: 20px;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .recomment-image svg {
    width: 36px;
    height: 36px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .host-img {
    width: 36px;
    height: 36px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner {
    width: calc(100% - 56px);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .comment-date {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .full-comment-section {
    margin-top: 5px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .reply-delete-btn {
    font-size: 10px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content {
    font-size: 14px;
    color: #1b1c13;
    line-height: 22.4px;
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
    font-weight: 400;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content.deleted-comment {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner > .comment-reply {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn {
    margin-bottom: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn button {
    color: #1b1c13;
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn.less svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner button.reply, #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner button.delete {
    color: #7e7e7e;
    line-height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment,
  #ItineraryDetail .layout .left .total-review-about .comment-inner .moreComment {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    grid-gap: 4px;
    gap: 4px;
    min-width: 160px;
    justify-content: center;
    padding: 0;
    height: 40px;
    padding: 0 24px 0 32px;
    margin-bottom: 40px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .showBtn-last {
    border-top: 1px solid #e7e7e7;
    padding-top: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-per-rating {
    font-size: 20px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating span,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .emptySymbol,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .fullySymbole {
    display: flex !important;
    width: 20px;
    height: 20px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating img {
    width: 16px;
    height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review {
    width: calc(50% - 10px);
    background-color: #f9f9f9;
    padding: 20px 24px;
    border-radius: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-img {
    width: 52px;
    height: 52px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-name {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .review-time {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    margin: 14px 0;
    position: relative;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single h5 {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .read-more-desk {
    font-size: 14px;
    color: #7e7e7e;
    word-break: keep-all;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .read-more-desk .readMore {
    color: #1b1c13;
    text-decoration: underline;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more {
    display: none;
  }
  #ItineraryDetail .layout .left .ratingStarArea-mobile {
    display: none !important;
  }
  #ItineraryDetail .layout .right {
    width: calc(40% - 40px);
    min-width: 360px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    border-radius: 40px;
    padding: 32px 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .sidebar-host-button {
    padding: 0 32px 32px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .sidebar-host-button button {
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .emptyHostList {
    font-size: 14px;
    color: #7e7e7e;
    padding: 32px 32px 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .host-list {
    padding: 32px 32px 0;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner {
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .host-img {
    width: 54px;
    height: 54px;
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .approval-right {
    bottom: 0;
    right: 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag {
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag .pro-name-suah {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag .host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .pro-tag-suah {
    grid-gap: 4px;
    gap: 4px;
    font-size: 10px;
    font-weight: 700;
    height: 28px;
    line-height: 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .pro-tag-suah > * {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .suah-date-time {
    font-size: 12px;
    color: #7e7e7e;
    margin-bottom: 8px;
    height: 16px;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .total-host-join {
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .info-arrow-btn {
    margin-top: -6px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost {
    padding: 20px 0 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat {
    grid-gap: 20px;
    gap: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat input {
    display: none;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat svg {
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .host-inner {
    margin-bottom: 32px !important;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .host-inner:last-child {
    margin-bottom: 0 !important;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-local-host {
    padding: 0 32px 32px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .btnbox {
    margin-top: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .btnbox button {
    height: 50px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host {
    padding: 0 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .emptymain,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc .emptymain,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .emptymain {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .empty,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc .empty,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .empty {
    color: #7e7e7e;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info {
    grid-gap: 20px;
    gap: 20px;
    padding: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about {
    display: flex;
    justify-content: space-between;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about h3 {
    width: 106px;
    font-size: 14px;
    color: #7e7e7e;
    display: block;
    width: 106px;
    font-weight: 400;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about h4 {
    width: calc(100% - 106px);
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
    text-align: right;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc {
    padding: 32px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc p {
    margin-bottom: 0;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-host-appstatus {
    margin-top: 32px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    justify-content: center;
    line-height: 20px;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-host-appstatus:not(:last-child) {
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .cancleApplicationbtn {
    font-size: 12px;
    font-weight: 700;
    color: #42b6e6;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    font-size: 14px;
    grid-gap: 16px;
    gap: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .total-pax-joining {
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left {
    align-items: center;
    grid-gap: 14px;
    gap: 14px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left .host-img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left .host-name {
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-right,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag {
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag > * {
    width: 35px;
    font-size: 10px;
    border: 1px solid #1b1c13;
    border-radius: 133.333px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag > * p {
    width: 100%;
    text-align: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .open-image-overlap {
    position: absolute;
  }
  #ItineraryDetail .create-hosting {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list {
    padding: 32px 20px;
    margin-top: 24px;
    background-color: #f9f9f9;
    border-radius: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .color-gray {
    color: #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list .Noapplication {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: #7e7e7e;
    margin-top: 20px;
    font-weight: 400;
  }
  #ItineraryDetail .my-trip-requst-list .request-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 9px;
    line-height: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .request-tabs {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    line-height: 16px;
  }
  #ItineraryDetail .my-trip-requst-list .request-tabs .active {
    color: #42b6e6;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list {
    padding: 20px 0;
    grid-gap: 20px;
    gap: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list:last-child {
    padding-bottom: 0;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info {
    width: 162px;
    grid-gap: 16px;
    gap: 16px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .host-img {
    min-width: 54px;
    height: 54px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag {
    width: calc(100% - 70px);
    grid-gap: 8px;
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag {
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
    max-width: calc(100% - 32px);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .host-tag {
    grid-gap: 4px;
    gap: 4px;
    width: 100%;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .host-tag > div p {
    display: flex;
    align-items: center;
    min-width: 43px;
    width: -webkit-max-content;
    width: max-content;
    height: 28px;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .applicationDate {
    font-size: 12px;
    color: #1b1c13;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns {
    grid-gap: 8px;
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns button {
    width: 72px;
    height: 36px;
    border-radius: 133.333px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns .tooltiptext {
    display: none;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns .Applidisabled:hover + .tooltiptext {
    display: block;
    position: absolute;
    background-color: #444;
    color: #fff;
    font-size: 1rem;
    padding: 5px 15px;
    border-radius: 8px;
    width: -webkit-max-content;
    width: max-content;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  #ItineraryDetail .ck-toolbar {
    display: none;
  }
  #ItineraryDetail .ck-editor__editable_inline {
    min-height: unset;
    margin-bottom: 0;
  }
  #ItineraryDetail .ck-content {
    border: none;
    padding: 0;
  }
  #ItineraryDetail .ck-content ul,
  #ItineraryDetail .ck-content ol {
    padding-left: 20px;
  }
  #ItineraryDetail .ck-content.ck-focused {
    box-shadow: none !important;
  }
  #ItineraryDetail .ck-content .ck-widget_selected {
    border: none !important;
    outline: none !important;
  }
  #ItineraryDetail .ck-content .ck-widget__selection-handle {
    display: none !important;
  }
  #ItineraryDetail .ck-content .ck-editor__nested-editable_focused {
    box-shadow: none !important;
    background-color: transparent !important;
    outline: none !important;
  }
  #ItineraryDetail .ck-content .ck-editor__editable_selected:after {
    background-color: transparent;
  }
  .roboto-text #ItineraryDetail .layout .left .itinerary-title {
    line-height: 36px;
  }
  .roboto-text #ItineraryDetail .layout .left .location-single-event {
    line-height: 20px;
  }
  .roboto-text #ItineraryDetail .layout .left .ratting-country-row {
    grid-gap: 12px;
    gap: 12px;
    line-height: 20px;
    position: relative;
  }
  .roboto-text #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
  }
  .roboto-text #ItineraryDetail .layout .left .star-single-event {
    grid-gap: 12px;
    gap: 12px;
  }
  .roboto-text #ItineraryDetail .layout .left .star-single-event .innere-itinarary-total {
    line-height: 16px;
  }
  .roboto-text #ItineraryDetail .layout .left .itinerary-subtitle {
    line-height: 20px;
    height: 26px;
  }
  .roboto-text #ItineraryDetail .layout .left .about-map .itinerary-subtitle {
    height: 20px;
    margin-bottom: 12px;
  }
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    width: calc(100% - 20px);
  }
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    line-height: 20px;
    width: calc(100% - 45px);
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 35px);
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .comment-content {
    line-height: 20px !important;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .review-tab {
    height: 58px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .user-name {
    line-height: 20px;
  }
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .comment-date {
    line-height: 16px;
  }
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .reply-delete-btn {
    line-height: 12px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .right .single-pax-list .host-gender {
    width: 50px !important;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .ratting-country-row span {
    position: relative;
    top: 0.5px;
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .ratting-country-row .star-single-event {
    position: relative;
    top: 1.5px;
  }
  .roboto-text #ItineraryDetail .ratting-country-row .innere-itinarary-total {
    position: relative;
    bottom: 2px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .ratting-country-row .ratingStarArea {
    position: relative;
    bottom: 1.5px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .rating-with-single h5 {
    position: relative;
    top: 1.5px;
  }
}
@media (max-width: 767px) {
  #ItineraryDetail {
    padding: 0;
  }
  #ItineraryDetail .empty {
    margin-top: 16px;
    font-size: 14px;
    color: #7e7e7e;
  }
  #ItineraryDetail .wrap {
    margin: 0;
    width: 100vw;
  }
  #ItineraryDetail .img-area {
    min-height: 230px;
    border-radius: 0;
    grid-gap: 0;
    gap: 0;
  }
  #ItineraryDetail .img-area .big-img {
    width: 50%;
    aspect-ratio: 1/2;
  }
  #ItineraryDetail .img-area .big-img .itinerary-viewAll-btn {
    left: 12px;
    right: unset;
    bottom: 12px;
  }
  #ItineraryDetail .img-area .small-img {
    width: 50%;
    grid-gap: 0;
    gap: 0;
  }
  #ItineraryDetail .layout {
    flex-direction: column;
  }
  #ItineraryDetail .layout .left {
    width: 100%;
    max-width: unset;
  }
  #ItineraryDetail .layout .left .ratingStarArea-mobile {
    display: flex !important;
    align-items: center;
  }
  #ItineraryDetail .layout .left > * {
    padding: 0 16px;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    padding: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list {
    display: block;
    padding: 20px 16px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .img-join-host {
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .img-join-host .flag-wrap {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationUserTitel {
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .host-gender,
  #ItineraryDetail .layout .left .mobile-host-list .gender-tages p {
    width: 66px;
    justify-content: center;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationGender {
    grid-gap: 4px;
    gap: 4px;
    font-size: 12px;
    margin-top: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationGender .info {
    padding: 0 12px;
    height: 24px;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    display: flex;
    align-items: center;
    line-height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list button {
    height: 48px;
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .cancleApplicationbtn {
    color: #42b6e6;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .emptyHostList {
    color: #7e7e7e;
    font-size: 14px;
    padding-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .host-list {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host {
    width: 100%;
    box-shadow: 0px 0px 13.4867px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info {
    width: 100%;
    position: relative;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner {
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .host-img {
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .approval-right {
    bottom: 0;
    right: 0;
    width: 16px;
    height: g16px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag {
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag .pro-name-suah {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag .host-flag {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag {
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .pro-tag-suah {
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .pro-tag-suah > * {
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: 700;
    border-radius: 133.333px;
    font-size: 12px;
    padding: 0 12px;
    border: 1px solid #1b1c13;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .info-arrow-btn {
    display: none !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .total-host-join {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .suah-date-time {
    font-size: 12px;
    padding-left: 52px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .info-arrow-btn {
    display: none !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .res-more-viewhost button {
    height: 44px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host {
    position: relative;
    padding: 0;
    box-shadow: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .single-host-info .host-inner {
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .single-host-info .host-inner .host-img {
    width: 48px;
    height: 48px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat {
    position: absolute;
    right: 0;
    grid-gap: 12px;
    gap: 12px;
    z-index: 1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat svg {
    width: 24px;
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat input {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-host-appstatus {
    font-weight: 700;
    grid-gap: 4px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info {
    width: 100vw;
    margin-left: -16px;
    padding: 20px 16px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div h3 {
    width: 100px;
    font-size: 14px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div h4 {
    text-align: right;
    width: calc(100% - 100px);
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-desc {
    width: 100vw;
    margin-left: -16px;
    padding: 20px 16px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-desc pre {
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host {
    padding-top: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .emptymain {
    font-size: 16px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .total-pax-joining {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list:not(:last-child) {
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left {
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left .host-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right {
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-flag {
    width: 24px;
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-tag {
    grid-gap: 4px;
    gap: 4px;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-tag > div {
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list {
    background-color: #f9f9f9;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .request-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .request-title span {
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs span {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs .active {
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs span:not(:last-child):after {
    content: " | ";
    color: #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div {
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    justify-content: start;
    align-items: baseline !important;
    padding: 16px 0;
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div:last-child {
    padding-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .host {
    grid-gap: 12px;
    gap: 12px;
    justify-content: start;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .host .host-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .res-application {
    flex-direction: column;
    padding-left: 52px;
    align-items: baseline !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .res-application .applicationDate {
    font-size: 12px;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    text-align: left;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox button {
    width: 64px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox .tooltiptext {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .Noapplication p {
    font-size: 14px;
    color: #7e7e7e;
    text-align: left;
  }
  #ItineraryDetail .layout .left .itinerary-title {
    width: calc(100% - 60px);
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .heart-section input {
    display: none;
  }
  #ItineraryDetail .layout .left .heart-section svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row {
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    min-width: 134px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 50%;
    height: 20px;
    grid-gap: 4px;
    gap: 4px;
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event svg {
    width: 16px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event {
    grid-gap: 2px;
    gap: 2px;
    height: 20px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar {
    display: none !important;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar {
    display: flex !important;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar * {
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar * svg {
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total {
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .date-start-end {
    font-size: 14px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .createdby {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .contents {
    padding: 0;
  }
  #ItineraryDetail .layout .left .contents > * {
    padding: 20px 16px;
  }
  #ItineraryDetail .layout .left .itinerary-subtitle {
    margin-bottom: 0;
    line-height: 20px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .name-with-arrow {
    height: 32px !important;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    border: none;
    grid-gap: 4px;
    gap: 4px;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow {
    height: 40px !important;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    grid-gap: 8px;
    gap: 8px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-num {
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 35px);
    font-size: 14px;
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc {
    width: 100vw;
    margin-left: -16px;
    border-radius: 0;
    margin-top: 0;
    border-left: none;
    border-right: none;
    padding: 17px 16px;
    grid-gap: 16px;
    gap: 16px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc > div {
    width: 100%;
    flex-direction: column;
    text-align: left;
    align-items: baseline !important;
    grid-gap: 4px;
    gap: 4px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right {
    width: 100%;
    word-break: keep-all;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right a {
    font-size: 14px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary .imgArea {
    width: calc(50% - 6px);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row {
    text-align: center;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    min-width: 185px;
    width: unset;
    height: 44px;
  }
  #ItineraryDetail .layout .left .about-tour-event {
    margin-bottom: 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .about-tour-event pre {
    font-size: 14px;
    color: #1b1c13;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .about-map {
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .about-map h6 {
    font-size: 14px;
    color: #1b1c13;
    padding-bottom: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .only-for-host-review {
    padding-left: 16px;
    height: 20px;
    align-items: center;
    margin-bottom: 16px !important;
  }
  #ItineraryDetail .layout .left .total-review-about .only-for-host-review .title-deactive {
    height: 20px !important;
    padding: 0;
  }
  #ItineraryDetail .layout .left .total-review-about .recomment-image svg {
    width: 10px !important;
    height: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab {
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    width: 100vw;
    margin-left: -16px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3 {
    width: 50%;
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    height: 40px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-active {
    border-bottom: 2px solid #42b6e6;
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-deactive {
    border-bottom: 2px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea {
    padding: 10px 12px;
    font-size: 14px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea:focus {
    outline: none;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment {
    grid-gap: 12px;
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment button {
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn:last-child {
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn button {
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply {
    padding: 10px 0;
    grid-gap: 8px;
    gap: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner {
    width: calc(100% - 42px);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner.single-reply-inner2 {
    width: calc(100% - 36px) !important;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details {
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 5px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .comment-date {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .reply-delete-btn {
    font-size: 10px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content {
    font-size: 14px;
    color: #1b1c13;
    line-height: 20px;
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content.deleted-comment {
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner > .comment-reply {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn {
    padding-bottom: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn button {
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn.less svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment,
  #ItineraryDetail .layout .left .total-review-about .comment-inner .moreComment {
    margin-bottom: 32px;
    width: 100%;
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star {
    padding-top: 4px;
    padding-left: 16px;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating span,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .emptySymbol,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .fullySymbole {
    display: flex !important;
    width: 20px;
    height: 20px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating img {
    width: 16px;
    height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row {
    grid-gap: 12px;
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review {
    width: 100%;
    padding: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review:last-child {
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info {
    grid-gap: 12px;
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-name {
    padding-bottom: 4px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single {
    margin: 12px 0;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more {
    display: block;
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more button {
    height: 44px;
    font-style: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right {
    display: none;
  }
  #ItineraryDetail .mobile-comment .mobile-comment-user {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
    position: relative;
  }
  #ItineraryDetail .mobile-comment pre {
    font-size: 14px;
  }
  #ItineraryDetail .mobile-comment .deleted-comment {
    font-size: 14px;
    color: #aeaeae;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-weight: 400;
  }
  #ItineraryDetail .mobile-comment .host-img {
    width: 28px !important;
    height: 28px !important;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .mobile-comment .name-details {
    margin-bottom: 0 !important;
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  #ItineraryDetail .mobile-comment .name-details .user-name,
  #ItineraryDetail .mobile-comment .name-details .comment-date,
  #ItineraryDetail .mobile-comment .name-details .reply-delete-btn {
    line-height: 20px !important;
  }
  #ItineraryDetail .full-comment-section {
    padding-left: 36px;
  }
  .roboto-text #ItineraryDetail .itinerary-title {
    line-height: 22px !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .ratting-country-row svg {
    margin-bottom: 1.5px !important;
  }
}
@media (max-width: 1920px) {
  #MyPage,
  #Inquire,
  #myWishlist,
  #AppliedHostingMain,
  #singleMyHosting,
  #edit-profile-page {
    padding: 75px 0 80px;
  }
  #MyPage .page-title,
  #Inquire .page-title,
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title,
  #edit-profile-page .page-title {
    font-size: 30px;
    font-weight: 700;
  }
  #MyPage .inquiry-gap,
  #Inquire .inquiry-gap,
  #myWishlist .inquiry-gap,
  #AppliedHostingMain .inquiry-gap,
  #singleMyHosting .inquiry-gap,
  #edit-profile-page .inquiry-gap {
    grid-gap: 60px;
    gap: 60px;
    margin-top: 60px;
  }
  #MyPage .inquiry-title,
  #Inquire .inquiry-title,
  #myWishlist .inquiry-title,
  #AppliedHostingMain .inquiry-title,
  #singleMyHosting .inquiry-title,
  #edit-profile-page .inquiry-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 28px;
  }
  #MyPage .inquiry-details,
  #Inquire .inquiry-details,
  #myWishlist .inquiry-details,
  #AppliedHostingMain .inquiry-details,
  #singleMyHosting .inquiry-details,
  #edit-profile-page .inquiry-details {
    font-size: 14px;
    line-height: 20px;
  }
  #MyPage .inquiry-content > div,
  #Inquire .inquiry-content > div,
  #myWishlist .inquiry-content > div,
  #AppliedHostingMain .inquiry-content > div,
  #singleMyHosting .inquiry-content > div,
  #edit-profile-page .inquiry-content > div {
    margin-bottom: 40px;
  }
  #MyPage .profile-card,
  #Inquire .profile-card,
  #myWishlist .profile-card,
  #AppliedHostingMain .profile-card,
  #singleMyHosting .profile-card,
  #edit-profile-page .profile-card {
    grid-gap: 28px;
    gap: 28px;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .profile-card .host-img,
  #Inquire .profile-card .host-img,
  #myWishlist .profile-card .host-img,
  #AppliedHostingMain .profile-card .host-img,
  #singleMyHosting .profile-card .host-img,
  #edit-profile-page .profile-card .host-img {
    width: 112px;
    height: 112px;
  }
  #MyPage .profile-card .user-name,
  #Inquire .profile-card .user-name,
  #myWishlist .profile-card .user-name,
  #AppliedHostingMain .profile-card .user-name,
  #singleMyHosting .profile-card .user-name,
  #edit-profile-page .profile-card .user-name {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #MyPage .profile-card .edit-profile-link,
  #Inquire .profile-card .edit-profile-link,
  #myWishlist .profile-card .edit-profile-link,
  #AppliedHostingMain .profile-card .edit-profile-link,
  #singleMyHosting .profile-card .edit-profile-link,
  #edit-profile-page .profile-card .edit-profile-link {
    font-size: 14px;
    font-weight: 700;
    color: #42b6e6;
  }
  #MyPage .myPage-title,
  #Inquire .myPage-title,
  #myWishlist .myPage-title,
  #AppliedHostingMain .myPage-title,
  #singleMyHosting .myPage-title,
  #edit-profile-page .myPage-title {
    font-size: 18px;
    font-weight: 700;
  }
  #MyPage .myPage-tabs,
  #Inquire .myPage-tabs,
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs,
  #edit-profile-page .myPage-tabs {
    margin: 16px 0 20px;
    font-size: 14px;
    font-weight: 400;
  }
  #MyPage .myPage-tabs .my-host-check,
  #Inquire .myPage-tabs .my-host-check,
  #myWishlist .myPage-tabs .my-host-check,
  #AppliedHostingMain .myPage-tabs .my-host-check,
  #singleMyHosting .myPage-tabs .my-host-check,
  #edit-profile-page .myPage-tabs .my-host-check {
    margin-top: 16px;
  }
  #MyPage .myPage-tabs span,
  #Inquire .myPage-tabs span,
  #myWishlist .myPage-tabs span,
  #AppliedHostingMain .myPage-tabs span,
  #singleMyHosting .myPage-tabs span,
  #edit-profile-page .myPage-tabs span {
    cursor: pointer;
    font-size: 14px;
  }
  #MyPage .myPage-tabs span.active,
  #Inquire .myPage-tabs span.active,
  #myWishlist .myPage-tabs span.active,
  #AppliedHostingMain .myPage-tabs span.active,
  #singleMyHosting .myPage-tabs span.active,
  #edit-profile-page .myPage-tabs span.active {
    color: #42b6e6;
  }
  #MyPage .myPage-tabs span:not(:last-child):after,
  #Inquire .myPage-tabs span:not(:last-child):after,
  #myWishlist .myPage-tabs span:not(:last-child):after,
  #AppliedHostingMain .myPage-tabs span:not(:last-child):after,
  #singleMyHosting .myPage-tabs span:not(:last-child):after,
  #edit-profile-page .myPage-tabs span:not(:last-child):after {
    content: "|";
    display: inline-block;
    padding: 0 10px;
    color: #e7e7e7;
    font-weight: 400;
    cursor: default;
  }
  #MyPage .moreLink,
  #Inquire .moreLink,
  #myWishlist .moreLink,
  #AppliedHostingMain .moreLink,
  #singleMyHosting .moreLink,
  #edit-profile-page .moreLink {
    font-size: 14px;
    color: #42b6e6;
    font-weight: 400;
    margin-right: 8px;
  }
  #MyPage .my-account-feeds,
  #Inquire .my-account-feeds,
  #myWishlist .my-account-feeds,
  #AppliedHostingMain .my-account-feeds,
  #singleMyHosting .my-account-feeds,
  #edit-profile-page .my-account-feeds {
    padding-top: 20px;
  }
  #MyPage .tour-card-list,
  #Inquire .tour-card-list,
  #myWishlist .tour-card-list,
  #AppliedHostingMain .tour-card-list,
  #singleMyHosting .tour-card-list,
  #edit-profile-page .tour-card-list {
    grid-gap: 31px;
    gap: 31px;
  }
  #MyPage .tour-card-list .tour-card,
  #Inquire .tour-card-list .tour-card,
  #myWishlist .tour-card-list .tour-card,
  #AppliedHostingMain .tour-card-list .tour-card,
  #singleMyHosting .tour-card-list .tour-card,
  #edit-profile-page .tour-card-list .tour-card {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    aspect-ratio: unset;
  }
  #MyPage .tour-card-list .single-card-tour,
  #Inquire .tour-card-list .single-card-tour,
  #myWishlist .tour-card-list .single-card-tour,
  #AppliedHostingMain .tour-card-list .single-card-tour,
  #singleMyHosting .tour-card-list .single-card-tour,
  #edit-profile-page .tour-card-list .single-card-tour {
    width: 100%;
    min-height: 234px;
    max-height: 234px;
    border-radius: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea,
  #Inquire .tour-card-list .single-card-tour .imgArea,
  #myWishlist .tour-card-list .single-card-tour .imgArea,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea {
    width: 260px !important;
  }
  #MyPage .tour-card-list .single-card-tour .card-box,
  #Inquire .tour-card-list .single-card-tour .card-box,
  #myWishlist .tour-card-list .single-card-tour .card-box,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box,
  #singleMyHosting .tour-card-list .single-card-tour .card-box,
  #edit-profile-page .tour-card-list .single-card-tour .card-box {
    width: calc(100% - 260px);
    padding: 16px 40px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a,
  #Inquire .tour-card-list .single-card-tour .card-box a,
  #myWishlist .tour-card-list .single-card-tour .card-box a,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a {
    width: calc(100% - 170px);
    display: block;
    margin-bottom: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a .cardTitle,
  #Inquire .tour-card-list .single-card-tour .card-box a .cardTitle,
  #myWishlist .tour-card-list .single-card-tour .card-box a .cardTitle,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a .cardTitle,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a .cardTitle,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a .cardTitle {
    display: block;
    height: 60px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
    word-break: keep-all;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .createdBy,
  #Inquire .tour-card-list .single-card-tour .card-box .createdBy,
  #myWishlist .tour-card-list .single-card-tour .card-box .createdBy,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .createdBy,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .createdBy,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .createdBy {
    font-size: 14px;
    font-weight: 700;
    max-width: 170px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting {
    grid-gap: 12px;
    gap: 12px;
    height: 22px;
    margin-bottom: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row {
    grid-gap: 4px;
    gap: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p {
    font-size: 12px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment {
    grid-gap: 12px;
    gap: 12px;
    height: 22px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list {
    display: flex !important;
    align-items: center;
    height: 22px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg {
    height: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment {
    color: #7e7e7e;
    font-size: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div,
  #Inquire .tour-card-list .single-card-tour .card-box .heart-div,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div {
    right: 40px;
    top: 60px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div svg,
  #Inquire .tour-card-list .single-card-tour .card-box .heart-div svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div svg {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting {
    width: 489px;
    height: 102px;
    padding-top: 34px;
    margin-top: -34px;
    margin-bottom: 16px;
    grid-gap: 8px;
    gap: 8px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .single-tag {
    height: 30px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn {
    padding: 0 14px;
    height: 30px;
    font-size: 14px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext {
    top: -34px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host {
    display: flex;
    justify-content: space-between;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card {
    font-size: 12px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host {
    font-size: 12px;
    font-weight: 700;
  }
  #MyPage .tour-card-list .status-layer,
  #Inquire .tour-card-list .status-layer,
  #myWishlist .tour-card-list .status-layer,
  #AppliedHostingMain .tour-card-list .status-layer,
  #singleMyHosting .tour-card-list .status-layer,
  #edit-profile-page .tour-card-list .status-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button,
  #Inquire .tour-card-list .status-layer .status-layer-button,
  #myWishlist .tour-card-list .status-layer .status-layer-button,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button {
    text-align: center;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button .status-title,
  #Inquire .tour-card-list .status-layer .status-layer-button .status-title,
  #myWishlist .tour-card-list .status-layer .status-layer-button .status-title,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button .status-title,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button .status-title,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button .status-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button button,
  #Inquire .tour-card-list .status-layer .status-layer-button button,
  #myWishlist .tour-card-list .status-layer .status-layer-button button,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button button,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button button,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button button {
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    width: 160px;
    background-color: #fff;
  }
  #MyPage .noFeed,
  #Inquire .noFeed,
  #myWishlist .noFeed,
  #AppliedHostingMain .noFeed,
  #singleMyHosting .noFeed,
  #edit-profile-page .noFeed {
    display: flex;
    background-color: #f9f9f9;
    height: 234px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  #MyPage .noFeed h4,
  #Inquire .noFeed h4,
  #myWishlist .noFeed h4,
  #AppliedHostingMain .noFeed h4,
  #singleMyHosting .noFeed h4,
  #edit-profile-page .noFeed h4 {
    font-size: 16px;
    color: #7e7e7e;
    font-weight: 400;
  }
  #MyPage #wishlist,
  #MyPage #request,
  #Inquire #wishlist,
  #Inquire #request,
  #myWishlist #wishlist,
  #myWishlist #request,
  #AppliedHostingMain #wishlist,
  #AppliedHostingMain #request,
  #singleMyHosting #wishlist,
  #singleMyHosting #request,
  #edit-profile-page #wishlist,
  #edit-profile-page #request {
    margin-top: 60px;
  }
  #MyPage #wishlist .modal-card-wrapper,
  #MyPage #request .modal-card-wrapper,
  #Inquire #wishlist .modal-card-wrapper,
  #Inquire #request .modal-card-wrapper,
  #myWishlist #wishlist .modal-card-wrapper,
  #myWishlist #request .modal-card-wrapper,
  #AppliedHostingMain #wishlist .modal-card-wrapper,
  #AppliedHostingMain #request .modal-card-wrapper,
  #singleMyHosting #wishlist .modal-card-wrapper,
  #singleMyHosting #request .modal-card-wrapper,
  #edit-profile-page #wishlist .modal-card-wrapper,
  #edit-profile-page #request .modal-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-auto-columns: auto;
    grid-gap: 20px 10px;
    flex-wrap: wrap;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card,
  #MyPage #request .modal-card-wrapper .modal-card,
  #Inquire #wishlist .modal-card-wrapper .modal-card,
  #Inquire #request .modal-card-wrapper .modal-card,
  #myWishlist #wishlist .modal-card-wrapper .modal-card,
  #myWishlist #request .modal-card-wrapper .modal-card,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card,
  #singleMyHosting #request .modal-card-wrapper .modal-card,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card,
  #edit-profile-page #request .modal-card-wrapper .modal-card {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    min-width: 260px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #MyPage #request .modal-card-wrapper .modal-card .modal-card-content,
  #Inquire #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #Inquire #request .modal-card-wrapper .modal-card .modal-card-content,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #myWishlist #request .modal-card-wrapper .modal-card .modal-card-content,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .modal-card-content,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #singleMyHosting #request .modal-card-wrapper .modal-card .modal-card-content,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #edit-profile-page #request .modal-card-wrapper .modal-card .modal-card-content {
    width: 100%;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .imgArea,
  #MyPage #request .modal-card-wrapper .modal-card .imgArea,
  #Inquire #wishlist .modal-card-wrapper .modal-card .imgArea,
  #Inquire #request .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #request .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #request .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #request .modal-card-wrapper .modal-card .imgArea {
    min-width: 260px;
    width: 100%;
    min-height: 146px;
    aspect-ratio: 4/3;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details,
  #MyPage #request .modal-card-wrapper .modal-card .card-details,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details,
  #Inquire #request .modal-card-wrapper .modal-card .card-details,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details {
    padding: 16px 20px 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-name,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-info,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-info {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-category,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-category {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-timeInfo {
    font-size: 14px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes label,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes label {
    cursor: pointer;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes label img,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes label img {
    width: 24px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes input,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes input {
    display: none;
  }
  #MyPage .wishlist-host,
  #Inquire .wishlist-host,
  #myWishlist .wishlist-host,
  #AppliedHostingMain .wishlist-host,
  #singleMyHosting .wishlist-host,
  #edit-profile-page .wishlist-host {
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }
  #MyPage .wishlist-host:last-child,
  #Inquire .wishlist-host:last-child,
  #myWishlist .wishlist-host:last-child,
  #AppliedHostingMain .wishlist-host:last-child,
  #singleMyHosting .wishlist-host:last-child,
  #edit-profile-page .wishlist-host:last-child {
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .wishlist-host .host-img,
  #Inquire .wishlist-host .host-img,
  #myWishlist .wishlist-host .host-img,
  #AppliedHostingMain .wishlist-host .host-img,
  #singleMyHosting .wishlist-host .host-img,
  #edit-profile-page .wishlist-host .host-img {
    width: 54px;
    height: 54px;
  }
  #MyPage .wishlist-host .host-img ~ div,
  #Inquire .wishlist-host .host-img ~ div,
  #myWishlist .wishlist-host .host-img ~ div,
  #AppliedHostingMain .wishlist-host .host-img ~ div,
  #singleMyHosting .wishlist-host .host-img ~ div,
  #edit-profile-page .wishlist-host .host-img ~ div {
    width: calc(100% - 70px);
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag,
  #Inquire .wishlist-host .host-img ~ div .host-tag,
  #myWishlist .wishlist-host .host-img ~ div .host-tag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag {
    grid-gap: 4px;
    gap: 4px;
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag > div,
  #Inquire .wishlist-host .host-img ~ div .host-tag > div,
  #myWishlist .wishlist-host .host-img ~ div .host-tag > div,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag > div,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag > div,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag > div {
    padding: 0 12px;
    border-radius: 133.333px;
    height: 28px;
    display: flex;
    align-items: center;
    border: 1px solid #1b1c13;
    font-size: 10px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label,
  #Inquire .wishlist-host .host-img ~ div .heart-div label,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div input,
  #Inquire .wishlist-host .host-img ~ div .heart-div input,
  #myWishlist .wishlist-host .host-img ~ div .heart-div input,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div input,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div input,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div input {
    display: none;
  }
  #MyPage .host-my-own-btn,
  #Inquire .host-my-own-btn,
  #myWishlist .host-my-own-btn,
  #AppliedHostingMain .host-my-own-btn,
  #singleMyHosting .host-my-own-btn,
  #edit-profile-page .host-my-own-btn {
    grid-gap: 4px;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
  }
  #MyPage .myOnly,
  #Inquire .myOnly,
  #myWishlist .myOnly,
  #AppliedHostingMain .myOnly,
  #singleMyHosting .myOnly,
  #edit-profile-page .myOnly {
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage .myOnly.checked,
  #Inquire .myOnly.checked,
  #myWishlist .myOnly.checked,
  #AppliedHostingMain .myOnly.checked,
  #singleMyHosting .myOnly.checked,
  #edit-profile-page .myOnly.checked {
    color: #42b6e6;
  }
  #MyPage .myOnly input,
  #Inquire .myOnly input,
  #myWishlist .myOnly input,
  #AppliedHostingMain .myOnly input,
  #singleMyHosting .myOnly input,
  #edit-profile-page .myOnly input {
    display: none;
  }
  .tour-card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
    grid-auto-columns: auto;
    grid-gap: 20px 10px;
    flex-wrap: wrap;
  }
  #myWishlist #wishlist {
    margin-top: 0;
  }
  #edit-profile-page {
    position: relative;
  }
  #edit-profile-page .essential {
    margin-left: 4px;
  }
  #edit-profile-page .disabledBtn {
    background-color: #aeaeae;
    color: #dcdcdc;
  }
  #edit-profile-page .basic-info {
    margin: 8px 0 20px;
  }
  #edit-profile-page .basic-info,
  #edit-profile-page label h3 {
    font-size: 14px;
    font-weight: 700;
  }
  #edit-profile-page label {
    display: flex;
  }
  #edit-profile-page .user-profile-area {
    padding: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    border: none;
    margin-bottom: 24px;
  }
  #edit-profile-page .user-profile-area input {
    display: none;
  }
  #edit-profile-page .user-profile-area label {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  #edit-profile-page .basic-info-area {
    display: flex;
    justify-content: space-between;
    grid-gap: 80px;
    grid-gap: 80px;
    gap: 80px;
  }
  #edit-profile-page .basic-info-area.mobile-info {
    display: none;
  }
  #edit-profile-page .basic-info-area * {
    box-sizing: border-box;
  }
  #edit-profile-page .basic-info-area .left,
  #edit-profile-page .basic-info-area .right {
    width: 510px;
  }
  #edit-profile-page .basic-info-area .left .input-field:nth-child(2) {
    padding-bottom: 106px;
  }
  #edit-profile-page .basic-info-area .input-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #edit-profile-page .basic-info-area .input-field:not(:last-child) {
    padding-bottom: 28px;
  }
  #edit-profile-page .basic-info-area .input-field label {
    display: flex;
    width: 120px;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field input {
    width: calc(100% - 120px);
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #dcdcdc;
  }
  #edit-profile-page .basic-info-area .input-field input:disabled {
    background-color: #e7e7e7;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex {
    width: calc(100% - 110px);
    grid-gap: 8px;
    gap: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type {
    padding: 0 16px;
    width: 196px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:active, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:focus, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:hover {
    height: 50px;
    border: 1px solid #dcdcdc;
    outline: none;
    box-shadow: none;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type > div {
    padding: 0;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type.css-1s2u09g-control * {
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type .css-qc6sy-singleValue {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #edit-profile-page .basic-info-area .input-field button {
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    width: 110px;
    padding: 0;
    border-radius: 8px;
  }
  #edit-profile-page form {
    margin-top: 20px;
    border-top: 1px solid #e7e7e7;
    padding-top: 24px;
  }
  #edit-profile-page form .delete-profiletwo {
    padding: 24px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #edit-profile-page form .delete-profiletwo a {
    color: #1b1c13;
    text-decoration: underline;
    font-size: 14px;
  }
  #edit-profile-page form input,
  #edit-profile-page form select {
    font-size: 14px;
  }
  #edit-profile-page form .text-danger {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    left: 126px;
  }
  #edit-profile-page form .save-btn {
    margin-top: 40px;
  }
  #edit-profile-page form .save-btn button {
    height: 50px;
    width: 296px;
    font-weight: 700;
  }
  .roboto-text #MyPage .tour-card .location-row,
  .roboto-text #Inquire .tour-card .location-row,
  .roboto-text #myWishlist .tour-card .location-row,
  .roboto-text #AppliedHostingMain .tour-card .location-row,
  .roboto-text #singleMyHosting .tour-card .location-row,
  .roboto-text #edit-profile-page .tour-card .location-row {
    width: 186px;
  }
}
@media (max-width: 767px) {
  #MyPage,
  #myWishlist,
  #AppliedHostingMain,
  #singleMyHosting,
  #edit-profile-page {
    padding: 8px 0 40px;
  }
  #MyPage #my-feed,
  #myWishlist #my-feed,
  #AppliedHostingMain #my-feed,
  #singleMyHosting #my-feed,
  #edit-profile-page #my-feed {
    position: relative;
  }
  #MyPage .page-title,
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title,
  #edit-profile-page .page-title {
    font-size: 18px;
    border-bottom: 1px solid #e7e7e7;
    height: 48px;
    display: flex;
    align-items: center;
    width: 100vw;
    margin-left: -16px;
    padding: 0 16px;
  }
  #MyPage .profile-card,
  #myWishlist .profile-card,
  #AppliedHostingMain .profile-card,
  #singleMyHosting .profile-card,
  #edit-profile-page .profile-card {
    grid-gap: 28px;
    gap: 28px;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .profile-card .host-img,
  #myWishlist .profile-card .host-img,
  #AppliedHostingMain .profile-card .host-img,
  #singleMyHosting .profile-card .host-img,
  #edit-profile-page .profile-card .host-img {
    width: 60px;
    height: 60px;
  }
  #MyPage .profile-card .user-name,
  #myWishlist .profile-card .user-name,
  #AppliedHostingMain .profile-card .user-name,
  #singleMyHosting .profile-card .user-name,
  #edit-profile-page .profile-card .user-name {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #MyPage .profile-card .edit-profile-link,
  #myWishlist .profile-card .edit-profile-link,
  #AppliedHostingMain .profile-card .edit-profile-link,
  #singleMyHosting .profile-card .edit-profile-link,
  #edit-profile-page .profile-card .edit-profile-link {
    font-size: 14px;
    font-weight: 700;
    color: #42b6e6;
  }
  #MyPage .myPage-title,
  #myWishlist .myPage-title,
  #AppliedHostingMain .myPage-title,
  #singleMyHosting .myPage-title,
  #edit-profile-page .myPage-title {
    font-size: 16px;
  }
  #MyPage .myPage-tabs,
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs,
  #edit-profile-page .myPage-tabs {
    margin: 12px 0 20px;
    font-size: 14px;
  }
  #MyPage .myPage-tabs span,
  #myWishlist .myPage-tabs span,
  #AppliedHostingMain .myPage-tabs span,
  #singleMyHosting .myPage-tabs span,
  #edit-profile-page .myPage-tabs span {
    font-size: 12px;
  }
  #MyPage .moreLink,
  #myWishlist .moreLink,
  #AppliedHostingMain .moreLink,
  #singleMyHosting .moreLink,
  #edit-profile-page .moreLink {
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 12px;
  }
  #MyPage .my-account-feeds,
  #myWishlist .my-account-feeds,
  #AppliedHostingMain .my-account-feeds,
  #singleMyHosting .my-account-feeds,
  #edit-profile-page .my-account-feeds {
    padding-top: 20px;
  }
  #MyPage .my-account-feeds > section,
  #myWishlist .my-account-feeds > section,
  #AppliedHostingMain .my-account-feeds > section,
  #singleMyHosting .my-account-feeds > section,
  #edit-profile-page .my-account-feeds > section {
    position: relative;
  }
  #MyPage .my-account-feeds > section:not(#wishlist) .moreLink,
  #myWishlist .my-account-feeds > section:not(#wishlist) .moreLink,
  #AppliedHostingMain .my-account-feeds > section:not(#wishlist) .moreLink,
  #singleMyHosting .my-account-feeds > section:not(#wishlist) .moreLink,
  #edit-profile-page .my-account-feeds > section:not(#wishlist) .moreLink {
    top: 0;
  }
  #MyPage .tour-card-list,
  #myWishlist .tour-card-list,
  #AppliedHostingMain .tour-card-list,
  #singleMyHosting .tour-card-list,
  #edit-profile-page .tour-card-list {
    grid-gap: 12px;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  }
  #MyPage .tour-card-list .tour-card,
  #myWishlist .tour-card-list .tour-card,
  #AppliedHostingMain .tour-card-list .tour-card,
  #singleMyHosting .tour-card-list .tour-card,
  #edit-profile-page .tour-card-list .tour-card {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    aspect-ratio: unset;
  }
  #MyPage .tour-card-list .single-card-tour,
  #myWishlist .tour-card-list .single-card-tour,
  #AppliedHostingMain .tour-card-list .single-card-tour,
  #singleMyHosting .tour-card-list .single-card-tour,
  #edit-profile-page .tour-card-list .single-card-tour {
    width: 100%;
    min-height: 193px;
    max-height: 193px;
    border-radius: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea-link,
  #myWishlist .tour-card-list .single-card-tour .imgArea-link,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea-link,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea-link,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea-link {
    width: 30%;
    min-width: 100px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #myWishlist .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea-link .imgArea {
    width: 100% !important;
  }
  #MyPage .tour-card-list .single-card-tour .card-box,
  #myWishlist .tour-card-list .single-card-tour .card-box,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box,
  #singleMyHosting .tour-card-list .single-card-tour .card-box,
  #edit-profile-page .tour-card-list .single-card-tour .card-box {
    min-width: calc(100% - 100px);
    width: 70%;
    padding: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .tour-card-data,
  #myWishlist .tour-card-list .single-card-tour .card-box .tour-card-data,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .tour-card-data,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .tour-card-data,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .tour-card-data {
    flex-direction: column;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a,
  #myWishlist .tour-card-list .single-card-tour .card-box a,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a {
    width: 100%;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a .cardTitle,
  #myWishlist .tour-card-list .single-card-tour .card-box a .cardTitle,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a .cardTitle,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a .cardTitle,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a .cardTitle {
    height: 44px;
    line-height: 22px;
    font-size: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .createdBy,
  #myWishlist .tour-card-list .single-card-tour .card-box .createdBy,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .createdBy,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .createdBy,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .createdBy {
    font-size: 16px;
    margin-bottom: 8px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    max-width: unset;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting {
    flex-direction: column;
    height: unset;
    grid-gap: 4px;
    gap: 4px;
    margin-bottom: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row {
    grid-gap: 2px;
    gap: 2px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg {
    width: 16px;
    height: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row {
    grid-gap: 2px;
    gap: 2px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list {
    display: none;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list {
    display: flex;
    height: 18px;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span {
    height: 18px;
    display: flex;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span * {
    display: flex;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg {
    width: 16px;
    height: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews {
    font-size: 10px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div {
    right: 12px;
    top: 60px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting {
    width: 100%;
    height: 24px;
    padding: 0;
    margin-top: 0;
    grid-gap: 4px;
    gap: 4px;
    margin-bottom: 8px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .single-tag {
    height: 24px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn {
    padding: 0 12px;
    height: 24px;
    font-size: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext {
    top: -34px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host {
    display: flex;
    justify-content: space-between;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card {
    font-size: 10px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host {
    font-size: 10px;
  }
  #MyPage .tour-card-list .status-layer,
  #myWishlist .tour-card-list .status-layer,
  #AppliedHostingMain .tour-card-list .status-layer,
  #singleMyHosting .tour-card-list .status-layer,
  #edit-profile-page .tour-card-list .status-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
  }
  #MyPage .tour-card-list .status-layer .status-title,
  #myWishlist .tour-card-list .status-layer .status-title,
  #AppliedHostingMain .tour-card-list .status-layer .status-title,
  #singleMyHosting .tour-card-list .status-layer .status-title,
  #edit-profile-page .tour-card-list .status-layer .status-title {
    font-size: 16px;
    font-weight: 700;
  }
  #MyPage .tour-card-list .status-layer button,
  #myWishlist .tour-card-list .status-layer button,
  #AppliedHostingMain .tour-card-list .status-layer button,
  #singleMyHosting .tour-card-list .status-layer button,
  #edit-profile-page .tour-card-list .status-layer button {
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    width: 160px;
  }
  #MyPage .noFeed,
  #myWishlist .noFeed,
  #AppliedHostingMain .noFeed,
  #singleMyHosting .noFeed,
  #edit-profile-page .noFeed {
    display: flex;
    background-color: #f9f9f9;
    height: 234px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  #MyPage .noFeed h4,
  #myWishlist .noFeed h4,
  #AppliedHostingMain .noFeed h4,
  #singleMyHosting .noFeed h4,
  #edit-profile-page .noFeed h4 {
    font-size: 14px;
    color: #7e7e7e;
  }
  #MyPage #wishlist,
  #myWishlist #wishlist,
  #AppliedHostingMain #wishlist,
  #singleMyHosting #wishlist,
  #edit-profile-page #wishlist {
    margin-top: 60px;
    position: relative;
  }
  #MyPage #wishlist .modal-card-wrapper,
  #myWishlist #wishlist .modal-card-wrapper,
  #AppliedHostingMain #wishlist .modal-card-wrapper,
  #singleMyHosting #wishlist .modal-card-wrapper,
  #edit-profile-page #wishlist .modal-card-wrapper {
    grid-gap: 16px;
    gap: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card,
  #myWishlist #wishlist .modal-card-wrapper .modal-card,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    width: 100%;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .imgArea {
    width: 100%;
    height: unset;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details {
    padding: 16px 20px 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-info {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-category {
    font-size: 14px;
    margin-bottom: 4px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo {
    font-size: 14px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label {
    cursor: pointer;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label img {
    width: 24px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes input {
    display: none;
  }
  #MyPage .wishlist-host,
  #myWishlist .wishlist-host,
  #AppliedHostingMain .wishlist-host,
  #singleMyHosting .wishlist-host,
  #edit-profile-page .wishlist-host {
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }
  #MyPage .wishlist-host:last-child,
  #myWishlist .wishlist-host:last-child,
  #AppliedHostingMain .wishlist-host:last-child,
  #singleMyHosting .wishlist-host:last-child,
  #edit-profile-page .wishlist-host:last-child {
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .wishlist-host .host-img,
  #myWishlist .wishlist-host .host-img,
  #AppliedHostingMain .wishlist-host .host-img,
  #singleMyHosting .wishlist-host .host-img,
  #edit-profile-page .wishlist-host .host-img {
    width: 48px;
    height: 48px;
  }
  #MyPage .wishlist-host .host-img ~ div,
  #myWishlist .wishlist-host .host-img ~ div,
  #AppliedHostingMain .wishlist-host .host-img ~ div,
  #singleMyHosting .wishlist-host .host-img ~ div,
  #edit-profile-page .wishlist-host .host-img ~ div {
    width: calc(100% - 70px);
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag {
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 8px;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag > div,
  #myWishlist .wishlist-host .host-img ~ div .host-tag > div,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag > div,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag > div,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag > div {
    height: 24px;
    font-size: 12px;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label svg,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label svg,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label svg,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label svg,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label svg {
    width: 24px;
    height: 24px;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div input,
  #myWishlist .wishlist-host .host-img ~ div .heart-div input,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div input,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div input,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div input {
    display: none;
  }
  #MyPage .host-my-own-btn,
  #myWishlist .host-my-own-btn,
  #AppliedHostingMain .host-my-own-btn,
  #singleMyHosting .host-my-own-btn,
  #edit-profile-page .host-my-own-btn {
    grid-gap: 4px;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 20px;
  }
  #MyPage .myOnly,
  #myWishlist .myOnly,
  #AppliedHostingMain .myOnly,
  #singleMyHosting .myOnly,
  #edit-profile-page .myOnly {
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage .myOnly.checked,
  #myWishlist .myOnly.checked,
  #AppliedHostingMain .myOnly.checked,
  #singleMyHosting .myOnly.checked,
  #edit-profile-page .myOnly.checked {
    color: #42b6e6;
  }
  #MyPage .myOnly input,
  #myWishlist .myOnly input,
  #AppliedHostingMain .myOnly input,
  #singleMyHosting .myOnly input,
  #edit-profile-page .myOnly input {
    display: none;
  }
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title {
    border-bottom: none;
  }
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs {
    margin: 4px 0 20px;
  }
  #myWishlist .myPage-tabs .my-host-check,
  #AppliedHostingMain .myPage-tabs .my-host-check,
  #singleMyHosting .myPage-tabs .my-host-check {
    margin-top: 12px;
  }
  #myWishlist #wishlist {
    margin-top: 0;
  }
  #myWishlist .page-title {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding-right: 0;
  }
  #myWishlist .host-my-own-btn {
    margin-left: 12px;
    height: 32px;
    font-size: 12px;
  }
  #myWishlist .host-my-own-btn svg {
    width: 16px;
  }
  #edit-profile-page {
    padding: 8px 0 40px;
  }
  #edit-profile-page .basic-info {
    margin: 8px 0 20px;
  }
  #edit-profile-page .page-title {
    border-bottom: none;
  }
  #edit-profile-page .basic-info,
  #edit-profile-page label h3 {
    font-size: 14px;
    font-weight: 700;
  }
  #edit-profile-page label {
    display: flex;
  }
  #edit-profile-page .user-info {
    width: 100vw;
    margin-left: -16px;
    margin-top: 0;
    padding: 20px 16px 0;
  }
  #edit-profile-page .user-profile-area {
    padding: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    border: none;
    margin-bottom: 24px;
  }
  #edit-profile-page .user-profile-area input {
    display: none;
  }
  #edit-profile-page .user-profile-area label {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  #edit-profile-page .basic-info-area {
    display: block;
    justify-content: space-between;
    grid-gap: 80px;
    grid-gap: 80px;
    gap: 80px;
  }
  #edit-profile-page .basic-info-area.mobile-info {
    display: block;
  }
  #edit-profile-page .basic-info-area.pc-info {
    display: none;
  }
  #edit-profile-page .basic-info-area * {
    box-sizing: border-box;
  }
  #edit-profile-page .basic-info-area .left,
  #edit-profile-page .basic-info-area .right {
    width: 100%;
  }
  #edit-profile-page .basic-info-area .left .input-field:nth-child(2) {
    padding-bottom: 20px;
  }
  #edit-profile-page .basic-info-area .input-field {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
  }
  #edit-profile-page .basic-info-area .input-field label {
    width: 100%;
    margin-bottom: 8px;
    grid-gap: 4px;
    gap: 4px;
  }
  #edit-profile-page .basic-info-area .input-field input {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #dcdcdc;
  }
  #edit-profile-page .basic-info-area .input-field input:disabled {
    background-color: #e7e7e7;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex {
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex input {
    width: calc(100% - 86px);
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type {
    padding: 0 16px;
    width: 196px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:active, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:focus, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:hover {
    height: 50px;
    border: 1px solid #dcdcdc;
    outline: none;
    box-shadow: none;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type > div {
    padding: 0;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type.css-1s2u09g-control * {
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type .css-qc6sy-singleValue {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #edit-profile-page .basic-info-area .input-field button {
    height: 44px;
    font-size: 12px;
    font-weight: 700;
    width: 86px;
    padding: 0;
    border-radius: 8px;
  }
  #edit-profile-page form {
    border-top: 1px solid #e7e7e7;
    padding-top: 24px;
  }
  #edit-profile-page form .delete-profiletwo {
    padding: 0 0 28px;
    border-bottom: 1px solid #e7e7e7;
  }
  #edit-profile-page form .delete-profiletwo a {
    padding: 0;
    color: #1b1c13;
    text-decoration: underline;
    font-size: 14px;
  }
  #edit-profile-page form input,
  #edit-profile-page form select {
    font-size: 14px;
  }
  #edit-profile-page form .text-danger {
    position: absolute;
    font-size: 12px;
    bottom: 6px;
    left: 13px;
  }
  #edit-profile-page form .save-btn {
    margin-top: 28px;
  }
  #edit-profile-page form .save-btn button {
    height: 50px;
    width: 296px;
    font-weight: 700;
  }
  .roboto-text #MyPage .host-tag p,
  .roboto-text #myWishlist .host-tag p,
  .roboto-text #AppliedHostingMain .host-tag p,
  .roboto-text #singleMyHosting .host-tag p,
  .roboto-text #edit-profile-page .host-tag p {
    font-size: 10px;
  }
}
@media (max-width: 1920px) {
  #forgotPw {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    min-height: calc(100vh - 338px);
    padding-top: 100px;
  }
  #forgotPw .page-title {
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 20px;
  }
  #forgotPw .page-desc {
    font-size: 14px;
    color: #7e7e7e;
    padding-bottom: 42px;
  }
  #forgotPw .InputBox {
    position: relative;
    height: 90px;
    margin-bottom: 40px;
  }
  #forgotPw .InputBox label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  #forgotPw .InputBox input {
    width: 400px;
    height: 50px;
    display: block;
    border: 1px solid #dcdcdc;
    border-radius: 133.333px;
    padding: 0 20px;
  }
  #forgotPw button {
    width: 400px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #forgotPw .text-danger {
    position: relative;
    top: 4px;
    left: 20px;
    font-size: 12px;
    color: #ce0000;
  }
}
@media (max-width: 1920px) {
  #SignUp form {
    width: 460px;
    margin: 0 auto;
    padding: 40px 30px 92px;
  }
  #SignUp form .email-input {
    margin-bottom: 10px;
  }
  #SignUp form .page-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 30px !important;
  }
  #SignUp form .input-field {
    padding-bottom: 40px;
    align-items: center;
    position: relative;
  }
  #SignUp form .input-field > *:not(label) {
    font-size: 14px;
  }
  #SignUp form .input-field .phone-field {
    width: 100%;
  }
  #SignUp form .input-field label {
    width: 100%;
    font-weight: 700;
    align-items: center;
    margin-bottom: 20px !important;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
  }
  #SignUp form .input-field label img {
    margin-left: 4px;
  }
  #SignUp form .input-field input {
    padding: 0 20px;
    font-size: 14px;
  }
  #SignUp form .input-field input[type=checkbox] {
    display: none;
  }
  #SignUp form .input-field input,
  #SignUp form .input-field .input-style__control {
    width: 100%;
    height: 50px;
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }
  #SignUp form .input-field > div {
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #SignUp form .input-field > div button {
    margin-right: 6px;
  }
  #SignUp form .input-field > div.birth-field, #SignUp form .input-field > div.phone-verify {
    border: none;
  }
  #SignUp form .input-field .inputWithBtn {
    width: calc(100% - 102px);
    margin: 0;
    border: none;
    border-radius: 0;
  }
  #SignUp form .input-field .input-style__indicator-separator {
    display: none;
  }
  #SignUp form .input-field .input-style__single-value {
    line-height: 50px;
    height: 50px;
    padding: 0 !important;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100% !important;
  }
  #SignUp form .input-field .name-field {
    border: none;
  }
  #SignUp form .input-field .name-field input {
    width: 194px;
  }
  #SignUp form .input-field .birth-field .input-style__control {
    width: 126px;
  }
  #SignUp form .input-field .phone-field .input-style__control {
    width: 126px;
  }
  #SignUp form .input-field .phone-field input {
    width: 262px;
  }
  #SignUp form .input-field .input-style__value-container {
    padding: 0 20px !important;
  }
  #SignUp form .input-field .input-style__single-value {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 16px 0 !important;
  }
  #SignUp form .input-field .signupButton {
    border-radius: 133.333px;
    background-color: #42b6e6;
    font-size: 12px;
    font-weight: 700;
    height: 38px;
    color: #fff;
    width: 96px;
  }
  #SignUp form .agree-area label {
    margin-bottom: 0 !important;
  }
  #SignUp form .agree-area label .d-flex {
    grid-gap: 4px;
    gap: 4px;
  }
  #SignUp form .agree-area label span {
    font-size: 14px;
  }
  #SignUp form .agree-area .input-field {
    justify-content: space-between;
  }
  #SignUp form .agree-area .input-field:nth-child(2) {
    padding-top: 20px;
  }
  #SignUp form .agree-area .agree-all {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 20px;
  }
  #SignUp form .agree-area label {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: flex;
    grid-gap: 12px;
    gap: 12px;
  }
  #SignUp form .agree-area button {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    color: #42b6e6;
  }
  #SignUp form .joinBtn {
    margin-bottom: 40px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #SignUp form .have-account-row {
    text-align: center;
    font-weight: 700;
  }
  #SignUp form .have-account-row span {
    color: #42b6e6;
    cursor: pointer;
  }
  #SignUp form .signup-form-error {
    color: #ff5f5f;
    position: absolute;
    left: 21px;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }
  #SignUp form .signup-form-ok {
    position: absolute;
    color: #42b6e6;
    padding-left: 21px;
    width: 100%;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
  }
  #SignUp form button:disabled {
    background-color: #aeaeae !important;
    color: #dcdcdc !important;
  }
  #SignUp form .disabled-input {
    background-color: #dcdcdc !important;
  }
  #SignUp form input:focus {
    outline: none;
  }
  #SignUp form .input-style__placeholder {
    font-size: 14px;
  }
  .roboto-text #SignUp .signupButton {
    line-height: 16px;
  }
  .roboto-text #SignUp .agree-area label[for=privacyAndCookis] span {
    width: 232px;
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #SignUp .agree-area label span {
    width: 218px;
  }
}
@media (max-width: 767px) {
  #SignUp form {
    width: 100%;
    margin: 0 auto;
    padding: 40px 16px 92px;
  }
  #SignUp form .page-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 30px !important;
  }
  #SignUp form .input-field {
    padding: 0;
    margin-bottom: 24px;
    align-items: center;
  }
  #SignUp form .input-field label {
    width: 100%;
    font-weight: 700;
    align-items: center;
    margin-bottom: 8px !important;
  }
  #SignUp form .input-field label img {
    margin-left: 4px;
  }
  #SignUp form .input-field input {
    padding: 0 29px;
    font-size: 14px;
    padding: 0 16px;
    height: 44px;
  }
  #SignUp form .input-field input[type=checkbox] {
    display: none;
  }
  #SignUp form .input-field input,
  #SignUp form .input-field .input-style__control {
    width: 100%;
    height: 44px;
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }
  #SignUp form .input-field .input-style__indicator-separator {
    display: none;
  }
  #SignUp form .input-field .input-style__single-value {
    line-height: 50px;
    height: 50px;
    padding: 0 !important;
  }
  #SignUp form .input-field .name-field {
    grid-gap: 8px;
    gap: 8px;
  }
  #SignUp form .input-field .name-field div {
    width: calc(50% - 4px);
  }
  #SignUp form .input-field .name-field input {
    width: 100%;
  }
  #SignUp form .input-field .birth-field {
    grid-gap: 8px;
    gap: 8px;
  }
  #SignUp form .input-field .birth-field .input-style__control {
    width: 100%;
  }
  #SignUp form .input-field .birth-field > * {
    width: calc(33.3333% - 4px);
  }
  #SignUp form .input-field .phone-field {
    grid-gap: 8px;
    gap: 8px;
  }
  #SignUp form .input-field .phone-field > *:first-child {
    min-width: 126px;
    width: calc(33.3333% - 4px);
  }
  #SignUp form .input-field .phone-field > *:nth-child(2) {
    width: calc(66.6667% - 4px);
  }
  #SignUp form .input-field .phone-field > *:nth-child(2) input {
    width: 100%;
  }
  #SignUp form .input-field .phone-field .input-style__control {
    width: 100%;
  }
  #SignUp form .input-field .phone-field input {
    width: 262px;
  }
  #SignUp form .input-field .input-style__value-container {
    padding: 0 20px !important;
  }
  #SignUp form .input-field .input-style__single-value {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 16px 0 !important;
  }
  #SignUp form .input-field .signupButton {
    border-radius: 133.333px;
    background-color: #42b6e6;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    line-height: 12px;
    color: #fff;
    width: 86px;
  }
  #SignUp form .agree-area .input-field {
    justify-content: space-between;
  }
  #SignUp form .agree-area .agree-all {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  #SignUp form .agree-area label {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    margin-bottom: 0 !important;
  }
  #SignUp form .agree-area label svg:first-child {
    width: 20px;
  }
  #SignUp form .agree-area label span {
    display: block;
    font-size: 14px;
  }
  #SignUp form .agree-area button {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    color: #42b6e6;
  }
  #SignUp form .joinBtn {
    margin-bottom: 40px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #SignUp form .have-account-row {
    text-align: center;
    font-weight: 700;
  }
  #SignUp form .have-account-row span {
    color: #42b6e6;
    cursor: pointer;
  }
  #SignUp form .signup-form-error {
    color: #ff5f5f;
    position: relative;
    left: 21px;
    top: 4px;
  }
  #SignUp form .signup-form-ok {
    position: relative;
    color: #42b6e6;
    padding-left: 21px;
    width: 100%;
    top: 4px;
  }
  #SignUp form input[type=email] {
    margin-bottom: 10px;
  }
  #SignUp form .disabled {
    background-color: #aeaeae !important;
  }
  #SignUp form input:focus {
    outline: none;
  }
}
@media (max-width: 1920px) {
  #header {
    height: 70px;
    background: rgba(64, 45, 37, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  }
  #header .wrap {
    height: 70px;
  }
  #header .off,
  #header .chatOff {
    opacity: 0.4;
  }
  #header.main-header .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
  }
  #header.main-header .loginBtn,
  #header.main-header .loginBtn,
  #header.main-header .user-name {
    font-size: 12px;
    color: #fff;
  }
  #header.main-header .loginBtn {
    border: 1px solid #fff;
  }
  #header.sub-header {
    background-color: #fff;
  }
  #header.sub-header .logo svg path {
    fill: #42b6e6;
  }
  #header.sub-header .chatIcon svg path:not(.noFill),
  #header.sub-header .chatIcon svg rect {
    fill: #1b1c13;
  }
  #header.sub-header .notifyIcon svg path,
  #header.sub-header .notifyIcon svg rect {
    stroke: #1b1c13;
  }
  #header.sub-header .loginBtn {
    color: #1b1c13;
    border: 1px solid #1b1c13;
  }
  #header .logo {
    top: 3.5px;
    width: 120px;
  }
  #header .right-button {
    grid-gap: 16px;
    gap: 16px;
  }
  #header .right-button.login .user-name {
    margin-right: 8px;
  }
  #header .right-button .loginBtn,
  #header .right-button .lang,
  #header .right-button .user-name {
    font-weight: 700;
    font-size: 12px;
  }
  #header .right-button .user-name {
    text-decoration: underline;
  }
  #header .right-button .chatIcon,
  #header .right-button .notifyIcon {
    width: 32px;
    height: 32px;
    aspect-ratio: 1/1;
  }
  #header .right-button .loginBtn {
    height: 38px;
    width: 92px;
    border-radius: 133.333px;
  }
  #header .right-button button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header .right-button .user-img {
    width: 26px;
    height: 26px;
    aspect-ratio: 1/1;
    margin-right: 8px;
  }
  #header .lnb {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  #header .lnb.lnb_wrap {
    overflow: hidden;
  }
  #header .lnb_profile {
    width: 240px;
    padding: 20px 0;
  }
  #header .lnb_profile > a {
    display: block;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    color: #1b1c13;
    padding: 0 20px;
  }
  #header .lnb_profile .ChatBoxInner {
    padding: 0 20px;
    height: 48px;
  }
  #header .lnb_profile .ChatBoxInner .chat-setting {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #1b1c13;
  }
  #header .lnb_profile .ChatBoxInner .font-blue {
    margin-right: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
  #header .lnb_notify {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    width: 232px;
    height: 234px;
    padding: 12px 0;
    overflow-y: scroll;
  }
  #header .lnb_notify > p {
    padding: 12px 20px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    color: #aeaeae;
    cursor: pointer;
  }
  #header .lnb_notify > p:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
  #header .lnb_notify > p.new-notify {
    color: #1b1c13;
  }
  #header .lnb_lang {
    width: 120px;
    padding: 16px 0;
  }
  #header .lnb_lang button {
    padding: 0 20px;
    width: 100%;
    height: 40px;
    text-align: left;
    font-size: 14px;
    color: #1b1c13;
  }
  #header .lnb_lang button.on {
    background-color: #ecf8fd;
    font-weight: 700;
  }
  #header #SmallHeader {
    max-width: 606px;
    min-height: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 133.333px;
    position: absolute;
    left: 50%;
    top: 35px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 6px 6px 6px 24px;
  }
  #header #SmallHeader .searchBar {
    position: relative;
    min-height: 56px;
  }
  #header #SmallHeader .searchBar input {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #1b1c13;
    font-weight: 400;
  }
  #header #SmallHeader .searchBar input::-webkit-input-placeholder {
    color: #aeaeae;
  }
  #header #SmallHeader .searchBar input::placeholder {
    color: #aeaeae;
  }
  #header #SmallHeader .searchBar .find-city-location {
    min-width: 282px;
  }
  #header #SmallHeader .searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #header #SmallHeader .searchBar .find-city-location svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #header #SmallHeader .searchBar .find-city-location svg:last-child {
    margin-right: 30px;
  }
  #header #SmallHeader .searchBar .find-city-location input {
    width: calc(100% - 78px);
    margin-left: 8px;
  }
  #header #SmallHeader .searchBar .search-calendar {
    min-width: 237px;
  }
  #header #SmallHeader .searchBar .search-calendar svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
    margin-left: 30px;
    margin-right: 8px;
  }
  #header #SmallHeader .searchBar .search-calendar .dateArea {
    width: calc(100% - 58px);
  }
  #header #SmallHeader .searchBar .search-calendar .dateArea input {
    min-width: 179px;
  }
  #header #SmallHeader .searchBar .searchIcon {
    width: 56px;
    height: 56px;
    background-color: #42b6e6;
    border-radius: 100%;
  }
  #header #SmallHeader .searchBar .searchIcon span {
    display: none;
  }
  #header #SmallHeader .searchBar .searchIcon svg {
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #header #SmallHeader .searchContainer {
    position: absolute;
    min-width: 606px;
    left: -24px;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 28px;
    top: 66px;
  }
  #header #SmallHeader .searchContainer .searchFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
  }
  #header #SmallHeader .searchContainer .searc-bar-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
  }
  #header #SmallHeader .searchContainer .country_list {
    margin-top: 23px;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
  }
  #header #SmallHeader .searchContainer .country_list input[type=radio] {
    display: none;
  }
  #header #SmallHeader .searchContainer .country_list input[type=radio]:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .country_list label {
    padding: 0 16px;
    border-radius: 20px;
    border: 1px solid #dcdcdc;
    height: 36px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1c13;
    cursor: pointer;
  }
  #header #SmallHeader .searchContainer .country_list label:hover {
    font-weight: 700;
    border-color: #42b6e6;
    color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .backBtn {
    font-size: 12px;
    color: #1b1c13;
    font-weight: 700;
    padding-bottom: 12px;
  }
  #header #SmallHeader .searchContainer.mobile-calendar {
    display: none !important;
  }
  #header #SmallHeader .searchContainer .custom-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 32px;
    gap: 32px;
    border: none;
  }
  #header #SmallHeader .searchContainer .react-datepicker__month-container {
    width: calc(50% - 16px);
  }
  #header #SmallHeader .searchContainer .react-datepicker__month-container .react-datepicker__header--custom {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  #header #SmallHeader .searchContainer .datepickerHeader {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    margin-bottom: 10px;
  }
  #header #SmallHeader .searchContainer .datepickerHeader .btn {
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day-names,
  #header #SmallHeader .searchContainer .react-datepicker__month,
  #header #SmallHeader .searchContainer .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__month {
    flex-direction: column;
    margin: 0;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day-name,
  #header #SmallHeader .searchContainer .react-datepicker__day {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--weekend:first-child {
    color: #ff5f5f;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled {
    opacity: 0.4;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day {
    font-weight: 700;
    font-size: 13px;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    background-color: #42b6e6;
    color: #fff;
    border-radius: 100%;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--selected {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    background-color: #ecf8fd;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--selected span {
    background-color: #42b6e6;
    border-radius: 100%;
    color: #fff;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: #ecf8fd;
    color: #1b1c13;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range:hover {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
    background-color: #ecf8fd !important;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range:hover span {
    border-radius: 100% !important;
    background-color: #42b6e6 !important;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-start {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: #ecf8fd;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-start span {
    border-radius: 100%;
    background-color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-range {
    border-radius: 0;
    background-color: #ecf8fd;
    color: #1b1c13;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-end {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-end span {
    background-color: #42b6e6;
    border-radius: 100%;
    color: #fff;
  }
  .roboto-text #header .lnb_profile > a {
    font-size: 12px;
  }
  .roboto-text #header .lnb_profile .ChatBoxInner .chat-setting {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  #header {
    height: 56px;
  }
  #header #SmallHeader {
    display: none !important;
  }
  #header .wrap {
    min-width: 328px;
    height: 56px;
  }
  #header.main-header {
    background-color: #fff;
  }
  #header .logo {
    width: 94px;
  }
  #header .logo svg path {
    fill: #42b6e6;
  }
  #header .right-button.login {
    grid-gap: 12px;
    gap: 12px;
  }
  #header .right-button .chatIcon svg path:not(.noFill),
  #header .right-button .chatIcon svg rect {
    fill: #1b1c13;
  }
  #header .right-button .notifyIcon svg path,
  #header .right-button .notifyIcon svg rect {
    stroke: #1b1c13;
  }
  #header .right-button .lang,
  #header .right-button .loginBtn {
    color: #1b1c13;
    border-color: #1b1c13;
    height: 28px;
  }
  #header .right-button .loginBtn {
    padding: 0 12px;
    margin-right: 8px;
  }
  #header .right-button button svg {
    height: 28px;
  }
  #header .right-button button.lang {
    width: 28px;
  }
  #header .right-button .user-name {
    display: none;
  }
  #header .lnb {
    right: -16px;
    top: 56px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  #header .lnb:not(.lnb_lang) {
    width: 100vw;
  }
  #header .lnb_profile > a,
  #header .lnb_profile .ChatBoxInner {
    padding: 0 32px;
  }
  #header .lnb_profile > a .on,
  #header .lnb_profile > a .off,
  #header .lnb_profile .ChatBoxInner .on,
  #header .lnb_profile .ChatBoxInner .off {
    font-size: 14px;
  }
  #header .lnb_notify {
    width: 100%;
    max-height: 280px;
    overflow-y: scroll;
  }
  #header .lnb_notify p {
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 32px;
    font-size: 14px;
  }
  #header .lnb_lang {
    right: 0;
    border-radius: 8px;
  }
  #header .btn {
    padding: 0 !important;
  }
}
@charset "UTF-8";
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 133.333px;
}

::-webkit-scrollbar-button {
  display: none;
}

.welcom-black {
  color: #1b1c13;
  font-size: 16px;
  font-weight: 700;
}

.url {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  color: #7e7e7e;
}
.url:hover {
  color: #7e7e7e;
}

pre {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.sticky-top {
  z-index: 3 !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
b,
label {
  margin: 0;
}

button,
input,
select,
a {
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button:focus,
input:focus,
select:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

input[type=text] {
  cursor: text;
}

input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aeaeae;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

.input-style__placeholder,
.dateArea-select__placeholder {
  color: #aeaeae !important;
}

.wrap {
  margin: 0 auto;
}

.pagination svg path {
  stroke: #42b6e6;
}
.pagination .disabled svg path {
  stroke: #dcdcdc;
}

.show-data-save {
  position: fixed;
  top: 70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: end;
}
.show-data-save p {
  background-color: #000;
  color: #fff;
  padding: 0 20px;
  width: 240px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1920px) {
  .ck-editor__editable_inline {
    min-height: 400px;
    margin-bottom: 40px;
  }
  .wrap {
    width: 1100px;
  }
  .mobileClose {
    display: none;
  }
  main.single-page {
    min-height: calc(100vh - 238px);
    padding-top: 75px;
  }
}
@media (max-width: 1199px) {
  .wrap {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .wrap {
    width: calc(100% - 32px);
  }
  .mobileClose {
    display: block;
  }
}
.host-category {
  background-color: #8362e2;
  color: #fff;
  border: none !important;
}

.travel-host-category {
  background-color: #ff5f5f;
  color: #fff;
  border: none !important;
}

.layout {
  display: flex;
}

.noto-text * {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.3px !important;
}

.roboto-text * {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2px;
}

.circle {
  aspect-ratio: 1/1;
  border-radius: 100%;
  overflow: hidden;
}
.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: unset;
}

.imgArea {
  overflow: hidden;
}
.imgArea img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: unset;
}

.font-blue {
  color: #42b6e6 !important;
}

.font-green {
  color: #41c82c !important;
}

.font-red {
  color: #ff5f5f !important;
}

.btn-outline-blue,
.btn-cancelBtn {
  border-radius: 133.333px;
  border: 1px solid #42b6e6;
  color: #42b6e6;
}
.btn-outline-blue:hover,
.btn-cancelBtn:hover {
  color: #42b6e6;
}

.btn-cancelBtn:disabled {
  background-color: transparent !important;
  border-color: #dcdcdc;
  color: #dcdcdc;
}

.btn-outline-red {
  border-radius: 133.333px;
  border: 1px solid #ff5f5f;
  color: #ff5f5f;
}
.btn-outline-red:hover {
  color: #ff5f5f;
}

.btn-outline-black {
  border-radius: 133.333px;
  border: 1px solid #1b1c13;
  color: #1b1c13;
}
.btn-outline-black:hover {
  color: #1b1c13;
}

.btn-blackBtn {
  background-color: #1b1c13;
  color: #fff;
}
.btn-blackBtn:hover {
  color: #fff;
}

.btn-declienedBtn {
  border-radius: 133.333px;
  background-color: #ff5f5f;
  color: #fff;
  cursor: default;
}
.btn-declienedBtn:hover {
  color: #fff;
}

.btn-confirmBtn {
  border-radius: 133.333px;
  background-color: #42b6e6;
  color: #fff;
}
.btn-confirmBtn:hover {
  color: #fff;
}

.btn-no-line {
  color: #42b6e6;
  border: none;
}
.btn-no-line:hover {
  color: #42b6e6;
}

.btn-successBtn {
  border-radius: 133.333px;
  background-color: #ecf8fd;
  color: #42b6e6;
  pointer-events: none;
}

.btn-normalBtn {
  border: 1px solid #1b1c13;
}

.btn:disabled {
  cursor: default;
}
.btn:disabled:not(.btn-successBtn) {
  background-color: #dcdcdc;
}

.btn-mainTab {
  padding: 0 16px;
  border-radius: 20px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
}

.searchContainer,
.createContainer {
  position: absolute;
  min-width: 606px;
  width: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 28px;
  top: 72px;
  z-index: 1;
}
.searchContainer .searchFlex,
.createContainer .searchFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
}
.searchContainer .searc-bar-title,
.createContainer .searc-bar-title {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 12px;
}
.searchContainer .country_list,
.createContainer .country_list {
  margin-top: 23px;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.searchContainer .country_list input[type=radio],
.createContainer .country_list input[type=radio] {
  display: none;
}
.searchContainer .country_list input[type=radio]:checked ~ label,
.createContainer .country_list input[type=radio]:checked ~ label {
  border-color: #42b6e6;
  color: #42b6e6;
}
.searchContainer .country_list label,
.createContainer .country_list label {
  padding: 0 16px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1c13;
  cursor: pointer;
}
.searchContainer .country_list label:hover,
.createContainer .country_list label:hover {
  font-weight: 700;
  border-color: #42b6e6;
  color: #42b6e6;
}
.searchContainer .backBtn,
.createContainer .backBtn {
  color: #1b1c13;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 12px;
}
.searchContainer.mobile-calendar,
.createContainer.mobile-calendar {
  display: none !important;
}
.searchContainer.mobile-calendar .react-datepicker__month-container,
.createContainer.mobile-calendar .react-datepicker__month-container {
  width: 100%;
}
.searchContainer .custom-container,
.createContainer .custom-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 32px;
  gap: 32px;
  border: none;
}
.searchContainer .react-datepicker__month-container,
.createContainer .react-datepicker__month-container {
  width: calc(50% - 16px);
}
.searchContainer .react-datepicker__month-container .react-datepicker__header--custom,
.createContainer .react-datepicker__month-container .react-datepicker__header--custom {
  border: none;
  background-color: transparent;
  padding: 0;
}
.searchContainer .datepickerHeader,
.createContainer .datepickerHeader {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 10px;
}
.searchContainer .datepickerHeader .btn,
.createContainer .datepickerHeader .btn {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day {
  background-color: #fff;
}
.searchContainer .react-datepicker__day--keyboard-selected,
.createContainer .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #1b1c13 !important;
}
.searchContainer .react-datepicker__day--keyboard-selected.react-datepicker__day--weekend,
.createContainer .react-datepicker__day--keyboard-selected.react-datepicker__day--weekend {
  color: #ff5f5f;
}
.searchContainer .react-datepicker__day--keyboard-selected .react-datepicker__day,
.createContainer .react-datepicker__day--keyboard-selected .react-datepicker__day {
  color: #1b1c13;
}
.searchContainer .react-datepicker__day-names,
.searchContainer .react-datepicker__month,
.searchContainer .react-datepicker__week,
.createContainer .react-datepicker__day-names,
.createContainer .react-datepicker__month,
.createContainer .react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .searchContainer .react-datepicker__day-names,
  .searchContainer .react-datepicker__month,
  .searchContainer .react-datepicker__week,
  .createContainer .react-datepicker__day-names,
  .createContainer .react-datepicker__month,
  .createContainer .react-datepicker__week {
    width: 100%;
  }
}
.searchContainer .react-datepicker__month,
.createContainer .react-datepicker__month {
  flex-direction: column;
  margin: 0;
}
.searchContainer .react-datepicker__day-name,
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day-name,
.createContainer .react-datepicker__day {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 12px;
  line-height: 16px;
}
.searchContainer .react-datepicker__day--weekend:first-child,
.createContainer .react-datepicker__day--weekend:first-child {
  color: #ff5f5f;
}
.searchContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled,
.createContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled {
  opacity: 0.4;
}
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
}
.searchContainer .react-datepicker__day span,
.createContainer .react-datepicker__day span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled),
.createContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  background-color: #42b6e6;
  color: #fff;
  border-radius: 100%;
}
.searchContainer .react-datepicker__day--outside-month,
.createContainer .react-datepicker__day--outside-month {
  visibility: hidden;
}
.searchContainer .react-datepicker__day--selected,
.createContainer .react-datepicker__day--selected {
  border-top-left-radius: 100% !important;
  border-bottom-left-radius: 100% !important;
  background-color: #ecf8fd;
}
.searchContainer .react-datepicker__day--selected span,
.createContainer .react-datepicker__day--selected span {
  background-color: #42b6e6;
  border-radius: 100%;
  color: #fff;
}
.searchContainer .react-datepicker__day--in-selecting-range,
.createContainer .react-datepicker__day--in-selecting-range {
  border-radius: 0;
  background-color: #ecf8fd;
  color: #1b1c13;
}
.searchContainer .react-datepicker__day--in-selecting-range:hover,
.createContainer .react-datepicker__day--in-selecting-range:hover {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  background-color: #ecf8fd !important;
}
.searchContainer .react-datepicker__day--in-selecting-range:hover span,
.createContainer .react-datepicker__day--in-selecting-range:hover span {
  border-radius: 100% !important;
  background-color: #42b6e6 !important;
}
.searchContainer .react-datepicker__day--range-start,
.createContainer .react-datepicker__day--range-start {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #ecf8fd;
}
.searchContainer .react-datepicker__day--range-start span,
.createContainer .react-datepicker__day--range-start span {
  border-radius: 100%;
  background-color: #42b6e6;
}
.searchContainer .react-datepicker__day--in-range,
.createContainer .react-datepicker__day--in-range {
  border-radius: 0;
  background-color: #ecf8fd;
  color: #1b1c13;
}
.searchContainer .react-datepicker__day--range-end,
.createContainer .react-datepicker__day--range-end {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}
.searchContainer .react-datepicker__day--range-end span,
.createContainer .react-datepicker__day--range-end span {
  background-color: #42b6e6;
  border-radius: 100%;
  color: #fff;
}

.createContainer {
  width: 328px;
  min-width: unset;
}
.createContainer .react-datepicker__month-container {
  width: 100%;
}
.createContainer .react-datepicker__month-container .react-datepicker__day--selected {
  border-radius: 100%;
  background-color: #42b6e6;
}
.createContainer .react-datepicker__week {
  width: 100%;
}

.modal .allchatMain {
  width: calc(100% - 32px);
  max-width: 1760px !important;
}
.modal #userDelete {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 30px;
  font-size: 14px;
  color: #7e7e7e;
}
.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}
.modal .wrap {
  max-width: none;
}
.modal .modal-content {
  width: 460px;
}
.modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: none;
}
.modal .modal-content .modal-header .custom-modal-title {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  display: block;
  text-align: center;
}
.modal .modal-content .modal-header button {
  padding: 0;
}
.modal .modal-content .modal-header .right-close-btn {
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.modal .modal-content.chatModal {
  width: 400px !important;
  height: 529px;
  top: 70px;
  right: 0;
  padding: 0;
  border-radius: 20px;
  border: none;
  overflow: hidden;
}
.modal .modal-content.chatModal .modal-header {
  border-bottom: 1px solid #e7e7e7;
  padding: 28px 28px 20px;
  align-items: center;
  margin-bottom: 0;
  height: 76px;
}
.modal .modal-content.chatModal .modal-header .modal-title {
  font-size: 16px;
  font-weight: 700;
  height: 28px;
  line-height: 28px;
}
.modal .modal-content.chatModal .modal-body {
  height: calc(100% - 76px);
}
.modal .modal-content.chatModal .modal-body .chat-scroll {
  height: 100%;
  overflow-y: auto;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 0;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row {
  padding: 20px 28px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .chat-info {
  grid-gap: 20px;
  gap: 20px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .wishlistimg,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .wishlistimg {
  width: 48px;
  height: 48px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .user-name,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .user-name {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #42b6e6;
  margin-bottom: 8px !important;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .msg-content,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .msg-content {
  font-size: 14px;
  color: #1b1c13;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left {
  width: calc(100% - 40px);
  cursor: pointer;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .chat-message {
  width: calc(100% - 130px);
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .badge-with-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  align-items: end;
  width: 66px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .badge {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 0 7px;
  min-width: 22px;
  height: 22px;
  background-color: #ff5f5f;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 8px !important;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .msg-time {
  font-size: 12px;
  color: #7e7e7e;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right {
  grid-gap: 20px;
  gap: 20px;
  width: 20px;
  justify-content: end;
}
.modal .modal-content.chatModal .modal-chat-header {
  display: flex;
  height: 76px;
  margin-bottom: 0;
}
.modal .modal-content.chatModal .modal-chat-header .header-left {
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  width: calc(100% - 28px);
}
.modal .modal-content.chatModal .modal-chat-header .header-left .user-name {
  font-size: 16px;
  font-weight: 700;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .modal-content.chatModal .admin-msg-only {
  background-color: #ebebeb;
  padding: 20px 30px;
  border-radius: 0 0 20px 20px;
}
.modal .modal-content.chatModal .admin-msg-only p {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  color: #7e7e7e;
}
.modal .modal-content.chatModal .chat-scroll-two {
  height: calc(100% - 133px);
  overflow: hidden;
  padding: 0;
  font-size: 14px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat {
  height: 100%;
  overflow-y: scroll;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date {
  position: relative;
  font-size: 12px;
  color: #7e7e7e;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::before, .modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::after {
  content: "";
  display: block;
  position: absolute;
  width: 160px;
  height: 1px;
  background-color: #e7e7e7;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::before {
  left: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date:after {
  right: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat {
  padding: 0 28px;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .user-imgchat {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .font-blue {
  font-size: 14px;
  margin-bottom: 4px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .secChatHead {
  max-width: 208px;
  padding: 14px 16px;
  background: #efefef;
  border-radius: 0px 20px 20px 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .secChatHead p {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .single-chat {
  padding: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .single-chat:first-child {
  margin-left: 48px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .custom-date-chat-two,
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .custom-date-chat {
  font-weight: 400;
  font-size: 10px;
  color: #7e7e7e;
  white-space: nowrap;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat {
  padding: 0 28px;
  margin-bottom: 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat .chateContentMsg {
  max-width: 256px;
  padding: 14px 16px;
  background: #42b6e6;
  border-radius: 20px 0px 20px 20px;
  color: #fff;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat .chateContentMsg p {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.modal .modal-content.chatModal .modal-footer {
  min-height: 57px;
  padding: 17px;
}
.modal .modal-content.chatModal .modal-footer.submit-complet {
  margin: 0;
  padding: 16px 30px;
  border-top: 1px solid #dfdfdf !important;
}
.modal .modal-content.chatModal .modal-footer.submit-complet textarea {
  resize: none;
  font-size: 14px;
}
.modal .modal-content.chatModal .modal-footer.submit-complet .submit-chat {
  width: 100%;
  height: 100%;
  margin: 0;
}
.modal .tour-courses .modal-content .modal-header,
.modal .tour-courses .modal-content .modal-body {
  width: 100%;
}
.modal .custom-modal.terms-usecard, .modal .custom-modal.privacy-usecard {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .modal .custom-modal.terms-usecard, .modal .custom-modal.privacy-usecard {
    width: calc(100% - 32px);
    margin-left: 0;
  }
}
.modal .custom-modal.terms-usecard .modal-content, .modal .custom-modal.privacy-usecard .modal-content {
  width: 800px;
}
.modal .custom-modal.terms-usecard .modal-header, .modal .custom-modal.privacy-usecard .modal-header {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #7e7e7e;
}
.modal .custom-modal.terms-usecard .modal-header .custom-modal-title, .modal .custom-modal.privacy-usecard .modal-header .custom-modal-title {
  text-align: left;
}
.modal .custom-modal .modal-body {
  width: 100%;
}
.modal .custom-modal .body-scroll {
  height: 50vh;
  overflow-y: scroll;
  margin-top: 20px;
  padding-right: 20px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  height: 128px;
  width: 3px;
  border-radius: 0px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar {
  width: 3px;
}
.modal .custom-modal .body-scroll h1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}
.modal .custom-modal .body-scroll p {
  font-size: 14px;
  word-break: keep-all;
}
.modal .custom-modal .body-scroll p:not(:last-child) {
  margin-bottom: 20px;
}
.modal .custom-modal .modal-content {
  padding: 50px 40px;
  border-radius: 20px;
}
.modal .custom-modal .modal-header {
  padding: 0;
}
.modal .custom-modal .modal-body {
  padding: 0;
}
.modal .custom-modal .modal-body .body-content {
  width: 380px;
  border-radius: 20px;
  background-color: rgba(126, 126, 126, 0.05);
  padding: 40px 20px;
  font-size: 14px;
  color: #7e7e7e;
  text-align: center;
  word-break: keep-all;
}
.modal .custom-modal .modal-body .body-content .body-title {
  font-weight: 700;
  font-size: 14px;
  color: #1b1c13;
  line-height: 25px;
  padding-bottom: 20px;
}
.modal .custom-modal .modal-body .body-content b {
  color: #1b1c13;
  font-weight: 500;
}
.modal .custom-modal .modal-body .body-content ul {
  margin-bottom: 0;
}
.modal .custom-modal .modal-body .body-content ul li {
  list-style-type: "∙ ";
  text-align: left;
  word-break: keep-all;
}
.modal .custom-modal .modal-body .pcReviewRating {
  margin: 30px auto;
}
.modal .custom-modal .modal-body .pcReviewRating > span {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: flex !important;
  grid-gap: 4px !important;
  gap: 4px !important;
  justify-content: center;
}
.modal .custom-modal .modal-body .pcReviewRating svg {
  width: 56px;
  height: 56px;
}
.modal .custom-modal .modal-body .mobileReviewRating {
  display: none;
}
.modal .custom-modal .modal-body textarea {
  resize: none;
  border-radius: 10px;
  border-color: #7e7e7e;
  padding: 30px 20px;
  font-size: 16px;
}
.modal .custom-modal .modal-body textarea::-webkit-input-placeholder {
  color: #aeaeae;
}
.modal .custom-modal .modal-body textarea::placeholder {
  color: #aeaeae;
}
.modal .custom-modal .modal-body textarea:focus {
  outline: none;
}
.modal .custom-modal .modal-body .host-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #c9c9c9;
}
.modal .custom-modal .modal-body .host-info .user-local-host {
  grid-gap: 16px;
  gap: 16px;
}
.modal .custom-modal .modal-body .host-info .host-img {
  width: 54px;
  height: 54px;
  aspect-ratio: 1/1;
}
.modal .custom-modal .modal-body .host-info .join-pro {
  grid-gap: 20px;
  gap: 20px;
}
.modal .custom-modal .modal-body .host-info .join-pro input {
  display: none;
}
.modal .custom-modal .modal-body .host-info .join-pro svg {
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.modal .custom-modal .modal-body .host-info .name-with-flag {
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 8px;
}
.modal .custom-modal .modal-body .host-info .name-with-flag .flag-wrap {
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
}
.modal .custom-modal .modal-body .host-info .name-with-flag .user-name {
  font-size: 14px;
  font-weight: 700;
}
.modal .custom-modal .modal-body .host-info .host-tag {
  grid-gap: 4px;
  gap: 4px;
}
.modal .custom-modal .modal-body .host-info .host-tag > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  line-height: 16px;
  height: 26px;
  font-weight: 700;
  border-radius: 133.333px;
  border: 1px solid #1b1c13;
}
.modal .custom-modal .modal-body .userImgContentko {
  padding-top: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .host-info-title {
  font-size: 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
  line-height: 26px;
  margin-bottom: 12px;
}
.modal .custom-modal .modal-body .userImgContentko .now-hosting-blank {
  margin-top: 36px;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.modal .custom-modal .modal-body .userImgContentko .now-hosting-blank h6 {
  color: #aeaeae;
  font-size: 14px;
  font-weight: 400;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list {
  grid-gap: 30px;
  gap: 30px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div {
  right: 12px;
  top: 12px;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div input {
  display: none;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div label {
  cursor: pointer;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .hosting-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: keep-all;
  line-height: 22px;
  max-height: 44px;
  font-size: 16px;
  color: #1b1c13;
  font-weight: 700;
  margin-bottom: 6px;
  cursor: pointer;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .modalcountryname {
  width: 50%;
  font-size: 14px;
  color: #7e7e7e;
  height: 20px;
  line-height: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .modalcountryname p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .review-with-heart {
  grid-gap: 4px;
  gap: 4px;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .review-with-heart .star-review {
  grid-gap: 4px;
  gap: 4px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location {
  height: 24px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  font-size: 14px;
  color: #1b1c13;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location .read-more-mobile {
  display: none;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 277px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
@media (max-width: 767px) {
  .modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy {
    width: 198px;
  }
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .pax-font {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  right: 0;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .date {
  font-size: 14px;
}
.modal .custom-modal .modal-body .userImgContentko .date-timeko .date {
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
}
.modal .custom-modal .modal-footer {
  margin-top: 50px;
  border-top: none;
  padding: 0;
  grid-gap: 20px;
  gap: 20px;
}
.modal .custom-modal .modal-footer button {
  width: calc(50% - 10px);
  height: 50px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 767px) {
  .modal .custom-modal {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    left: 50%;
    -webkit-transform: translateX(-50%) !important;
            transform: translateX(-50%) !important;
    margin: 0;
  }
  .modal .custom-modal .modal-content {
    min-width: 328px;
    width: calc(100vw - 32px);
    padding: 32px 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .modal .custom-modal .modal-content .modal-header {
    width: 100%;
    margin-bottom: 20px;
  }
  .modal .custom-modal .modal-content .modal-body .reviewRating span svg {
    width: 40px;
    height: 40px;
  }
  .modal .custom-modal .modal-content .modal-body textarea {
    font-size: 14px;
  }
  .modal .custom-modal .modal-content .modal-body .body-content {
    width: 100%;
    padding: 32px 20px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .host-img {
    width: 48px;
    height: 48px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .flag-wrap {
    width: 28px;
    height: 28px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .host-tag {
    grid-gap: 4px;
    gap: 4px;
  }
  .modal .custom-modal .modal-content .modal-body .join-pro {
    position: absolute;
    top: 0;
    right: 0;
    grid-gap: 12px;
    gap: 12px;
  }
  .modal .custom-modal .modal-content .modal-body .join-pro svg {
    width: 24px;
    height: 24px;
  }
  .modal .custom-modal .modal-content .modal-footer {
    width: 100%;
    margin-top: 20px;
  }
  .modal .custom-modal.review-modal .body-content {
    padding: 20px !important;
  }
  .modal .custom-modal.review-modal .reviewRating > span {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
}

.tour-courses {
  width: calc(100% - 32px) !important;
  max-width: 1100px !important;
}
.tour-courses .modal-content {
  width: 100%;
  padding: 50px !important;
  margin: auto;
}
.tour-courses .modal-content .modal-header {
  width: 100%;
  margin-bottom: 20px;
}
.tour-courses .modal-content .modal-header h6 {
  font-weight: 700;
  font-size: 24px;
}
.tour-courses .modal-content .modal-body {
  color: #1b1c13;
}
.tour-courses .modal-content .modal-body .filter-title {
  font-size: 14px;
  display: block;
  min-width: 90px;
  line-height: 36px;
}
.tour-courses .modal-content .modal-body .top-serach-tour {
  padding: 20px 0;
  border-top: 1px solid #7e7e7e;
  border-bottom: 1px solid #7e7e7e;
}
.tour-courses .modal-content .modal-body .top-serach-tour .search-field:not(:last-child) {
  margin-bottom: 16px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title,
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  line-height: 20px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 .font-blue {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  padding-top: 4px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items {
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  font-size: 14px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items input:checked ~ label {
  border-color: #42b6e6;
  color: #42b6e6;
  font-weight: 700;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items label {
  height: 36px;
  padding: 0 16px;
  border: 1px solid #dcdcdc;
  border-radius: 133.333px;
  line-height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tour-courses .modal-content .modal-body .top-serach-tour input[type=radio],
.tour-courses .modal-content .modal-body .top-serach-tour input[type=checkbox] {
  display: none;
}
.tour-courses .modal-content .modal-body .search-keywords {
  padding: 20px 0;
}
.tour-courses .modal-content .modal-body .search-keywords input {
  width: calc(100% - 138px);
  border-radius: 133.333px;
  outline: none;
  border: 1px solid #dcdcdc;
  padding: 0 20px;
  height: 50px;
}
.tour-courses .modal-content .modal-body .search-keywords button {
  min-width: 110px;
  border-radius: 133.333px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.tour-courses .modal-content .modal-body .filter-results-items {
  display: grid;
  padding: 20px 0 40px;
  grid-gap: 20px 13px;
  grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card {
  overflow: hidden;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  border-radius: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .imgArea {
  width: 100%;
  aspect-ratio: 16/9;
  display: block;
  background-size: cover;
  background-position: center;
  position: unset;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details {
  position: unset;
  padding: 16px 16px 20px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details * {
  position: unset;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-title,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-region,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-category,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-date {
  font-size: 14px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-title {
  font-weight: 700;
  margin-bottom: 8px !important;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-region {
  padding: 8px 0 20px;
  color: #7e7e7e;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check {
  top: 18px;
  right: 18px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check input {
  display: none;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check input:checked ~ .check-marker {
  background-color: #42b6e6;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check .check-marker {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check .check-marker::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 14px;
  top: 4px;
  left: 8px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 1.5px;
}
.tour-courses .modal-content .modal-body .pagination {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #42b6e6;
  font-size: 14px;
  font-weight: 500;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom.activepage {
  border: 1px solid #42b6e6;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom:not(.page-arrow) {
  margin: 0 4px;
}
.tour-courses .modal-content .modal-body .pagination .page-arrow {
  visibility: hidden;
  position: relative;
}
.tour-courses .modal-content .modal-body .pagination .page-arrow:after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  visibility: visible;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}
.tour-courses .modal-content .modal-body .pagination .page-first-arrow:after {
  background-image: url(/static/media/first-arrow-color.54a5f058.svg);
}
.tour-courses .modal-content .modal-body .pagination .page-last-arrow:after {
  background-image: url(/static/media/last-arrow-color.a4afc8ff.svg);
}
.tour-courses .modal-content .modal-body .pagination .page-prev-arrow {
  margin-right: 6px;
  margin-right: 10px;
}
.tour-courses .modal-content .modal-body .pagination .page-prev-arrow:after {
  background-image: url(/static/media/prev-arrow-color.eb1712d0.svg);
}
.tour-courses .modal-content .modal-body .pagination .page-next-arrow {
  margin-left: 6px;
  margin-right: 10px;
}
.tour-courses .modal-content .modal-body .pagination .page-next-arrow:after {
  background-image: url(/static/media/next-arrow-color.80a74baf.svg);
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-first-arrow:after {
  background-image: url(/static/media/first-arrow.9eaf3b1d.svg);
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-last-arrow:after {
  background-image: url(/static/media/last-arrow.9a5d10cc.svg);
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-prev-arrow:after {
  background-image: url(/static/media/prev-arrow.3779c783.svg);
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-next-arrow:after {
  background-image: url(/static/media/next-arrow.37564ebd.svg);
}
.tour-courses .modal-content .modal-footer {
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 0 auto;
  margin-top: 40px;
}
.tour-courses .modal-content .modal-footer button {
  width: 30%;
  margin: 0;
}
@media (max-width: 1920px) {
  .tour-courses .modal-content .modal-footer button {
    min-width: 296px;
  }
}
.tour-courses .modal-content .mobile-footer {
  display: none;
}
@media (max-width: 767px) {
  .tour-courses .modal-content .mobile-footer {
    display: block;
  }
}
.tour-courses .modal-content .res-button {
  display: none;
}
@media (max-width: 767px) {
  .tour-courses {
    width: 100% !important;
    margin: 0;
  }
  .tour-courses .modal-content {
    width: 100vw !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .tour-courses .modal-content .modal-header {
    height: 105px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .tour-courses .modal-content .modal-header .modal-title {
    flex-direction: column-reverse;
  }
  .tour-courses .modal-content .modal-header .modal-title .btn {
    text-align: right;
  }
  .tour-courses .modal-content .modal-header .modal-title h6 {
    padding: 0 16px;
    text-align: left;
    font-size: 18px;
    line-height: 49px;
    height: 49px;
  }
  .tour-courses .modal-content .modal-header button {
    height: 56px;
    padding: 0 16px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour {
    padding: 24px 16px;
    border-color: #d9d9d9 !important;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field {
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field:not(:last-child) {
    margin-bottom: 24px !important;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-title2 {
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-title {
    font-size: 16px;
    font-weight: 700;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-items {
    grid-gap: 8px;
    gap: 8px;
    flex-wrap: wrap;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-items label {
    font-size: 14px;
  }
  .tour-courses .modal-content .modal-body .search-keywords {
    padding: 24px 16px !important;
    grid-gap: 8px;
    gap: 8px;
  }
  .tour-courses .modal-content .modal-body .search-keywords input {
    min-width: calc(100% - 44px);
    max-height: 44px;
    font-size: 14px;
  }
  .tour-courses .modal-content .modal-body .search-keywords .search {
    min-width: 36px;
    min-height: 36px;
    height: 36px;
    aspect-ratio: 1/1;
    box-sizing: border-box;
  }
  .tour-courses .modal-content .modal-body .search-keywords .search span {
    display: none;
  }
  .tour-courses .modal-content .modal-body .filter-results-items {
    flex-direction: column;
    padding: 16px !important;
    grid-gap: 16px;
    gap: 16px;
  }
  .tour-courses .modal-content .modal-body .filter-results-items .course-card {
    width: 100% !important;
  }
  .tour-courses .modal-content .modal-body .filter-results-items .course-card .imgArea {
    width: 100%;
    aspect-ratio: 5/3 !important;
  }
  .tour-courses .modal-content .filter-total {
    padding: 0 16px;
    line-height: 16px;
    font-size: 16px !important;
    font-weight: 700;
  }
  .tour-courses .modal-content .filter-total span {
    font-size: 16px;
    line-height: 16px;
  }
  .tour-courses .modal-content .course-title {
    font-size: 16px !important;
  }
  .tour-courses .modal-content .course-region {
    padding: 0 0 8px !important;
  }
  .tour-courses .modal-content .course-category {
    padding-bottom: 4px;
  }
  .tour-courses .modal-content .pagination {
    display: none;
  }
  .tour-courses .modal-content .res-button {
    display: block;
    text-align: center;
    padding: 24px 16px 0;
  }
  .tour-courses .modal-content .res-button button {
    min-width: 328px;
    width: 100%;
    border-radius: 133.333px;
    height: 48px;
  }
  .tour-courses .modal-content .modal-footer {
    grid-gap: 12px !important;
    gap: 12px !important;
    padding: 0 16px;
    padding-bottom: 36px;
  }
  .tour-courses .modal-content .modal-footer button {
    min-width: unset !important;
    width: calc(50% - 6px) !important;
    border-width: 1px !important;
  }
}

@media (max-width: 1920px) {
  .tour-details-modal {
    min-width: 800px;
  }
}
.tour-details-modal .modal-content {
  width: 100%;
  padding: 50px;
  border-radius: 20px;
}
.tour-details-modal .modal-content .modal-header {
  width: 100%;
  align-items: center;
  background-blend-mode: 0 0 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-header {
    padding-bottom: 0;
  }
}
.tour-details-modal .modal-content .modal-header h6 {
  font-size: 24px;
  font-weight: 700;
}
.tour-details-modal .modal-content .modal-body table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.tour-details-modal .modal-content .modal-body table tr {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  display: table;
  min-height: 60px !important;
  height: -webkit-max-content;
  height: max-content;
  background-color: #f9f9f9;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr {
    background-color: #fff;
  }
}
.tour-details-modal .modal-content .modal-body table tr th {
  display: inline-block;
  width: 180px;
  align-items: center;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr th {
    width: 100%;
  }
}
.tour-details-modal .modal-content .modal-body table tr th h6 {
  font-weight: 700;
  font-size: 14px;
}
.tour-details-modal .modal-content .modal-body table tr td {
  padding: 20px;
  display: inline-block;
  width: calc(100% - 180px);
  margin-right: 0 !important;
  background-color: #fff;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr td {
    width: 100%;
  }
}
.tour-details-modal .modal-content .modal-body table tr td h6,
.tour-details-modal .modal-content .modal-body table tr td p {
  color: #7e7e7e;
  font-weight: 400;
  font-size: 14px;
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.tour-details-modal .modal-content .modal-body table tr td .upload-pic {
  padding: 20px 0;
  grid-gap: 7px;
  gap: 7px;
}
.tour-details-modal .modal-content .modal-body table tr td .upload-pic .imgArea {
  width: calc(33.333% - 7px);
  aspect-ratio: 4/3;
  display: block;
}
@media (max-width: 767px) {
  .tour-details-modal {
    min-width: 328px;
    margin: 90px auto 0;
    margin-top: 0;
  }
  .tour-details-modal .modal-content {
    width: calc(100vw - 32px) !important;
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    overflow: hidden;
    top: 0;
    border-radius: 20px;
    padding: 30px 0;
    overflow-y: scroll;
  }
  .tour-details-modal .modal-content::-webkit-scrollbar {
    display: none;
  }
  .tour-details-modal .modal-content .modal-header {
    border-color: #e7e7e7;
    background-color: #fff;
    z-index: 1;
    margin-bottom: 0 !important;
  }
  .tour-details-modal .modal-content .modal-header .modal-title {
    align-items: center;
    padding-bottom: 8px;
  }
  .tour-details-modal .modal-content .modal-header .modal-title h6 {
    font-size: 16px;
  }
  .tour-details-modal .modal-content .modal-header .modal-title button {
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tour-details-modal .modal-content .modal-body {
    overflow-y: scroll;
    padding: 0 !important;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background: #c3c3c3;
    border-radius: 0;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table {
    width: 100%;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr {
    display: block;
    min-height: 50px !important;
    padding: 20px 0 13px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th,
  .tour-details-modal .modal-content .modal-body .tour-details-table tr td {
    display: block;
    width: 100%;
    height: -moz-fit-content !important;
    height: -webkit-fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    line-height: 20px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th h5,
  .tour-details-modal .modal-content .modal-body .tour-details-table tr td h5 {
    font-size: 14px;
    color: #1b1c13;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th {
    margin-bottom: 8px !important;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th {
    background-color: transparent;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table .upload-pic {
    grid-gap: 12px !important;
    gap: 12px !important;
    padding: 8px 0 12px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table .upload-pic .imgArea {
    overflow: hidden;
    border-radius: 8px;
    width: calc(50% - 6px);
  }
}

@media (max-width: 1920px) {
  .error-page {
    height: calc(100vh - 270px);
    display: flex;
    align-items: center;
  }
  .error-page label {
    width: 296px;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .error-page {
    height: calc(100vh - 210px);
  }
  .error-page label {
    width: calc(100vw - 32px);
    height: 48px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .modal .allchatmodal {
    width: 100vw !important;
    height: 100vh;
    left: 0;
  }
  .modal .allchatmodal .chatModal {
    width: 100% !important;
    height: 100vh !important;
    top: 0;
    border-radius: 0;
  }
  .modal .allchatmodal .chatModal .modal-header {
    flex-direction: column-reverse;
    padding: 0;
    height: 106px;
  }
  .modal .allchatmodal .chatModal .modal-header .footerClose {
    display: block;
    text-align: right;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .modal .allchatmodal .chatModal .modal-header .modal-title {
    display: block;
    padding: 0 16px;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header {
    flex-direction: row;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-right {
    display: none !important;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left {
    display: block !important;
    height: 106px;
    padding: 0 16px;
    grid-gap: 0;
    gap: 0;
    width: 100%;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left svg {
    width: 28px;
    height: 28px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .chat-backgBtn {
    height: 56px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .w-100 {
    height: 56px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .user-name {
    height: 50px;
    line-height: 50px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #Dashboard #searchBar .searchBtn {
    width: 134px;
  }
  .roboto-text #Dashboard #searchBar .search-calendar {
    min-width: 233px;
  }
  .roboto-text #Dashboard .searchContainer .backBtn {
    font-size: 14px;
    font-weight: 400;
  }
  .roboto-text .modalImageContent .hosting-title {
    line-height: 20px !important;
  }
}
@media (max-width: 767px) {
  .roboto-text.custom-modal .pcReviewRating {
    display: none;
  }
  .roboto-text.custom-modal .mobileReviewRating {
    display: block !important;
    margin: 10px auto !important;
  }
  .roboto-text.custom-modal textarea {
    padding: 10px 20px !important;
  }
}


.ReactModal__Overlay--after-open {
  z-index: 1500 !important;
}
