#HostMyOwn input[type=checkbox],
#HostMyOwn input[type=radio] {
  display: none;
}
@media (max-width: 767px) {
  #HostMyOwn {
    padding: 8px 0 24px !important;
  }
  #HostMyOwn .wrap {
    padding: 0 !important;
    margin: 0;
    width: 100%;
  }
  #HostMyOwn .wrap > * {
    padding: 0 16px;
  }
  #HostMyOwn .wrap .tourTitle {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
    align-items: center;
  }
  #HostMyOwn .wrap .tourTitle img {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
  #HostMyOwn .wrap input[type=checkbox],
  #HostMyOwn .wrap input[type=radio] {
    display: none;
  }
  #HostMyOwn .wrap .host-title-with-add {
    flex-direction: column;
    padding-bottom: 24px;
  }
  #HostMyOwn .wrap .host-title-with-add h3 {
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 8px !important;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group {
    gap: 8px;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group button {
    font-size: 14px;
    border-radius: 133.333px;
    height: 44px;
    min-width: 107px;
    padding: 0;
  }
  #HostMyOwn .wrap .host-title-with-add .add-btns-group .add-to-my-wishlist {
    min-width: 213px;
  }
  #HostMyOwn .wrap .add-places,
  #HostMyOwn .wrap .mainAddEventBorder {
    padding: 24px 0 !important;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .wrap .mainAddEventBorder {
    gap: 4px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses {
    height: 40px;
    padding: 0 16px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .num-with-title {
    gap: 8px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .num-with-title .round-number {
    width: 24px;
    height: 24px;
    background-color: #42b6e6;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .selectedLabelInfo {
    display: none !important;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn {
    flex-direction: row-reverse;
    gap: 12px;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .btn {
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    min-width: unset;
    padding: 0;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .mobile-btn {
    display: block !important;
  }
  #HostMyOwn .wrap .mainAddEventBorder .selected-courses .info-with-btn .pc-btn {
    display: none !important;
  }
  #HostMyOwn .wrap .selected-img {
    padding: 24px 16px;
    gap: 12px;
  }
  #HostMyOwn .wrap .selected-img .imgArea {
    width: 100%;
    aspect-ratio: 4/3;
    border-radius: 8px;
    overflow: hidden;
  }
  #HostMyOwn .wrap .thumbnail-upload {
    margin: 24px 16px 0;
    padding: 0;
    height: 44px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    width: calc(100% - 32px);
  }
  #HostMyOwn .wrap .thumbnail-upload input {
    display: none;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name {
    width: 100% !important;
  }
  #HostMyOwn .wrap .thumbnail-upload .fileUploadLabel {
    background-color: #42b6e6;
    color: #fff;
    font-size: 12px;
    height: 44px;
    line-height: 14px;
    font-weight: 700;
    max-width: 140px;
    padding: 7.5px 9px;
    display: inline !important;
  }
  #HostMyOwn .wrap .thumbnail-upload .fileUploadLabel p {
    display: inline !important;
    font-weight: 400;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name {
    width: calc(100% - 140px) !important;
    padding: 0 8px;
  }
  #HostMyOwn .wrap .thumbnail-upload .thumbnail-name a {
    color: #42b6e6;
    font-weight: 700;
  }
  #HostMyOwn .wrap .optional-label {
    color: #42b6e6;
    font-size: 12px;
    padding: 8px 16px 24px;
  }
  #HostMyOwn .wrap .label-groups {
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  #HostMyOwn .wrap .label-groups label {
    height: 40px;
    border-radius: 133.333px;
    min-width: 158px;
    border: 1px solid #dcdcdc;
    color: #7e7e7e;
  }
  #HostMyOwn .wrap .user-basic-info {
    gap: 16px;
    margin-bottom: 24px;
  }
  #HostMyOwn .wrap .user-basic-info .user-img {
    width: 72px;
    aspect-ratio: 1/1;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info {
    gap: 4px;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info .info-title h6 {
    font-size: 14px;
    color: #7e7e7e;
  }
  #HostMyOwn .wrap .user-basic-info .full-my-info .info-title p {
    font-size: 14px;
    color: #1b1c13;
    font-weight: 700;
  }
  #HostMyOwn .wrap .search-calendar {
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;
  }
  #HostMyOwn .wrap .search-calendar input {
    margin-bottom: 0;
  }
  #HostMyOwn .wrap .search-calendar .input-calendar {
    width: 100%;
    max-width: none;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    height: 44px;
    overflow: hidden;
    padding-left: 12px;
    gap: 12px;
  }
  #HostMyOwn .wrap .search-calendar .input-calendar input {
    width: 100%;
    border: none;
    margin-bottom: 0;
    padding: 0;
  }
  #HostMyOwn .wrap .search-calendar input[name=startsAt] {
    width: 100%;
    max-width: none;
  }
  #HostMyOwn .wrap .search-calendar .select {
    width: calc(50% - 6px);
    min-width: 158px;
  }
  #HostMyOwn .wrap .search-calendar .select .dateArea-select__control {
    width: 100%;
    margin: 0 !important;
  }
  #HostMyOwn .wrap .search-calendar .select .dateArea-select__control .dateArea-select__indicator-separator {
    display: none;
  }
  #HostMyOwn .wrap .search-calendar .divder {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #HostMyOwn .wrap .trasporttaion {
    width: 100%;
  }
  #HostMyOwn .wrap .trasporttaion input {
    width: 100% !important;
  }
  #HostMyOwn input:not([type=text]) {
    display: none;
  }
  #HostMyOwn input:not([type=text]):checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #HostMyOwn input[name=startsAt] {
    width: 100%;
  }
  #HostMyOwn .single-day-hosting {
    color: #42b6e6;
    padding: 4px 16px 24px;
  }
  #HostMyOwn .paxnumber {
    width: 100% !important;
  }
  #HostMyOwn .paxnumber input {
    width: 100% !important;
  }
  #HostMyOwn .paxnumber .paxnumText {
    right: 16px;
  }
  #HostMyOwn textarea {
    width: 100%;
    resize: none;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    border-color: #dcdcdc;
  }
  #HostMyOwn .max-length {
    bottom: 40px !important;
    right: 16px;
  }
  #HostMyOwn .editorinfomation {
    margin-bottom: 24px;
  }
  #HostMyOwn .btn-groups {
    gap: 12px;
    padding: 0 !important;
  }
  #HostMyOwn .btn-groups button {
    width: calc(50% - 6px);
    min-width: unset !important;
    border-radius: 133.333px;
    height: 48px;
  }
  #HostMyOwn .user-info {
    border-top: 1px solid #ebebeb;
    padding: 24px 0 0 !important;
  }
  #HostMyOwn .tourAbout {
    padding-bottom: 0 !important;
  }
  #HostMyOwn .tour-info {
    padding: 24px 0 0 !important;
    border-bottom: none !important;
  }
  #HostMyOwn .tour-introduction {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1920px) {
  #HostMyOwn {
    padding: 75px 0 80px;
  }
  #HostMyOwn .wrap > * {
    width: 100%;
  }
  #HostMyOwn .wrap .divder {
    font-size: 20px;
    width: 12px;
    display: block;
    text-align: center;
  }
  #HostMyOwn label,
  #HostMyOwn .schedule,
  #HostMyOwn .input-calendar {
    cursor: pointer;
  }
  #HostMyOwn input:checked ~ label {
    background-color: #ecf8fd;
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
  }
  #HostMyOwn input[type=text],
  #HostMyOwn textarea {
    resize: none;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn input[type=text][name=pax],
  #HostMyOwn textarea[name=pax] {
    border: none !important;
  }
  #HostMyOwn .dateArea-select__placeholder,
  #HostMyOwn .paxnumText {
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn input[name=title] {
    height: 50px;
    margin-bottom: 40px;
  }
  #HostMyOwn textarea {
    padding: 16px;
    width: 100%;
  }
  #HostMyOwn textarea:focus {
    outline: none;
  }
  #HostMyOwn textarea::-webkit-scrollbar {
    display: none;
  }
  #HostMyOwn .host-title-with-add {
    padding-bottom: 20px;
  }
  #HostMyOwn .host-title-with-add h3 {
    font-weight: 700;
    font-size: 30px;
  }
  #HostMyOwn .add-btns-group {
    gap: 12px;
  }
  #HostMyOwn .add-btns-group button {
    min-width: 110px;
    padding: 0 25px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 133.333px;
  }
  #HostMyOwn .view-section-itinerary {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .view-section-itinerary .add-places {
    padding: 100px 0;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  #HostMyOwn .view-section-itinerary .add-places img {
    margin-left: 4px;
  }
  #HostMyOwn .mainAddEventBorder {
    gap: 16px;
    padding: 40px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title {
    gap: 16px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title .round-number {
    width: 24px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    color: #fff;
    background-color: #42b6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .num-with-title .addEventTitle {
    font-size: 14px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn {
    gap: 8px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .mobile-btn {
    display: none !important;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button,
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo {
    border-radius: 133.333px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button img,
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo img {
    display: none;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn .selectedLabelInfo {
    color: #42b6e6;
    border: 1px solid #42b6e6;
    font-weight: 700;
    padding: 0 16px;
  }
  #HostMyOwn .mainAddEventBorder .selected-courses .info-with-btn button {
    min-width: 74px;
  }
  #HostMyOwn .selected-img {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(166px, 1fr));
    width: 100%;
    padding: 30px 0;
    grid-gap: 20px;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .selected-img .imgArea {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 4/3;
  }
  #HostMyOwn .thumbnail-upload {
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0 0;
  }
  #HostMyOwn .thumbnail-upload input {
    display: none;
  }
  #HostMyOwn .thumbnail-upload label {
    width: 158px;
    height: 50px;
    background-color: #42b6e6;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    align-items: center;
  }
  #HostMyOwn .thumbnail-upload label p {
    font-weight: 400;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name {
    width: calc(100% - 158px);
    border: 1px solid #e7e7e7;
    border-radius: 0 8px 8px 0;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 700;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name a {
    color: #42b6e6;
  }
  #HostMyOwn .thumbnail-upload .thumbnail-name p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #HostMyOwn .optional-label {
    padding: 4px 0 40px;
    font-size: 14px;
    color: #42b6e6;
  }
  #HostMyOwn .tourTitle {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  #HostMyOwn .tourTitle img {
    margin-left: 4px;
  }
  #HostMyOwn .tourTitle span {
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
    color: #42b6e6;
  }
  #HostMyOwn .tourAbout {
    padding-bottom: 40px;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .tourAbout input {
    width: 100%;
    height: 50px;
    border: 1px solid #dcdcdc;
    margin-bottom: 40px;
  }
  #HostMyOwn .label-groups {
    gap: 12px;
    color: #7e7e7e;
  }
  #HostMyOwn .label-groups label {
    min-width: 160px;
    max-width: 160px;
    height: 46px;
    border: 1px solid #c9c9c9;
    border-radius: 133.333px;
    font-size: 14px;
  }
  #HostMyOwn .label-groups .trasporttaion {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
  }
  #HostMyOwn .label-groups .trasporttaion input {
    height: 50px;
  }
  #HostMyOwn .create-my-itinerary .user-info {
    padding: 40px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info {
    gap: 30px;
    margin-bottom: 40px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .user-img {
    width: 80px;
    aspect-ratio: 1/1;
    overflow: hidden;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info {
    gap: 12px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title {
    font-size: 14px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title h6 {
    color: #7e7e7e;
    min-width: 90px;
    font-weight: 400;
    font-size: 14px;
  }
  #HostMyOwn .create-my-itinerary .user-info .user-basic-info .full-my-info .info-title p {
    font-size: 14px;
    font-weight: 700;
  }
  #HostMyOwn .create-my-itinerary .tour-info {
    padding: 40px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #HostMyOwn .create-my-itinerary .tour-info input {
    height: 50px;
    border: 1px solid #c9c9c9;
  }
  #HostMyOwn .create-my-itinerary .tour-info .createContainer {
    top: 55px;
  }
  #HostMyOwn .search-calendar {
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
  }
  #HostMyOwn .search-calendar .input-calendar,
  #HostMyOwn .search-calendar .dateArea-select__control,
  #HostMyOwn .search-calendar input[name=startsAt] {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    height: 50px;
  }
  #HostMyOwn .search-calendar .input-calendar {
    min-width: 268px;
    gap: 12px;
    padding: 0 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    overflow: hidden;
  }
  #HostMyOwn .search-calendar .input-calendar input {
    max-width: 186px;
    padding: 0;
    border: none;
    box-sizing: border-box;
  }
  #HostMyOwn .search-calendar .select {
    width: 160px;
  }
  #HostMyOwn .search-calendar .select * {
    cursor: pointer;
  }
  #HostMyOwn .search-calendar .select .dateArea-select__indicator-separator {
    display: none;
  }
  #HostMyOwn .search-calendar input[name=startsAt] {
    width: calc(100% - 648px);
    min-width: 328px;
    max-width: 452px;
    padding: 0 16px;
  }
  #HostMyOwn .single-day-hosting {
    width: 100%;
    color: #42b6e6;
    padding: 4px 0 40px;
    font-size: 14px;
  }
  #HostMyOwn .tour-transportation .label-groups {
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  #HostMyOwn .tour-transportation label {
    padding: 0 10px;
    min-width: 150px;
    max-width: 150px;
    height: 50px;
    text-align: center;
  }
  #HostMyOwn .tour-transportation .trasporttaion {
    width: 452px;
    border-radius: 8px;
    padding: 0 16px;
    border-color: #dcdcdc !important;
  }
  #HostMyOwn .paxnumber {
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #HostMyOwn .paxnumber input {
    width: 440px;
    height: 50px;
  }
  #HostMyOwn .paxnumber .paxnumText {
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  #HostMyOwn .tour-introduction {
    margin-bottom: 40px;
  }
  #HostMyOwn .tour-introduction .max-length {
    right: 16px;
    bottom: 16px;
    font-size: 14px;
  }
  #HostMyOwn .editorinfomation p {
    font-size: 14px;
    font-weight: 400;
  }
  #HostMyOwn .btn-groups {
    padding: 40px 0 80px;
    gap: 20px;
  }
  #HostMyOwn .btn-groups button {
    min-width: 296px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
  }
  #HostMyOwn .react-datepicker__day--disabled {
    opacity: 0.4;
  }
  #HostMyOwn .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #HostMyOwn .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: #000;
  }
}
#HostMyOwn.admin-create .tour-info {
  border-bottom: none;
  padding-bottom: 0;
}
#HostMyOwn.admin-create .btn-groups {
  padding-top: 0;
}

.create-hosting {
  margin-top: 40px;
}
.create-hosting #HostMyOwn {
  padding: 0;
}
.create-hosting #HostMyOwn .wrap {
  margin: 0;
  width: 100%;
  padding: 0;
}

.roboto-text #HostMyOwn input {
  font-size: 14px;
}
.roboto-text #HostMyOwn .info-title h6 {
  font-size: 14px;
  font-weight: 400;
}
.roboto-text #HostMyOwn .info-title p {
  font-size: 14px;
}