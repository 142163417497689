@media (max-width: 1920px) {
  #ViewHost {
    padding: 75px 0 80px;
  }
  #ViewHost .layout {
    gap: 54px;
  }
  #ViewHost .layout .left {
    min-width: 264px;
    max-width: 264px;
  }
  #ViewHost .layout .left .page-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  #ViewHost .layout .left .filter-tab-view-host {
    padding: 32px 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  #ViewHost .layout .left .filter-tab-view-host .tab-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 16px;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input {
    display: none;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup label {
    border: 1px solid #c9c9c9;
    color: #7e7e7e;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
    height: 46px;
    font-size: 14px;
    cursor: pointer;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #ViewHost .layout .right {
    margin-top: 60px;
    min-width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    border-top: 1px solid #e7e7e7;
  }
  #ViewHost .layout .right .view-host-list {
    display: flex;
    flex-wrap: wrap;
  }
  #ViewHost .layout .right .view-host-list .single-local-host {
    width: 50%;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
    gap: 16px;
    cursor: pointer;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .host-img {
    width: 54px;
    height: 54px;
    aspect-ratio: 1/1;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .flag-wrap {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #ViewHost .layout .right .view-host-list .single-local-host .name-with-flag {
    gap: 8px;
    margin-bottom: 8px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah {
    gap: 4px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah > div {
    padding: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 133.333px;
    border: 1px solid #7e7e7e;
    font-weight: 700;
    font-size: 10px;
  }
  #ViewHost .layout .right .pagination {
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 0;
  }
  #ViewHost .layout .right .pagination li {
    min-width: 28px;
    width: -moz-fit-content;
    width: fit-content;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
  }
  #ViewHost .layout .right .pagination li a {
    color: #7e7e7e;
    font-size: 14px;
  }
  #ViewHost .layout .right .pagination li.active {
    border-radius: 100%;
    border: 1px solid #42b6e6;
  }
  #ViewHost .layout .right .pagination li.active a {
    color: #42b6e6;
    font-weight: 700;
  }
  #ViewHost .layout .right .pagination li:not(.disabled) svg path {
    stroke: #42b6e6;
  }
  #ViewHost .layout .right .pagination li.disabled a {
    cursor: default !important;
  }
  #ViewHost .layout .right .pagination li svg {
    width: 20px;
    height: 20px;
  }
  #ViewHost .layout .right .pagination .page-first-arrow,
  #ViewHost .layout .right .pagination .page-prev-arrow {
    margin-right: 8px;
  }
  #ViewHost .layout .right .pagination .page-next-arrow,
  #ViewHost .layout .right .pagination .page-last-arrow {
    margin-left: 8px;
  }
}
@media (max-width: 767px) {
  #ViewHost {
    padding: 0;
  }
  #ViewHost .layout {
    min-height: calc(100vh - 233px);
    gap: 0;
    flex-direction: column;
  }
  #ViewHost .layout .left {
    width: 100vw;
    margin-left: -16px;
    max-width: unset;
  }
  #ViewHost .layout .left .page-title {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding: 0 16px;
    margin-top: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host {
    padding: 20px 24px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    box-shadow: none;
    border-radius: 0;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  #ViewHost .layout .left .filter-tab-view-host > div {
    display: flex;
    justify-content: space-between;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox {
    display: flex;
    flex-direction: row !important;
    width: 220px;
    flex-wrap: wrap;
    gap: 8px;
  }
  #ViewHost .layout .left .filter-tab-view-host .res-checkbox .inputGroup {
    width: calc(50% - 4px);
  }
  #ViewHost .layout .left .filter-tab-view-host .tab-title {
    font-size: 14px;
    padding-bottom: 0;
    line-height: 32px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input {
    display: none;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup label {
    font-size: 14px;
    height: 32px;
  }
  #ViewHost .layout .left .filter-tab-view-host .inputGroup input:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
    font-weight: 700;
    background-color: #ecf8fd;
  }
  #ViewHost .layout .right {
    margin-top: 0;
    width: 100%;
    max-width: unset;
    border-top: 1px solid #e7e7e7;
  }
  #ViewHost .layout .right .view-host-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 8px;
    gap: 20px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host {
    width: 100%;
    padding: 0;
    border-bottom: none;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .host-img {
    width: 48px;
    height: 48px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .name-with-flag {
    gap: 8px;
    margin-bottom: 8px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah {
    gap: 4px;
  }
  #ViewHost .layout .right .view-host-list .single-local-host .pro-tag-suah > div {
    padding: 0 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 133.333px;
    border: 1px solid #7e7e7e;
    font-weight: 700;
    font-size: 12px;
  }
  #ViewHost .layout .right .res-more-viewhost button {
    height: 44px;
  }
}