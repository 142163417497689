@media (max-width: 1920px) {
  #ItineraryDetail {
    padding: 45px 0 80px;
  }
  #ItineraryDetail .gm-style-iw p {
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: center !important;
  }
  #ItineraryDetail .gm-style-iw button {
    display: none !important;
  }
  #ItineraryDetail .img-area {
    width: 100%;
    max-height: 440px;
    gap: 8px;
    overflow: hidden;
    border-radius: 20px;
    aspect-ratio: 16/9;
    margin-bottom: 10px;
  }
  #ItineraryDetail .img-area .big-img {
    width: calc(100% - 368px);
    position: relative;
  }
  #ItineraryDetail .img-area .big-img .itinerary-viewAll-btn {
    position: absolute;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    right: 20px;
    bottom: 30px;
    width: 140px;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .img-area .small-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;
    width: 360px;
  }
  #ItineraryDetail .img-area .small-img .imgArea {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #ItineraryDetail .layout {
    gap: 40px;
  }
  #ItineraryDetail .layout .left {
    width: 75%;
    max-width: calc(100% - 400px);
  }
  #ItineraryDetail .layout .left .ratingStarArea {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .mobile-host-list {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .sidebar-host-button button,
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host button {
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .itinerary-title {
    width: 584px;
    line-height: 40px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    word-break: keep-all;
  }
  #ItineraryDetail .layout .left .heart-section input {
    display: none;
  }
  #ItineraryDetail .layout .left .heart-section svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event svg {
    width: 18px;
    height: 18px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event span {
    position: relative;
    bottom: 1.5px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event {
    gap: 6px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar {
    width: 108px;
    gap: 2px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol {
    display: flex !important;
    align-items: center;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar *,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol *,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol * {
    display: flex;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar svg,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol svg,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar span,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol span,
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .emptySymbol span {
    width: 20px;
    height: 20px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total {
    font-size: 16px;
    line-height: 19.2px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total a {
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .date-start-end {
    display: block;
    font-size: 16px;
    color: #7e7e7e;
    margin-bottom: 6px;
    line-height: 19.2px;
  }
  #ItineraryDetail .layout .left .createdby {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
    line-height: 25.6px;
  }
  #ItineraryDetail .layout .left .itinerary-subtitle {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 25.6px;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    gap: 20px;
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 48px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    gap: 16px;
    width: calc(100% - 20px);
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-num {
    width: 24px;
    height: 24px;
    background-color: #42b6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 45px);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .rotate {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc {
    padding: 32px 0;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    gap: 19px;
    margin-top: -6px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc > div {
    width: calc(100% - 78px);
    margin: 0 auto;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-left {
    width: 128px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right {
    width: calc(100% - 128px);
    font-size: 14px;
    line-height: 20px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right a {
    display: block;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 14px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
    grid-gap: 12px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary .imgArea {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 4/3;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row {
    text-align: center;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    min-width: 160px;
    height: 40px;
    font-weight: 700;
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .about-tour-event {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .about-map {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .about-map h6 {
    font-size: 14px;
    color: #1b1c13;
    margin-bottom: 12px;
    line-height: 20px;
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab {
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab.only-for-host-review {
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab.only-for-host-review .title-deactive {
    border-bottom: none;
    text-align: left;
    padding-bottom: 0;
    height: 46px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3 {
    height: 58px;
    line-height: 20px;
    width: 50%;
    padding: 20px 0 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-active {
    border-bottom: 2px solid #42b6e6;
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-deactive {
    border-bottom: 2px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea {
    width: 100%;
    resize: none;
    padding: 16px;
    border-radius: 8px;
    border-color: #dcdcdc;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea:focus {
    outline: none;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea::-moz-placeholder {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea::placeholder {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment {
    justify-content: end;
    gap: 10px;
    padding-bottom: 24px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment button {
    width: 110px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn:not(:last-child) {
    margin-bottom: 24px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn button {
    height: 40px;
    width: 110px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .chat-reply-inner .single-reply-inner {
    width: calc(100% - 112px) !important;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply {
    padding: 10px 0;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .recomment-image svg {
    width: 36px;
    height: 36px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .host-img {
    width: 36px;
    height: 36px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner {
    width: calc(100% - 56px);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .comment-date {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .full-comment-section {
    margin-top: 5px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .reply-delete-btn {
    font-size: 10px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content {
    font-size: 14px;
    color: #1b1c13;
    line-height: 22.4px;
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 400;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content.deleted-comment {
    color: #aeaeae;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner > .comment-reply {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn {
    margin-bottom: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn button {
    color: #1b1c13;
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn.less svg {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner button.reply, #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner button.delete {
    color: #7e7e7e;
    line-height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment,
  #ItineraryDetail .layout .left .total-review-about .comment-inner .moreComment {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    gap: 4px;
    min-width: 160px;
    justify-content: center;
    padding: 0;
    height: 40px;
    padding: 0 24px 0 32px;
    margin-bottom: 40px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment svg {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .showBtn-last {
    border-top: 1px solid #e7e7e7;
    padding-top: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-per-rating {
    font-size: 20px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star {
    gap: 8px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating span,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .emptySymbol,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .fullySymbole {
    display: flex !important;
    width: 20px;
    height: 20px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating img {
    width: 16px;
    height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review {
    width: calc(50% - 10px);
    background-color: #f9f9f9;
    padding: 20px 24px;
    border-radius: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info {
    display: flex;
    gap: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-img {
    width: 52px;
    height: 52px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-name {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .review-time {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 14px 0;
    position: relative;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single h5 {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .read-more-desk {
    font-size: 14px;
    color: #7e7e7e;
    word-break: keep-all;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .read-more-desk .readMore {
    color: #1b1c13;
    text-decoration: underline;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more {
    display: none;
  }
  #ItineraryDetail .layout .left .ratingStarArea-mobile {
    display: none !important;
  }
  #ItineraryDetail .layout .right {
    width: calc(40% - 40px);
    min-width: 360px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    border-radius: 40px;
    padding: 32px 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .sidebar-host-button {
    padding: 0 32px 32px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .sidebar-host-button button {
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .emptyHostList {
    font-size: 14px;
    color: #7e7e7e;
    padding: 32px 32px 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .host-list {
    padding: 32px 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner {
    gap: 16px;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .host-img {
    width: 54px;
    height: 54px;
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .approval-right {
    bottom: 0;
    right: 0;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag {
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag .pro-name-suah {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .name-with-flag .host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .pro-tag-suah {
    gap: 4px;
    font-size: 10px;
    font-weight: 700;
    height: 28px;
    line-height: 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .host-inner .pro-tag-suah > * {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .suah-date-time {
    font-size: 12px;
    color: #7e7e7e;
    margin-bottom: 8px;
    height: 16px;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .single-host-info .total-host-join {
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list .single-local-host .info-arrow-btn {
    margin-top: -6px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost {
    padding: 20px 0 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat {
    gap: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat input {
    display: none;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .like-with-chat svg {
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .host-inner {
    margin-bottom: 32px !important;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .host-inner:last-child {
    margin-bottom: 0 !important;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-local-host {
    padding: 0 32px 32px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .btnbox {
    margin-top: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .btnbox button {
    height: 50px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host {
    padding: 0 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .emptymain,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc .emptymain,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .emptymain {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .empty,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc .empty,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .empty {
    color: #7e7e7e;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info {
    gap: 20px;
    padding: 32px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about {
    display: flex;
    justify-content: space-between;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about h3 {
    width: 106px;
    font-size: 14px;
    color: #7e7e7e;
    display: block;
    width: 106px;
    font-weight: 400;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-info .single-row-about h4 {
    width: calc(100% - 106px);
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
    text-align: right;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc {
    padding: 32px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .about-itinery-desc p {
    margin-bottom: 0;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-host-appstatus {
    margin-top: 32px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    line-height: 20px;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .single-host-appstatus:not(:last-child) {
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .cancleApplicationbtn {
    font-size: 12px;
    font-weight: 700;
    color: #42b6e6;
    line-height: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    font-size: 14px;
    gap: 16px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .total-pax-joining {
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left {
    align-items: center;
    gap: 14px;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left .host-img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-left .host-name {
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .profile-right,
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag {
    gap: 4px;
    align-items: center;
    cursor: default;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag > * {
    width: 35px;
    font-size: 10px;
    border: 1px solid #1b1c13;
    border-radius: 133.333px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .pax-list-host .single-pax-list .host-tag > * p {
    width: 100%;
    text-align: center;
  }
  #ItineraryDetail .layout .right .hosting-inner-list.byHost .open-image-overlap {
    position: absolute;
  }
  #ItineraryDetail .create-hosting {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list {
    padding: 32px 20px;
    margin-top: 24px;
    background-color: #f9f9f9;
    border-radius: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .color-gray {
    color: #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list .Noapplication {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: #7e7e7e;
    margin-top: 20px;
    font-weight: 400;
  }
  #ItineraryDetail .my-trip-requst-list .request-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 9px;
    line-height: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .request-tabs {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    line-height: 16px;
  }
  #ItineraryDetail .my-trip-requst-list .request-tabs .active {
    color: #42b6e6;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list {
    padding: 20px 0;
    gap: 20px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list:last-child {
    padding-bottom: 0;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info {
    width: 162px;
    gap: 16px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .host-img {
    min-width: 54px;
    height: 54px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag {
    width: calc(100% - 70px);
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag {
    width: 100%;
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
    max-width: calc(100% - 32px);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .host-tag {
    gap: 4px;
    width: 100%;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .host-info .name-with-tag .host-tag > div p {
    display: flex;
    align-items: center;
    min-width: 43px;
    width: -moz-max-content;
    width: max-content;
    height: 28px;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .applicationDate {
    font-size: 12px;
    color: #1b1c13;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns {
    gap: 8px;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns button {
    width: 72px;
    height: 36px;
    border-radius: 133.333px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns .tooltiptext {
    display: none;
  }
  #ItineraryDetail .my-trip-requst-list .single-request-list .request-btns .Applidisabled:hover + .tooltiptext {
    display: block;
    position: absolute;
    background-color: #444;
    color: #fff;
    font-size: 1rem;
    padding: 5px 15px;
    border-radius: 8px;
    width: -moz-max-content;
    width: max-content;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  #ItineraryDetail .ck-toolbar {
    display: none;
  }
  #ItineraryDetail .ck-editor__editable_inline {
    min-height: unset;
    margin-bottom: 0;
  }
  #ItineraryDetail .ck-content {
    border: none;
    padding: 0;
  }
  #ItineraryDetail .ck-content ul,
  #ItineraryDetail .ck-content ol {
    padding-left: 20px;
  }
  #ItineraryDetail .ck-content.ck-focused {
    box-shadow: none !important;
  }
  #ItineraryDetail .ck-content .ck-widget_selected {
    border: none !important;
    outline: none !important;
  }
  #ItineraryDetail .ck-content .ck-widget__selection-handle {
    display: none !important;
  }
  #ItineraryDetail .ck-content .ck-editor__nested-editable_focused {
    box-shadow: none !important;
    background-color: transparent !important;
    outline: none !important;
  }
  #ItineraryDetail .ck-content .ck-editor__editable_selected:after {
    background-color: transparent;
  }
  .roboto-text #ItineraryDetail .layout .left .itinerary-title {
    line-height: 36px;
  }
  .roboto-text #ItineraryDetail .layout .left .location-single-event {
    line-height: 20px;
  }
  .roboto-text #ItineraryDetail .layout .left .ratting-country-row {
    gap: 12px;
    line-height: 20px;
    position: relative;
  }
  .roboto-text #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    gap: 4px;
    align-items: center;
  }
  .roboto-text #ItineraryDetail .layout .left .star-single-event {
    gap: 12px;
  }
  .roboto-text #ItineraryDetail .layout .left .star-single-event .innere-itinarary-total {
    line-height: 16px;
  }
  .roboto-text #ItineraryDetail .layout .left .itinerary-subtitle {
    line-height: 20px;
    height: 26px;
  }
  .roboto-text #ItineraryDetail .layout .left .about-map .itinerary-subtitle {
    height: 20px;
    margin-bottom: 12px;
  }
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    width: calc(100% - 20px);
  }
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    line-height: 20px;
    width: calc(100% - 45px);
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 35px);
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .comment-content {
    line-height: 20px !important;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .review-tab {
    height: 58px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .user-name {
    line-height: 20px;
  }
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .comment-date {
    line-height: 16px;
  }
  .roboto-text #ItineraryDetail .layout .left .single-reply-inner .reply-delete-btn {
    line-height: 12px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .layout .right .single-pax-list .host-gender {
    width: 50px !important;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .ratting-country-row span {
    position: relative;
    top: 0.5px;
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .ratting-country-row .star-single-event {
    position: relative;
    top: 1.5px;
  }
  .roboto-text #ItineraryDetail .ratting-country-row .innere-itinarary-total {
    position: relative;
    bottom: 2px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .ratting-country-row .ratingStarArea {
    position: relative;
    bottom: 1.5px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #ItineraryDetail .rating-with-single h5 {
    position: relative;
    top: 1.5px;
  }
}
@media (max-width: 767px) {
  #ItineraryDetail {
    padding: 0;
  }
  #ItineraryDetail .empty {
    margin-top: 16px;
    font-size: 14px;
    color: #7e7e7e;
  }
  #ItineraryDetail .wrap {
    margin: 0;
    width: 100vw;
  }
  #ItineraryDetail .img-area {
    min-height: 230px;
    border-radius: 0;
    gap: 0;
  }
  #ItineraryDetail .img-area .big-img {
    width: 50%;
    aspect-ratio: 1/2;
  }
  #ItineraryDetail .img-area .big-img .itinerary-viewAll-btn {
    left: 12px;
    right: unset;
    bottom: 12px;
  }
  #ItineraryDetail .img-area .small-img {
    width: 50%;
    gap: 0;
  }
  #ItineraryDetail .layout {
    flex-direction: column;
  }
  #ItineraryDetail .layout .left {
    width: 100%;
    max-width: unset;
  }
  #ItineraryDetail .layout .left .ratingStarArea-mobile {
    display: flex !important;
    align-items: center;
  }
  #ItineraryDetail .layout .left > * {
    padding: 0 16px;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    padding: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list {
    display: block;
    padding: 20px 16px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .img-join-host {
    gap: 8px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .img-join-host .flag-wrap {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationUserTitel {
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .host-gender,
  #ItineraryDetail .layout .left .mobile-host-list .gender-tages p {
    width: 66px;
    justify-content: center;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationGender {
    gap: 4px;
    font-size: 12px;
    margin-top: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .applicationGender .info {
    padding: 0 12px;
    height: 24px;
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    display: flex;
    align-items: center;
    line-height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list button {
    height: 48px;
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .cancleApplicationbtn {
    color: #42b6e6;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .emptyHostList {
    color: #7e7e7e;
    font-size: 14px;
    padding-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .host-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host {
    width: 100%;
    box-shadow: 0px 0px 13.4867px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info {
    width: 100%;
    position: relative;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner {
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .host-img {
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .approval-right {
    bottom: 0;
    right: 0;
    width: 16px;
    height: g16px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag {
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag .pro-name-suah {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag .host-flag {
    width: 24px;
    height: 24px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .name-with-flag {
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .pro-tag-suah {
    gap: 4px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .host-inner .pro-tag-suah > * {
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: 700;
    border-radius: 133.333px;
    font-size: 12px;
    padding: 0 12px;
    border: 1px solid #1b1c13;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .info-arrow-btn {
    display: none !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .total-host-join {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .single-local-host .single-host-info .suah-date-time {
    font-size: 12px;
    padding-left: 52px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .info-arrow-btn {
    display: none !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .res-more-viewhost button {
    height: 44px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host {
    position: relative;
    padding: 0;
    box-shadow: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .single-host-info .host-inner {
    gap: 16px;
    margin-bottom: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .single-host-info .host-inner .host-img {
    width: 48px;
    height: 48px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat {
    position: absolute;
    right: 0;
    gap: 12px;
    z-index: 1;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat svg {
    width: 24px;
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-local-host .like-with-chat input {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .single-host-appstatus {
    font-weight: 700;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info {
    width: 100vw;
    margin-left: -16px;
    padding: 20px 16px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div h3 {
    width: 100px;
    font-size: 14px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-info > div h4 {
    text-align: right;
    width: calc(100% - 100px);
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-desc {
    width: 100vw;
    margin-left: -16px;
    padding: 20px 16px;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .about-itinery-desc pre {
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host {
    padding-top: 20px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .emptymain {
    font-size: 16px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .total-pax-joining {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list:not(:last-child) {
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left {
    gap: 12px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left .host-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-left .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right {
    gap: 8px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-flag {
    width: 24px;
    height: 24px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-tag {
    gap: 4px;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .mobile-host-list .byHost .pax-list-host .single-pax-list .profile-right .host-tag > div {
    border-radius: 133.333px;
    border: 1px solid #1b1c13;
    padding: 0 12px;
    height: 24px;
    align-items: center;
    display: flex;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list {
    background-color: #f9f9f9;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .request-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .request-title span {
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs span {
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs .active {
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .details-tabs span:not(:last-child):after {
    content: " | ";
    color: #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div {
    flex-direction: column;
    gap: 12px;
    justify-content: start;
    align-items: baseline !important;
    padding: 16px 0;
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div:last-child {
    padding-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .host {
    gap: 12px;
    justify-content: start;
    align-items: center;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .host .host-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .res-application {
    flex-direction: column;
    padding-left: 52px;
    align-items: baseline !important;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .res-application .applicationDate {
    font-size: 12px;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox {
    display: flex;
    gap: 8px;
    text-align: left;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox button {
    width: 64px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .secMb111 > div .ApplicationChatBox .tooltiptext {
    display: none;
  }
  #ItineraryDetail .layout .left .mobile-host-list .mobile-request-list .Noapplication p {
    font-size: 14px;
    color: #7e7e7e;
    text-align: left;
  }
  #ItineraryDetail .layout .left .itinerary-title {
    width: calc(100% - 60px);
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .heart-section input {
    display: none;
  }
  #ItineraryDetail .layout .left .heart-section svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row {
    gap: 16px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event {
    min-width: 134px;
    width: -moz-max-content;
    width: max-content;
    max-width: 50%;
    height: 20px;
    gap: 4px;
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event svg {
    width: 16px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .location-single-event span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event {
    gap: 2px;
    height: 20px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .ratingStar {
    display: none !important;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar {
    display: flex !important;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar * {
    display: flex;
    align-items: center;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .res-ratingStar * svg {
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .layout .left .ratting-country-row .star-single-event .innere-itinarary-total {
    font-size: 12px;
  }
  #ItineraryDetail .layout .left .date-start-end {
    font-size: 14px;
    margin-bottom: 4px;
  }
  #ItineraryDetail .layout .left .createdby {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .contents {
    padding: 0;
  }
  #ItineraryDetail .layout .left .contents > * {
    padding: 20px 16px;
  }
  #ItineraryDetail .layout .left .itinerary-subtitle {
    margin-bottom: 0;
    line-height: 20px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .name-with-arrow {
    height: 32px !important;
  }
  #ItineraryDetail .layout .left .accordian-itinery {
    border: none;
    gap: 4px;
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow {
    height: 40px !important;
    cursor: pointer;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title {
    gap: 8px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-num {
    font-size: 14px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .num-with-title .accor-title {
    width: calc(100% - 35px);
    font-size: 14px;
    font-weight: 400;
  }
  #ItineraryDetail .layout .left .accordian-itinery .name-with-arrow .rotate {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc {
    width: 100vw;
    margin-left: -16px;
    border-radius: 0;
    margin-top: 0;
    border-left: none;
    border-right: none;
    padding: 17px 16px;
    gap: 16px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc > div {
    width: 100%;
    flex-direction: column;
    text-align: left;
    align-items: baseline !important;
    gap: 4px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right {
    width: 100%;
    word-break: keep-all;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right a {
    font-size: 14px;
    color: #1b1c13;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .accor-right.image-sub-itinerary .imgArea {
    width: calc(50% - 6px);
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row {
    text-align: center;
  }
  #ItineraryDetail .layout .left .accordian-itinery .show-acc .add-to-wishlist-row button {
    min-width: 185px;
    width: unset;
    height: 44px;
  }
  #ItineraryDetail .layout .left .about-tour-event {
    margin-bottom: 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .about-tour-event pre {
    font-size: 14px;
    color: #1b1c13;
    line-height: 20px;
  }
  #ItineraryDetail .layout .left .about-map {
    margin-bottom: 0;
  }
  #ItineraryDetail .layout .left .about-map h6 {
    font-size: 14px;
    color: #1b1c13;
    padding-bottom: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .only-for-host-review {
    padding-left: 16px;
    height: 20px;
    align-items: center;
    margin-bottom: 16px !important;
  }
  #ItineraryDetail .layout .left .total-review-about .only-for-host-review .title-deactive {
    height: 20px !important;
    padding: 0;
  }
  #ItineraryDetail .layout .left .total-review-about .recomment-image svg {
    width: 10px !important;
    height: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab {
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
    margin-left: -16px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3 {
    width: 50%;
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    height: 40px;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-active {
    border-bottom: 2px solid #42b6e6;
    color: #42b6e6;
  }
  #ItineraryDetail .layout .left .total-review-about .review-tab h3.title-deactive {
    border-bottom: 2px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea {
    padding: 10px 12px;
    font-size: 14px;
    margin-bottom: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner textarea:focus {
    outline: none;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment {
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .reply-comment button {
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn:last-child {
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .comment-btn button {
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply {
    padding: 10px 0;
    gap: 8px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner {
    width: calc(100% - 42px);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner.single-reply-inner2 {
    width: calc(100% - 36px) !important;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details {
    gap: 5px;
    margin-bottom: 5px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .user-name {
    font-size: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .name-details .comment-date {
    font-size: 12px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .reply-delete-btn {
    font-size: 10px;
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content {
    font-size: 14px;
    color: #1b1c13;
    line-height: 20px;
    display: flex;
    gap: 6px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .single-reply .single-reply-inner .comment-content.deleted-comment {
    color: #7e7e7e;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner > .comment-reply {
    border-top: 1px solid #e7e7e7;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn {
    padding-bottom: 10px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn button {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .chat-reply-inner .showBtn.less svg {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment,
  #ItineraryDetail .layout .left .total-review-about .comment-inner .moreComment {
    margin-bottom: 32px;
    width: 100%;
    height: 44px;
  }
  #ItineraryDetail .layout .left .total-review-about .comment-inner .lessComment svg {
    transform: rotate(180deg);
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star {
    padding-top: 4px;
    padding-left: 16px;
    margin-bottom: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating span,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .emptySymbol,
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating .fullySymbole {
    display: flex !important;
    width: 20px;
    height: 20px;
    align-items: center;
  }
  #ItineraryDetail .layout .left .total-review-about .total-rating-with-star .tour-rating img {
    width: 16px;
    height: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row {
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review {
    width: 100%;
    padding: 16px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review:last-child {
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info {
    gap: 12px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-img {
    width: 40px;
    height: 40px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .user-review-info .user-name {
    padding-bottom: 4px;
  }
  #ItineraryDetail .layout .left .total-review-about .host-review-row .single-host-review .rating-with-single {
    margin: 12px 0;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more {
    display: block;
    margin-bottom: 32px;
  }
  #ItineraryDetail .layout .left .total-review-about .res-more button {
    height: 44px;
    font-style: 14px;
    font-weight: 700;
  }
  #ItineraryDetail .layout .right {
    display: none;
  }
  #ItineraryDetail .mobile-comment .mobile-comment-user {
    gap: 8px;
    margin-bottom: 8px;
    position: relative;
  }
  #ItineraryDetail .mobile-comment pre {
    font-size: 14px;
  }
  #ItineraryDetail .mobile-comment .deleted-comment {
    font-size: 14px;
    color: #aeaeae;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
  }
  #ItineraryDetail .mobile-comment .host-img {
    width: 28px !important;
    height: 28px !important;
    aspect-ratio: 1/1;
  }
  #ItineraryDetail .mobile-comment .name-details {
    margin-bottom: 0 !important;
    gap: 0 !important;
  }
  #ItineraryDetail .mobile-comment .name-details .user-name,
  #ItineraryDetail .mobile-comment .name-details .comment-date,
  #ItineraryDetail .mobile-comment .name-details .reply-delete-btn {
    line-height: 20px !important;
  }
  #ItineraryDetail .full-comment-section {
    padding-left: 36px;
  }
  .roboto-text #ItineraryDetail .itinerary-title {
    line-height: 22px !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .roboto-text #ItineraryDetail .ratting-country-row svg {
    margin-bottom: 1.5px !important;
  }
}