@media (max-width: 1920px) {
  #SignUp form {
    width: 460px;
    margin: 0 auto;
    padding: 40px 30px 92px;
  }
  #SignUp form .email-input {
    margin-bottom: 10px;
  }
  #SignUp form .page-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 30px !important;
  }
  #SignUp form .input-field {
    padding-bottom: 40px;
    align-items: center;
    position: relative;
  }
  #SignUp form .input-field > *:not(label) {
    font-size: 14px;
  }
  #SignUp form .input-field .phone-field {
    width: 100%;
  }
  #SignUp form .input-field label {
    width: 100%;
    font-weight: 700;
    align-items: center;
    margin-bottom: 20px !important;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  #SignUp form .input-field label img {
    margin-left: 4px;
  }
  #SignUp form .input-field input {
    padding: 0 20px;
    font-size: 14px;
  }
  #SignUp form .input-field input[type=checkbox] {
    display: none;
  }
  #SignUp form .input-field input,
  #SignUp form .input-field .input-style__control {
    width: 100%;
    height: 50px;
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }
  #SignUp form .input-field > div {
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #SignUp form .input-field > div button {
    margin-right: 6px;
  }
  #SignUp form .input-field > div.birth-field, #SignUp form .input-field > div.phone-verify {
    border: none;
  }
  #SignUp form .input-field .inputWithBtn {
    width: calc(100% - 102px);
    margin: 0;
    border: none;
    border-radius: 0;
  }
  #SignUp form .input-field .input-style__indicator-separator {
    display: none;
  }
  #SignUp form .input-field .input-style__single-value {
    line-height: 50px;
    height: 50px;
    padding: 0 !important;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100% !important;
  }
  #SignUp form .input-field .name-field {
    border: none;
  }
  #SignUp form .input-field .name-field input {
    width: 194px;
  }
  #SignUp form .input-field .birth-field .input-style__control {
    width: 126px;
  }
  #SignUp form .input-field .phone-field .input-style__control {
    width: 126px;
  }
  #SignUp form .input-field .phone-field input {
    width: 262px;
  }
  #SignUp form .input-field .input-style__value-container {
    padding: 0 20px !important;
  }
  #SignUp form .input-field .input-style__single-value {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 16px 0 !important;
  }
  #SignUp form .input-field .signupButton {
    border-radius: 133.333px;
    background-color: #42b6e6;
    font-size: 12px;
    font-weight: 700;
    height: 38px;
    color: #fff;
    width: 96px;
  }
  #SignUp form .agree-area label {
    margin-bottom: 0 !important;
  }
  #SignUp form .agree-area label .d-flex {
    gap: 4px;
  }
  #SignUp form .agree-area label span {
    font-size: 14px;
  }
  #SignUp form .agree-area .input-field {
    justify-content: space-between;
  }
  #SignUp form .agree-area .input-field:nth-child(2) {
    padding-top: 20px;
  }
  #SignUp form .agree-area .agree-all {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 20px;
  }
  #SignUp form .agree-area label {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    gap: 12px;
  }
  #SignUp form .agree-area button {
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    color: #42b6e6;
  }
  #SignUp form .joinBtn {
    margin-bottom: 40px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #SignUp form .have-account-row {
    text-align: center;
    font-weight: 700;
  }
  #SignUp form .have-account-row span {
    color: #42b6e6;
    cursor: pointer;
  }
  #SignUp form .signup-form-error {
    color: #ff5f5f;
    position: absolute;
    left: 21px;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }
  #SignUp form .signup-form-ok {
    position: absolute;
    color: #42b6e6;
    padding-left: 21px;
    width: 100%;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
  }
  #SignUp form button:disabled {
    background-color: #aeaeae !important;
    color: #dcdcdc !important;
  }
  #SignUp form .disabled-input {
    background-color: #dcdcdc !important;
  }
  #SignUp form input:focus {
    outline: none;
  }
  #SignUp form .input-style__placeholder {
    font-size: 14px;
  }
  .roboto-text #SignUp .signupButton {
    line-height: 16px;
  }
  .roboto-text #SignUp .agree-area label[for=privacyAndCookis] span {
    width: 232px;
  }
}
@media (max-width: 1920px) and (max-width: 767px) {
  .roboto-text #SignUp .agree-area label span {
    width: 218px;
  }
}
@media (max-width: 767px) {
  #SignUp form {
    width: 100%;
    margin: 0 auto;
    padding: 40px 16px 92px;
  }
  #SignUp form .page-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 30px !important;
  }
  #SignUp form .input-field {
    padding: 0;
    margin-bottom: 24px;
    align-items: center;
  }
  #SignUp form .input-field label {
    width: 100%;
    font-weight: 700;
    align-items: center;
    margin-bottom: 8px !important;
  }
  #SignUp form .input-field label img {
    margin-left: 4px;
  }
  #SignUp form .input-field input {
    padding: 0 29px;
    font-size: 14px;
    padding: 0 16px;
    height: 44px;
  }
  #SignUp form .input-field input[type=checkbox] {
    display: none;
  }
  #SignUp form .input-field input,
  #SignUp form .input-field .input-style__control {
    width: 100%;
    height: 44px;
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }
  #SignUp form .input-field .input-style__indicator-separator {
    display: none;
  }
  #SignUp form .input-field .input-style__single-value {
    line-height: 50px;
    height: 50px;
    padding: 0 !important;
  }
  #SignUp form .input-field .name-field {
    gap: 8px;
  }
  #SignUp form .input-field .name-field div {
    width: calc(50% - 4px);
  }
  #SignUp form .input-field .name-field input {
    width: 100%;
  }
  #SignUp form .input-field .birth-field {
    gap: 8px;
  }
  #SignUp form .input-field .birth-field .input-style__control {
    width: 100%;
  }
  #SignUp form .input-field .birth-field > * {
    width: calc(33.3333% - 4px);
  }
  #SignUp form .input-field .phone-field {
    gap: 8px;
  }
  #SignUp form .input-field .phone-field > *:first-child {
    min-width: 126px;
    width: calc(33.3333% - 4px);
  }
  #SignUp form .input-field .phone-field > *:nth-child(2) {
    width: calc(66.6667% - 4px);
  }
  #SignUp form .input-field .phone-field > *:nth-child(2) input {
    width: 100%;
  }
  #SignUp form .input-field .phone-field .input-style__control {
    width: 100%;
  }
  #SignUp form .input-field .phone-field input {
    width: 262px;
  }
  #SignUp form .input-field .input-style__value-container {
    padding: 0 20px !important;
  }
  #SignUp form .input-field .input-style__single-value {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 16px 0 !important;
  }
  #SignUp form .input-field .signupButton {
    border-radius: 133.333px;
    background-color: #42b6e6;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    line-height: 12px;
    color: #fff;
    width: 86px;
  }
  #SignUp form .agree-area .input-field {
    justify-content: space-between;
  }
  #SignUp form .agree-area .agree-all {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  #SignUp form .agree-area label {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    gap: 12px;
    margin-bottom: 0 !important;
  }
  #SignUp form .agree-area label svg:first-child {
    width: 20px;
  }
  #SignUp form .agree-area label span {
    display: block;
    font-size: 14px;
  }
  #SignUp form .agree-area button {
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    color: #42b6e6;
  }
  #SignUp form .joinBtn {
    margin-bottom: 40px;
    border-radius: 133.333px;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
  }
  #SignUp form .have-account-row {
    text-align: center;
    font-weight: 700;
  }
  #SignUp form .have-account-row span {
    color: #42b6e6;
    cursor: pointer;
  }
  #SignUp form .signup-form-error {
    color: #ff5f5f;
    position: relative;
    left: 21px;
    top: 4px;
  }
  #SignUp form .signup-form-ok {
    position: relative;
    color: #42b6e6;
    padding-left: 21px;
    width: 100%;
    top: 4px;
  }
  #SignUp form input[type=email] {
    margin-bottom: 10px;
  }
  #SignUp form .disabled {
    background-color: #aeaeae !important;
  }
  #SignUp form input:focus {
    outline: none;
  }
}