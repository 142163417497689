@media (max-width: 1920px) {
  #header {
    height: 70px;
    background: rgba(64, 45, 37, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  }
  #header .wrap {
    height: 70px;
  }
  #header .off,
  #header .chatOff {
    opacity: 0.4;
  }
  #header.main-header .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
  }
  #header.main-header .loginBtn,
  #header.main-header .loginBtn,
  #header.main-header .user-name {
    font-size: 12px;
    color: #fff;
  }
  #header.main-header .loginBtn {
    border: 1px solid #fff;
  }
  #header.sub-header {
    background-color: #fff;
  }
  #header.sub-header .logo svg path {
    fill: #42b6e6;
  }
  #header.sub-header .chatIcon svg path:not(.noFill),
  #header.sub-header .chatIcon svg rect {
    fill: #1b1c13;
  }
  #header.sub-header .notifyIcon svg path,
  #header.sub-header .notifyIcon svg rect {
    stroke: #1b1c13;
  }
  #header.sub-header .loginBtn {
    color: #1b1c13;
    border: 1px solid #1b1c13;
  }
  #header .logo {
    top: 3.5px;
    width: 120px;
  }
  #header .right-button {
    gap: 16px;
  }
  #header .right-button.login .user-name {
    margin-right: 8px;
  }
  #header .right-button .loginBtn,
  #header .right-button .lang,
  #header .right-button .user-name {
    font-weight: 700;
    font-size: 12px;
  }
  #header .right-button .user-name {
    text-decoration: underline;
  }
  #header .right-button .chatIcon,
  #header .right-button .notifyIcon {
    width: 32px;
    height: 32px;
    aspect-ratio: 1/1;
  }
  #header .right-button .loginBtn {
    height: 38px;
    width: 92px;
    border-radius: 133.333px;
  }
  #header .right-button button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header .right-button .user-img {
    width: 26px;
    height: 26px;
    aspect-ratio: 1/1;
    margin-right: 8px;
  }
  #header .lnb {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  #header .lnb.lnb_wrap {
    overflow: hidden;
  }
  #header .lnb_profile {
    width: 240px;
    padding: 20px 0;
  }
  #header .lnb_profile > a {
    display: block;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    color: #1b1c13;
    padding: 0 20px;
  }
  #header .lnb_profile .ChatBoxInner {
    padding: 0 20px;
    height: 48px;
  }
  #header .lnb_profile .ChatBoxInner .chat-setting {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #1b1c13;
  }
  #header .lnb_profile .ChatBoxInner .font-blue {
    margin-right: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
  #header .lnb_notify {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    width: 232px;
    height: 234px;
    padding: 12px 0;
    overflow-y: scroll;
  }
  #header .lnb_notify > p {
    padding: 12px 20px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    color: #aeaeae;
    cursor: pointer;
  }
  #header .lnb_notify > p:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
  #header .lnb_notify > p.new-notify {
    color: #1b1c13;
  }
  #header .lnb_lang {
    width: 120px;
    padding: 16px 0;
  }
  #header .lnb_lang button {
    padding: 0 20px;
    width: 100%;
    height: 40px;
    text-align: left;
    font-size: 14px;
    color: #1b1c13;
  }
  #header .lnb_lang button.on {
    background-color: #ecf8fd;
    font-weight: 700;
  }
  #header #SmallHeader {
    max-width: 606px;
    min-height: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 133.333px;
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    padding: 6px 6px 6px 24px;
  }
  #header #SmallHeader .searchBar {
    position: relative;
    min-height: 56px;
  }
  #header #SmallHeader .searchBar input {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #1b1c13;
    font-weight: 400;
  }
  #header #SmallHeader .searchBar input::-moz-placeholder {
    color: #aeaeae;
  }
  #header #SmallHeader .searchBar input::placeholder {
    color: #aeaeae;
  }
  #header #SmallHeader .searchBar .find-city-location {
    min-width: 282px;
  }
  #header #SmallHeader .searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #header #SmallHeader .searchBar .find-city-location svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #header #SmallHeader .searchBar .find-city-location svg:last-child {
    margin-right: 30px;
  }
  #header #SmallHeader .searchBar .find-city-location input {
    width: calc(100% - 78px);
    margin-left: 8px;
  }
  #header #SmallHeader .searchBar .search-calendar {
    min-width: 237px;
  }
  #header #SmallHeader .searchBar .search-calendar svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
    margin-left: 30px;
    margin-right: 8px;
  }
  #header #SmallHeader .searchBar .search-calendar .dateArea {
    width: calc(100% - 58px);
  }
  #header #SmallHeader .searchBar .search-calendar .dateArea input {
    min-width: 179px;
  }
  #header #SmallHeader .searchBar .searchIcon {
    width: 56px;
    height: 56px;
    background-color: #42b6e6;
    border-radius: 100%;
  }
  #header #SmallHeader .searchBar .searchIcon span {
    display: none;
  }
  #header #SmallHeader .searchBar .searchIcon svg {
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #header #SmallHeader .searchContainer {
    position: absolute;
    min-width: 606px;
    left: -24px;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 28px;
    top: 66px;
  }
  #header #SmallHeader .searchContainer .searchFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
  }
  #header #SmallHeader .searchContainer .searc-bar-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
  }
  #header #SmallHeader .searchContainer .country_list {
    margin-top: 23px;
    gap: 10px;
    flex-wrap: wrap;
  }
  #header #SmallHeader .searchContainer .country_list input[type=radio] {
    display: none;
  }
  #header #SmallHeader .searchContainer .country_list input[type=radio]:checked ~ label {
    border-color: #42b6e6;
    color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .country_list label {
    padding: 0 16px;
    border-radius: 20px;
    border: 1px solid #dcdcdc;
    height: 36px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1c13;
    cursor: pointer;
  }
  #header #SmallHeader .searchContainer .country_list label:hover {
    font-weight: 700;
    border-color: #42b6e6;
    color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .backBtn {
    font-size: 12px;
    color: #1b1c13;
    font-weight: 700;
    padding-bottom: 12px;
  }
  #header #SmallHeader .searchContainer.mobile-calendar {
    display: none !important;
  }
  #header #SmallHeader .searchContainer .custom-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    border: none;
  }
  #header #SmallHeader .searchContainer .react-datepicker__month-container {
    width: calc(50% - 16px);
  }
  #header #SmallHeader .searchContainer .react-datepicker__month-container .react-datepicker__header--custom {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  #header #SmallHeader .searchContainer .datepickerHeader {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    margin-bottom: 10px;
  }
  #header #SmallHeader .searchContainer .datepickerHeader .btn {
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day-names,
  #header #SmallHeader .searchContainer .react-datepicker__month,
  #header #SmallHeader .searchContainer .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__month {
    flex-direction: column;
    margin: 0;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day-name,
  #header #SmallHeader .searchContainer .react-datepicker__day {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--weekend:first-child {
    color: #ff5f5f;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled {
    opacity: 0.4;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day {
    font-weight: 700;
    font-size: 13px;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    background-color: #42b6e6;
    color: #fff;
    border-radius: 100%;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--selected {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    background-color: #ecf8fd;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--selected span {
    background-color: #42b6e6;
    border-radius: 100%;
    color: #fff;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: #ecf8fd;
    color: #1b1c13;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range:hover {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
    background-color: #ecf8fd !important;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-selecting-range:hover span {
    border-radius: 100% !important;
    background-color: #42b6e6 !important;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-start {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: #ecf8fd;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-start span {
    border-radius: 100%;
    background-color: #42b6e6;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--in-range {
    border-radius: 0;
    background-color: #ecf8fd;
    color: #1b1c13;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-end {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
  #header #SmallHeader .searchContainer .react-datepicker__day--range-end span {
    background-color: #42b6e6;
    border-radius: 100%;
    color: #fff;
  }
  .roboto-text #header .lnb_profile > a {
    font-size: 12px;
  }
  .roboto-text #header .lnb_profile .ChatBoxInner .chat-setting {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  #header {
    height: 56px;
  }
  #header #SmallHeader {
    display: none !important;
  }
  #header .wrap {
    min-width: 328px;
    height: 56px;
  }
  #header.main-header {
    background-color: #fff;
  }
  #header .logo {
    width: 94px;
  }
  #header .logo svg path {
    fill: #42b6e6;
  }
  #header .right-button.login {
    gap: 12px;
  }
  #header .right-button .chatIcon svg path:not(.noFill),
  #header .right-button .chatIcon svg rect {
    fill: #1b1c13;
  }
  #header .right-button .notifyIcon svg path,
  #header .right-button .notifyIcon svg rect {
    stroke: #1b1c13;
  }
  #header .right-button .lang,
  #header .right-button .loginBtn {
    color: #1b1c13;
    border-color: #1b1c13;
    height: 28px;
  }
  #header .right-button .loginBtn {
    padding: 0 12px;
    margin-right: 8px;
  }
  #header .right-button button svg {
    height: 28px;
  }
  #header .right-button button.lang {
    width: 28px;
  }
  #header .right-button .user-name {
    display: none;
  }
  #header .lnb {
    right: -16px;
    top: 56px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  #header .lnb:not(.lnb_lang) {
    width: 100vw;
  }
  #header .lnb_profile > a,
  #header .lnb_profile .ChatBoxInner {
    padding: 0 32px;
  }
  #header .lnb_profile > a .on,
  #header .lnb_profile > a .off,
  #header .lnb_profile .ChatBoxInner .on,
  #header .lnb_profile .ChatBoxInner .off {
    font-size: 14px;
  }
  #header .lnb_notify {
    width: 100%;
    max-height: 280px;
    overflow-y: scroll;
  }
  #header .lnb_notify p {
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 32px;
    font-size: 14px;
  }
  #header .lnb_lang {
    right: 0;
    border-radius: 8px;
  }
  #header .btn {
    padding: 0 !important;
  }
}