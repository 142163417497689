@charset "UTF-8";
.tour-card {
  width: 100%;
  min-height: 308px;
}
.tour-card .card-box {
  position: relative;
  margin-top: 8px;
}

.tour-card .imgArea-link {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}
.tour-card .imgArea {
  border-radius: 12px;
  margin-bottom: 8px;
}
.tour-card .heart-div {
  top: 12px;
  right: 12px;
}
.tour-card .heart-div input {
  display: none;
}
.tour-card .heart-div label {
  cursor: pointer;
}
.tour-card .heart-div label svg {
  width: 28px;
  height: 28px;
}
.tour-card .cardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: keep-all;
  line-height: 22px;
  max-height: 44px;
  font-size: 16px;
  color: #1b1c13;
  font-weight: bold;
  margin-bottom: 6px;
}
.tour-card .location-row {
  width: 218px;
}
.tour-card .location-row p {
  display: block;
  width: calc(100% - 22px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 20px;
}
.tour-card .location-with-comment {
  position: relative;
}
.tour-card .star-with-comment {
  align-items: center;
}
.tour-card .star-with-comment *:not(svg) {
  display: flex;
  align-items: center;
  height: 20px;
  line-height: 20px;
}
.tour-card .star-with-comment .card-comment {
  gap: 4px;
}
.tour-card .star-with-comment .res-star-list {
  gap: 2px;
}
.tour-card .star-with-comment .res-star-list svg {
  margin-bottom: 1.5px;
}
.tour-card .star-with-comment .star-reviews {
  font-size: 14px;
  color: #7e7e7e;
  line-height: 20px;
}
.tour-card .createdBy {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tour-card .tour-total-host {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  right: 0;
}
.tour-card .date-tour-card {
  font-size: 14px;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 20px;
}
@media (max-width: 767px) {
  .tour-card .tour-card {
    grid-template-columns: repeat(auto-fit, minmax(328px, 1fr));
  }
}

.roboto-text .tour-card .cardTitle {
  line-height: 20px;
  max-height: 40px;
}
.roboto-text .tour-card .location-row {
  width: 186px;
}
.roboto-text .tour-card .location-row p {
  position: relative;
  top: 1.2px;
}
@media (max-width: 767px) {
  .roboto-text .tour-card .location-row svg {
    position: relative;
    bottom: 1px;
  }
  .roboto-text .tour-card .location-row p {
    top: 0.6px;
  }
}
.roboto-text .tour-card .res-star-list p {
  position: relative;
  top: 1px;
}
@media (max-width: 767px) {
  .roboto-text .tour-card .res-star-list svg {
    position: relative;
    bottom: 1px;
  }
  .roboto-text .tour-card .res-star-list p {
    top: 0.6px;
  }
}
.roboto-text .tour-card .createdBy {
  width: 259px;
}