@media (max-width: 1920px) {
  #MyPage,
  #Inquire,
  #myWishlist,
  #AppliedHostingMain,
  #singleMyHosting,
  #edit-profile-page {
    padding: 75px 0 80px;
  }
  #MyPage .page-title,
  #Inquire .page-title,
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title,
  #edit-profile-page .page-title {
    font-size: 30px;
    font-weight: 700;
  }
  #MyPage .inquiry-gap,
  #Inquire .inquiry-gap,
  #myWishlist .inquiry-gap,
  #AppliedHostingMain .inquiry-gap,
  #singleMyHosting .inquiry-gap,
  #edit-profile-page .inquiry-gap {
    gap: 60px;
    margin-top: 60px;
  }
  #MyPage .inquiry-title,
  #Inquire .inquiry-title,
  #myWishlist .inquiry-title,
  #AppliedHostingMain .inquiry-title,
  #singleMyHosting .inquiry-title,
  #edit-profile-page .inquiry-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 28px;
  }
  #MyPage .inquiry-details,
  #Inquire .inquiry-details,
  #myWishlist .inquiry-details,
  #AppliedHostingMain .inquiry-details,
  #singleMyHosting .inquiry-details,
  #edit-profile-page .inquiry-details {
    font-size: 14px;
    line-height: 20px;
  }
  #MyPage .inquiry-content > div,
  #Inquire .inquiry-content > div,
  #myWishlist .inquiry-content > div,
  #AppliedHostingMain .inquiry-content > div,
  #singleMyHosting .inquiry-content > div,
  #edit-profile-page .inquiry-content > div {
    margin-bottom: 40px;
  }
  #MyPage .profile-card,
  #Inquire .profile-card,
  #myWishlist .profile-card,
  #AppliedHostingMain .profile-card,
  #singleMyHosting .profile-card,
  #edit-profile-page .profile-card {
    gap: 28px;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .profile-card .host-img,
  #Inquire .profile-card .host-img,
  #myWishlist .profile-card .host-img,
  #AppliedHostingMain .profile-card .host-img,
  #singleMyHosting .profile-card .host-img,
  #edit-profile-page .profile-card .host-img {
    width: 112px;
    height: 112px;
  }
  #MyPage .profile-card .user-name,
  #Inquire .profile-card .user-name,
  #myWishlist .profile-card .user-name,
  #AppliedHostingMain .profile-card .user-name,
  #singleMyHosting .profile-card .user-name,
  #edit-profile-page .profile-card .user-name {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #MyPage .profile-card .edit-profile-link,
  #Inquire .profile-card .edit-profile-link,
  #myWishlist .profile-card .edit-profile-link,
  #AppliedHostingMain .profile-card .edit-profile-link,
  #singleMyHosting .profile-card .edit-profile-link,
  #edit-profile-page .profile-card .edit-profile-link {
    font-size: 14px;
    font-weight: 700;
    color: #42b6e6;
  }
  #MyPage .myPage-title,
  #Inquire .myPage-title,
  #myWishlist .myPage-title,
  #AppliedHostingMain .myPage-title,
  #singleMyHosting .myPage-title,
  #edit-profile-page .myPage-title {
    font-size: 18px;
    font-weight: 700;
  }
  #MyPage .myPage-tabs,
  #Inquire .myPage-tabs,
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs,
  #edit-profile-page .myPage-tabs {
    margin: 16px 0 20px;
    font-size: 14px;
    font-weight: 400;
  }
  #MyPage .myPage-tabs .my-host-check,
  #Inquire .myPage-tabs .my-host-check,
  #myWishlist .myPage-tabs .my-host-check,
  #AppliedHostingMain .myPage-tabs .my-host-check,
  #singleMyHosting .myPage-tabs .my-host-check,
  #edit-profile-page .myPage-tabs .my-host-check {
    margin-top: 16px;
  }
  #MyPage .myPage-tabs span,
  #Inquire .myPage-tabs span,
  #myWishlist .myPage-tabs span,
  #AppliedHostingMain .myPage-tabs span,
  #singleMyHosting .myPage-tabs span,
  #edit-profile-page .myPage-tabs span {
    cursor: pointer;
    font-size: 14px;
  }
  #MyPage .myPage-tabs span.active,
  #Inquire .myPage-tabs span.active,
  #myWishlist .myPage-tabs span.active,
  #AppliedHostingMain .myPage-tabs span.active,
  #singleMyHosting .myPage-tabs span.active,
  #edit-profile-page .myPage-tabs span.active {
    color: #42b6e6;
  }
  #MyPage .myPage-tabs span:not(:last-child):after,
  #Inquire .myPage-tabs span:not(:last-child):after,
  #myWishlist .myPage-tabs span:not(:last-child):after,
  #AppliedHostingMain .myPage-tabs span:not(:last-child):after,
  #singleMyHosting .myPage-tabs span:not(:last-child):after,
  #edit-profile-page .myPage-tabs span:not(:last-child):after {
    content: "|";
    display: inline-block;
    padding: 0 10px;
    color: #e7e7e7;
    font-weight: 400;
    cursor: default;
  }
  #MyPage .moreLink,
  #Inquire .moreLink,
  #myWishlist .moreLink,
  #AppliedHostingMain .moreLink,
  #singleMyHosting .moreLink,
  #edit-profile-page .moreLink {
    font-size: 14px;
    color: #42b6e6;
    font-weight: 400;
    margin-right: 8px;
  }
  #MyPage .my-account-feeds,
  #Inquire .my-account-feeds,
  #myWishlist .my-account-feeds,
  #AppliedHostingMain .my-account-feeds,
  #singleMyHosting .my-account-feeds,
  #edit-profile-page .my-account-feeds {
    padding-top: 20px;
  }
  #MyPage .tour-card-list,
  #Inquire .tour-card-list,
  #myWishlist .tour-card-list,
  #AppliedHostingMain .tour-card-list,
  #singleMyHosting .tour-card-list,
  #edit-profile-page .tour-card-list {
    gap: 31px;
  }
  #MyPage .tour-card-list .tour-card,
  #Inquire .tour-card-list .tour-card,
  #myWishlist .tour-card-list .tour-card,
  #AppliedHostingMain .tour-card-list .tour-card,
  #singleMyHosting .tour-card-list .tour-card,
  #edit-profile-page .tour-card-list .tour-card {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    aspect-ratio: unset;
  }
  #MyPage .tour-card-list .single-card-tour,
  #Inquire .tour-card-list .single-card-tour,
  #myWishlist .tour-card-list .single-card-tour,
  #AppliedHostingMain .tour-card-list .single-card-tour,
  #singleMyHosting .tour-card-list .single-card-tour,
  #edit-profile-page .tour-card-list .single-card-tour {
    width: 100%;
    min-height: 234px;
    max-height: 234px;
    border-radius: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea,
  #Inquire .tour-card-list .single-card-tour .imgArea,
  #myWishlist .tour-card-list .single-card-tour .imgArea,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea {
    width: 260px !important;
  }
  #MyPage .tour-card-list .single-card-tour .card-box,
  #Inquire .tour-card-list .single-card-tour .card-box,
  #myWishlist .tour-card-list .single-card-tour .card-box,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box,
  #singleMyHosting .tour-card-list .single-card-tour .card-box,
  #edit-profile-page .tour-card-list .single-card-tour .card-box {
    width: calc(100% - 260px);
    padding: 16px 40px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a,
  #Inquire .tour-card-list .single-card-tour .card-box a,
  #myWishlist .tour-card-list .single-card-tour .card-box a,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a {
    width: calc(100% - 170px);
    display: block;
    margin-bottom: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a .cardTitle,
  #Inquire .tour-card-list .single-card-tour .card-box a .cardTitle,
  #myWishlist .tour-card-list .single-card-tour .card-box a .cardTitle,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a .cardTitle,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a .cardTitle,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a .cardTitle {
    display: block;
    height: 60px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
    word-break: keep-all;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .createdBy,
  #Inquire .tour-card-list .single-card-tour .card-box .createdBy,
  #myWishlist .tour-card-list .single-card-tour .card-box .createdBy,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .createdBy,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .createdBy,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .createdBy {
    font-size: 14px;
    font-weight: 700;
    max-width: 170px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting {
    gap: 12px;
    height: 22px;
    margin-bottom: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row {
    gap: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row p {
    font-size: 12px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment {
    gap: 12px;
    height: 22px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list {
    display: flex !important;
    align-items: center;
    height: 22px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .emptySymbol svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .fullSymbol svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .star-list svg {
    height: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #Inquire .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-with-comment .card-comment {
    color: #7e7e7e;
    font-size: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div,
  #Inquire .tour-card-list .single-card-tour .card-box .heart-div,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div {
    right: 40px;
    top: 60px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div svg,
  #Inquire .tour-card-list .single-card-tour .card-box .heart-div svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div svg {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting {
    width: 489px;
    height: 102px;
    padding-top: 34px;
    margin-top: -34px;
    margin-bottom: 16px;
    gap: 8px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .single-tag {
    height: 30px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn {
    padding: 0 14px;
    height: 30px;
    font-size: 14px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #Inquire .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext {
    top: -34px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host {
    display: flex;
    justify-content: space-between;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card {
    font-size: 12px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #Inquire .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host {
    font-size: 12px;
    font-weight: 700;
  }
  #MyPage .tour-card-list .status-layer,
  #Inquire .tour-card-list .status-layer,
  #myWishlist .tour-card-list .status-layer,
  #AppliedHostingMain .tour-card-list .status-layer,
  #singleMyHosting .tour-card-list .status-layer,
  #edit-profile-page .tour-card-list .status-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button,
  #Inquire .tour-card-list .status-layer .status-layer-button,
  #myWishlist .tour-card-list .status-layer .status-layer-button,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button {
    text-align: center;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button .status-title,
  #Inquire .tour-card-list .status-layer .status-layer-button .status-title,
  #myWishlist .tour-card-list .status-layer .status-layer-button .status-title,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button .status-title,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button .status-title,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button .status-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
  }
  #MyPage .tour-card-list .status-layer .status-layer-button button,
  #Inquire .tour-card-list .status-layer .status-layer-button button,
  #myWishlist .tour-card-list .status-layer .status-layer-button button,
  #AppliedHostingMain .tour-card-list .status-layer .status-layer-button button,
  #singleMyHosting .tour-card-list .status-layer .status-layer-button button,
  #edit-profile-page .tour-card-list .status-layer .status-layer-button button {
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    width: 160px;
    background-color: #fff;
  }
  #MyPage .noFeed,
  #Inquire .noFeed,
  #myWishlist .noFeed,
  #AppliedHostingMain .noFeed,
  #singleMyHosting .noFeed,
  #edit-profile-page .noFeed {
    display: flex;
    background-color: #f9f9f9;
    height: 234px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  #MyPage .noFeed h4,
  #Inquire .noFeed h4,
  #myWishlist .noFeed h4,
  #AppliedHostingMain .noFeed h4,
  #singleMyHosting .noFeed h4,
  #edit-profile-page .noFeed h4 {
    font-size: 16px;
    color: #7e7e7e;
    font-weight: 400;
  }
  #MyPage #wishlist,
  #MyPage #request,
  #Inquire #wishlist,
  #Inquire #request,
  #myWishlist #wishlist,
  #myWishlist #request,
  #AppliedHostingMain #wishlist,
  #AppliedHostingMain #request,
  #singleMyHosting #wishlist,
  #singleMyHosting #request,
  #edit-profile-page #wishlist,
  #edit-profile-page #request {
    margin-top: 60px;
  }
  #MyPage #wishlist .modal-card-wrapper,
  #MyPage #request .modal-card-wrapper,
  #Inquire #wishlist .modal-card-wrapper,
  #Inquire #request .modal-card-wrapper,
  #myWishlist #wishlist .modal-card-wrapper,
  #myWishlist #request .modal-card-wrapper,
  #AppliedHostingMain #wishlist .modal-card-wrapper,
  #AppliedHostingMain #request .modal-card-wrapper,
  #singleMyHosting #wishlist .modal-card-wrapper,
  #singleMyHosting #request .modal-card-wrapper,
  #edit-profile-page #wishlist .modal-card-wrapper,
  #edit-profile-page #request .modal-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-auto-columns: auto;
    grid-gap: 20px 10px;
    flex-wrap: wrap;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card,
  #MyPage #request .modal-card-wrapper .modal-card,
  #Inquire #wishlist .modal-card-wrapper .modal-card,
  #Inquire #request .modal-card-wrapper .modal-card,
  #myWishlist #wishlist .modal-card-wrapper .modal-card,
  #myWishlist #request .modal-card-wrapper .modal-card,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card,
  #singleMyHosting #request .modal-card-wrapper .modal-card,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card,
  #edit-profile-page #request .modal-card-wrapper .modal-card {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    min-width: 260px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #MyPage #request .modal-card-wrapper .modal-card .modal-card-content,
  #Inquire #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #Inquire #request .modal-card-wrapper .modal-card .modal-card-content,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #myWishlist #request .modal-card-wrapper .modal-card .modal-card-content,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .modal-card-content,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #singleMyHosting #request .modal-card-wrapper .modal-card .modal-card-content,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .modal-card-content,
  #edit-profile-page #request .modal-card-wrapper .modal-card .modal-card-content {
    width: 100%;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .imgArea,
  #MyPage #request .modal-card-wrapper .modal-card .imgArea,
  #Inquire #wishlist .modal-card-wrapper .modal-card .imgArea,
  #Inquire #request .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #request .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #request .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #request .modal-card-wrapper .modal-card .imgArea {
    min-width: 260px;
    width: 100%;
    min-height: 146px;
    aspect-ratio: 4/3;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details,
  #MyPage #request .modal-card-wrapper .modal-card .card-details,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details,
  #Inquire #request .modal-card-wrapper .modal-card .card-details,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details {
    padding: 16px 20px 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-name,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-info,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-info {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-category,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-category {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #MyPage #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #Inquire #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #Inquire #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #request .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #request .modal-card-wrapper .modal-card .card-details .place-timeInfo {
    font-size: 14px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes label,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes label {
    cursor: pointer;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes label img,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes label img {
    width: 24px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #MyPage #request .modal-card-wrapper .modal-card .checkboxes input,
  #Inquire #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #Inquire #request .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #request .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #request .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #request .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #request .modal-card-wrapper .modal-card .checkboxes input {
    display: none;
  }
  #MyPage .wishlist-host,
  #Inquire .wishlist-host,
  #myWishlist .wishlist-host,
  #AppliedHostingMain .wishlist-host,
  #singleMyHosting .wishlist-host,
  #edit-profile-page .wishlist-host {
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    gap: 16px;
    width: 100%;
  }
  #MyPage .wishlist-host:last-child,
  #Inquire .wishlist-host:last-child,
  #myWishlist .wishlist-host:last-child,
  #AppliedHostingMain .wishlist-host:last-child,
  #singleMyHosting .wishlist-host:last-child,
  #edit-profile-page .wishlist-host:last-child {
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .wishlist-host .host-img,
  #Inquire .wishlist-host .host-img,
  #myWishlist .wishlist-host .host-img,
  #AppliedHostingMain .wishlist-host .host-img,
  #singleMyHosting .wishlist-host .host-img,
  #edit-profile-page .wishlist-host .host-img {
    width: 54px;
    height: 54px;
  }
  #MyPage .wishlist-host .host-img ~ div,
  #Inquire .wishlist-host .host-img ~ div,
  #myWishlist .wishlist-host .host-img ~ div,
  #AppliedHostingMain .wishlist-host .host-img ~ div,
  #singleMyHosting .wishlist-host .host-img ~ div,
  #edit-profile-page .wishlist-host .host-img ~ div {
    width: calc(100% - 70px);
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag {
    gap: 8px;
    margin-bottom: 8px;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #Inquire .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag,
  #Inquire .wishlist-host .host-img ~ div .host-tag,
  #myWishlist .wishlist-host .host-img ~ div .host-tag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag {
    gap: 4px;
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag > div,
  #Inquire .wishlist-host .host-img ~ div .host-tag > div,
  #myWishlist .wishlist-host .host-img ~ div .host-tag > div,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag > div,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag > div,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag > div {
    padding: 0 12px;
    border-radius: 133.333px;
    height: 28px;
    display: flex;
    align-items: center;
    border: 1px solid #1b1c13;
    font-size: 10px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label,
  #Inquire .wishlist-host .host-img ~ div .heart-div label,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div input,
  #Inquire .wishlist-host .host-img ~ div .heart-div input,
  #myWishlist .wishlist-host .host-img ~ div .heart-div input,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div input,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div input,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div input {
    display: none;
  }
  #MyPage .host-my-own-btn,
  #Inquire .host-my-own-btn,
  #myWishlist .host-my-own-btn,
  #AppliedHostingMain .host-my-own-btn,
  #singleMyHosting .host-my-own-btn,
  #edit-profile-page .host-my-own-btn {
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
  }
  #MyPage .myOnly,
  #Inquire .myOnly,
  #myWishlist .myOnly,
  #AppliedHostingMain .myOnly,
  #singleMyHosting .myOnly,
  #edit-profile-page .myOnly {
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage .myOnly.checked,
  #Inquire .myOnly.checked,
  #myWishlist .myOnly.checked,
  #AppliedHostingMain .myOnly.checked,
  #singleMyHosting .myOnly.checked,
  #edit-profile-page .myOnly.checked {
    color: #42b6e6;
  }
  #MyPage .myOnly input,
  #Inquire .myOnly input,
  #myWishlist .myOnly input,
  #AppliedHostingMain .myOnly input,
  #singleMyHosting .myOnly input,
  #edit-profile-page .myOnly input {
    display: none;
  }
  .tour-card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
    grid-auto-columns: auto;
    grid-gap: 20px 10px;
    flex-wrap: wrap;
  }
  #myWishlist #wishlist {
    margin-top: 0;
  }
  #edit-profile-page {
    position: relative;
  }
  #edit-profile-page .essential {
    margin-left: 4px;
  }
  #edit-profile-page .disabledBtn {
    background-color: #aeaeae;
    color: #dcdcdc;
  }
  #edit-profile-page .basic-info {
    margin: 8px 0 20px;
  }
  #edit-profile-page .basic-info,
  #edit-profile-page label h3 {
    font-size: 14px;
    font-weight: 700;
  }
  #edit-profile-page label {
    display: flex;
  }
  #edit-profile-page .user-profile-area {
    padding: 0;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    margin-bottom: 24px;
  }
  #edit-profile-page .user-profile-area input {
    display: none;
  }
  #edit-profile-page .user-profile-area label {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  #edit-profile-page .basic-info-area {
    display: flex;
    justify-content: space-between;
    grid-gap: 80px;
    gap: 80px;
  }
  #edit-profile-page .basic-info-area.mobile-info {
    display: none;
  }
  #edit-profile-page .basic-info-area * {
    box-sizing: border-box;
  }
  #edit-profile-page .basic-info-area .left,
  #edit-profile-page .basic-info-area .right {
    width: 510px;
  }
  #edit-profile-page .basic-info-area .left .input-field:nth-child(2) {
    padding-bottom: 106px;
  }
  #edit-profile-page .basic-info-area .input-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #edit-profile-page .basic-info-area .input-field:not(:last-child) {
    padding-bottom: 28px;
  }
  #edit-profile-page .basic-info-area .input-field label {
    display: flex;
    width: 120px;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field input {
    width: calc(100% - 120px);
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #dcdcdc;
  }
  #edit-profile-page .basic-info-area .input-field input:disabled {
    background-color: #e7e7e7;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex {
    width: calc(100% - 110px);
    gap: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type {
    padding: 0 16px;
    width: 196px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:active, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:focus, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:hover {
    height: 50px;
    border: 1px solid #dcdcdc;
    outline: none;
    box-shadow: none;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type > div {
    padding: 0;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type.css-1s2u09g-control * {
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type .css-qc6sy-singleValue {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #edit-profile-page .basic-info-area .input-field button {
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    width: 110px;
    padding: 0;
    border-radius: 8px;
  }
  #edit-profile-page form {
    margin-top: 20px;
    border-top: 1px solid #e7e7e7;
    padding-top: 24px;
  }
  #edit-profile-page form .delete-profiletwo {
    padding: 24px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #edit-profile-page form .delete-profiletwo a {
    color: #1b1c13;
    text-decoration: underline;
    font-size: 14px;
  }
  #edit-profile-page form input,
  #edit-profile-page form select {
    font-size: 14px;
  }
  #edit-profile-page form .text-danger {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    left: 126px;
  }
  #edit-profile-page form .save-btn {
    margin-top: 40px;
  }
  #edit-profile-page form .save-btn button {
    height: 50px;
    width: 296px;
    font-weight: 700;
  }
  .roboto-text #MyPage .tour-card .location-row,
  .roboto-text #Inquire .tour-card .location-row,
  .roboto-text #myWishlist .tour-card .location-row,
  .roboto-text #AppliedHostingMain .tour-card .location-row,
  .roboto-text #singleMyHosting .tour-card .location-row,
  .roboto-text #edit-profile-page .tour-card .location-row {
    width: 186px;
  }
}
@media (max-width: 767px) {
  #MyPage,
  #myWishlist,
  #AppliedHostingMain,
  #singleMyHosting,
  #edit-profile-page {
    padding: 8px 0 40px;
  }
  #MyPage #my-feed,
  #myWishlist #my-feed,
  #AppliedHostingMain #my-feed,
  #singleMyHosting #my-feed,
  #edit-profile-page #my-feed {
    position: relative;
  }
  #MyPage .page-title,
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title,
  #edit-profile-page .page-title {
    font-size: 18px;
    border-bottom: 1px solid #e7e7e7;
    height: 48px;
    display: flex;
    align-items: center;
    width: 100vw;
    margin-left: -16px;
    padding: 0 16px;
  }
  #MyPage .profile-card,
  #myWishlist .profile-card,
  #AppliedHostingMain .profile-card,
  #singleMyHosting .profile-card,
  #edit-profile-page .profile-card {
    gap: 28px;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .profile-card .host-img,
  #myWishlist .profile-card .host-img,
  #AppliedHostingMain .profile-card .host-img,
  #singleMyHosting .profile-card .host-img,
  #edit-profile-page .profile-card .host-img {
    width: 60px;
    height: 60px;
  }
  #MyPage .profile-card .user-name,
  #myWishlist .profile-card .user-name,
  #AppliedHostingMain .profile-card .user-name,
  #singleMyHosting .profile-card .user-name,
  #edit-profile-page .profile-card .user-name {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 8px;
  }
  #MyPage .profile-card .edit-profile-link,
  #myWishlist .profile-card .edit-profile-link,
  #AppliedHostingMain .profile-card .edit-profile-link,
  #singleMyHosting .profile-card .edit-profile-link,
  #edit-profile-page .profile-card .edit-profile-link {
    font-size: 14px;
    font-weight: 700;
    color: #42b6e6;
  }
  #MyPage .myPage-title,
  #myWishlist .myPage-title,
  #AppliedHostingMain .myPage-title,
  #singleMyHosting .myPage-title,
  #edit-profile-page .myPage-title {
    font-size: 16px;
  }
  #MyPage .myPage-tabs,
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs,
  #edit-profile-page .myPage-tabs {
    margin: 12px 0 20px;
    font-size: 14px;
  }
  #MyPage .myPage-tabs span,
  #myWishlist .myPage-tabs span,
  #AppliedHostingMain .myPage-tabs span,
  #singleMyHosting .myPage-tabs span,
  #edit-profile-page .myPage-tabs span {
    font-size: 12px;
  }
  #MyPage .moreLink,
  #myWishlist .moreLink,
  #AppliedHostingMain .moreLink,
  #singleMyHosting .moreLink,
  #edit-profile-page .moreLink {
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 12px;
  }
  #MyPage .my-account-feeds,
  #myWishlist .my-account-feeds,
  #AppliedHostingMain .my-account-feeds,
  #singleMyHosting .my-account-feeds,
  #edit-profile-page .my-account-feeds {
    padding-top: 20px;
  }
  #MyPage .my-account-feeds > section,
  #myWishlist .my-account-feeds > section,
  #AppliedHostingMain .my-account-feeds > section,
  #singleMyHosting .my-account-feeds > section,
  #edit-profile-page .my-account-feeds > section {
    position: relative;
  }
  #MyPage .my-account-feeds > section:not(#wishlist) .moreLink,
  #myWishlist .my-account-feeds > section:not(#wishlist) .moreLink,
  #AppliedHostingMain .my-account-feeds > section:not(#wishlist) .moreLink,
  #singleMyHosting .my-account-feeds > section:not(#wishlist) .moreLink,
  #edit-profile-page .my-account-feeds > section:not(#wishlist) .moreLink {
    top: 0;
  }
  #MyPage .tour-card-list,
  #myWishlist .tour-card-list,
  #AppliedHostingMain .tour-card-list,
  #singleMyHosting .tour-card-list,
  #edit-profile-page .tour-card-list {
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  }
  #MyPage .tour-card-list .tour-card,
  #myWishlist .tour-card-list .tour-card,
  #AppliedHostingMain .tour-card-list .tour-card,
  #singleMyHosting .tour-card-list .tour-card,
  #edit-profile-page .tour-card-list .tour-card {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    aspect-ratio: unset;
  }
  #MyPage .tour-card-list .single-card-tour,
  #myWishlist .tour-card-list .single-card-tour,
  #AppliedHostingMain .tour-card-list .single-card-tour,
  #singleMyHosting .tour-card-list .single-card-tour,
  #edit-profile-page .tour-card-list .single-card-tour {
    width: 100%;
    min-height: 193px;
    max-height: 193px;
    border-radius: 20px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea-link,
  #myWishlist .tour-card-list .single-card-tour .imgArea-link,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea-link,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea-link,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea-link {
    width: 30%;
    min-width: 100px;
  }
  #MyPage .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #myWishlist .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #AppliedHostingMain .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #singleMyHosting .tour-card-list .single-card-tour .imgArea-link .imgArea,
  #edit-profile-page .tour-card-list .single-card-tour .imgArea-link .imgArea {
    width: 100% !important;
  }
  #MyPage .tour-card-list .single-card-tour .card-box,
  #myWishlist .tour-card-list .single-card-tour .card-box,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box,
  #singleMyHosting .tour-card-list .single-card-tour .card-box,
  #edit-profile-page .tour-card-list .single-card-tour .card-box {
    min-width: calc(100% - 100px);
    width: 70%;
    padding: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .tour-card-data,
  #myWishlist .tour-card-list .single-card-tour .card-box .tour-card-data,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .tour-card-data,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .tour-card-data,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .tour-card-data {
    flex-direction: column;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a,
  #myWishlist .tour-card-list .single-card-tour .card-box a,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a {
    width: 100%;
  }
  #MyPage .tour-card-list .single-card-tour .card-box a .cardTitle,
  #myWishlist .tour-card-list .single-card-tour .card-box a .cardTitle,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box a .cardTitle,
  #singleMyHosting .tour-card-list .single-card-tour .card-box a .cardTitle,
  #edit-profile-page .tour-card-list .single-card-tour .card-box a .cardTitle {
    height: 44px;
    line-height: 22px;
    font-size: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .createdBy,
  #myWishlist .tour-card-list .single-card-tour .card-box .createdBy,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .createdBy,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .createdBy,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .createdBy {
    font-size: 16px;
    margin-bottom: 8px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: unset;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting {
    flex-direction: column;
    height: unset;
    gap: 4px;
    margin-bottom: 4px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row {
    gap: 2px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .location-row svg {
    width: 16px;
    height: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row {
    gap: 2px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .star-list {
    display: none;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list {
    display: flex;
    height: 18px;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span {
    height: 18px;
    display: flex;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span *,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span * {
    display: flex;
    align-items: center;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-row .res-star-list span svg {
    width: 16px;
    height: 16px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #myWishlist .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .card-location-ratting .star-reviews {
    font-size: 10px;
    color: #7e7e7e;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div {
    right: 12px;
    top: 60px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .heart-div svg,
  #myWishlist .tour-card-list .single-card-tour .card-box .heart-div svg,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .heart-div svg,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .heart-div svg,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .heart-div svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting {
    width: 100%;
    height: 24px;
    padding: 0;
    margin-top: 0;
    gap: 4px;
    margin-bottom: 8px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .single-tag,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .single-tag {
    height: 24px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn {
    padding: 0 12px;
    height: 24px;
    font-size: 12px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #myWishlist .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .route-setting .tour-tag-btn:hover ~ .tooltiptext {
    top: -34px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host {
    display: flex;
    justify-content: space-between;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .date-tour-card {
    font-size: 10px;
  }
  #MyPage .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #myWishlist .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #AppliedHostingMain .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #singleMyHosting .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host,
  #edit-profile-page .tour-card-list .single-card-tour .card-box .date-with-host .tour-total-host {
    font-size: 10px;
  }
  #MyPage .tour-card-list .status-layer,
  #myWishlist .tour-card-list .status-layer,
  #AppliedHostingMain .tour-card-list .status-layer,
  #singleMyHosting .tour-card-list .status-layer,
  #edit-profile-page .tour-card-list .status-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
  }
  #MyPage .tour-card-list .status-layer .status-title,
  #myWishlist .tour-card-list .status-layer .status-title,
  #AppliedHostingMain .tour-card-list .status-layer .status-title,
  #singleMyHosting .tour-card-list .status-layer .status-title,
  #edit-profile-page .tour-card-list .status-layer .status-title {
    font-size: 16px;
    font-weight: 700;
  }
  #MyPage .tour-card-list .status-layer button,
  #myWishlist .tour-card-list .status-layer button,
  #AppliedHostingMain .tour-card-list .status-layer button,
  #singleMyHosting .tour-card-list .status-layer button,
  #edit-profile-page .tour-card-list .status-layer button {
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    width: 160px;
  }
  #MyPage .noFeed,
  #myWishlist .noFeed,
  #AppliedHostingMain .noFeed,
  #singleMyHosting .noFeed,
  #edit-profile-page .noFeed {
    display: flex;
    background-color: #f9f9f9;
    height: 234px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  #MyPage .noFeed h4,
  #myWishlist .noFeed h4,
  #AppliedHostingMain .noFeed h4,
  #singleMyHosting .noFeed h4,
  #edit-profile-page .noFeed h4 {
    font-size: 14px;
    color: #7e7e7e;
  }
  #MyPage #wishlist,
  #myWishlist #wishlist,
  #AppliedHostingMain #wishlist,
  #singleMyHosting #wishlist,
  #edit-profile-page #wishlist {
    margin-top: 60px;
    position: relative;
  }
  #MyPage #wishlist .modal-card-wrapper,
  #myWishlist #wishlist .modal-card-wrapper,
  #AppliedHostingMain #wishlist .modal-card-wrapper,
  #singleMyHosting #wishlist .modal-card-wrapper,
  #edit-profile-page #wishlist .modal-card-wrapper {
    gap: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card,
  #myWishlist #wishlist .modal-card-wrapper .modal-card,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    width: 100%;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .imgArea,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .imgArea,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .imgArea,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .imgArea,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .imgArea {
    width: 100%;
    height: unset;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details {
    padding: 16px 20px 20px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-name,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-info,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-info {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 8px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-category,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-category {
    font-size: 14px;
    margin-bottom: 4px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .card-details .place-timeInfo {
    font-size: 14px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label {
    cursor: pointer;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes label img,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes label img {
    width: 24px;
  }
  #MyPage #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #myWishlist #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #AppliedHostingMain #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #singleMyHosting #wishlist .modal-card-wrapper .modal-card .checkboxes input,
  #edit-profile-page #wishlist .modal-card-wrapper .modal-card .checkboxes input {
    display: none;
  }
  #MyPage .wishlist-host,
  #myWishlist .wishlist-host,
  #AppliedHostingMain .wishlist-host,
  #singleMyHosting .wishlist-host,
  #edit-profile-page .wishlist-host {
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    gap: 16px;
    width: 100%;
  }
  #MyPage .wishlist-host:last-child,
  #myWishlist .wishlist-host:last-child,
  #AppliedHostingMain .wishlist-host:last-child,
  #singleMyHosting .wishlist-host:last-child,
  #edit-profile-page .wishlist-host:last-child {
    border-bottom: 1px solid #e7e7e7;
  }
  #MyPage .wishlist-host .host-img,
  #myWishlist .wishlist-host .host-img,
  #AppliedHostingMain .wishlist-host .host-img,
  #singleMyHosting .wishlist-host .host-img,
  #edit-profile-page .wishlist-host .host-img {
    width: 48px;
    height: 48px;
  }
  #MyPage .wishlist-host .host-img ~ div,
  #myWishlist .wishlist-host .host-img ~ div,
  #AppliedHostingMain .wishlist-host .host-img ~ div,
  #singleMyHosting .wishlist-host .host-img ~ div,
  #edit-profile-page .wishlist-host .host-img ~ div {
    width: calc(100% - 70px);
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag {
    gap: 8px;
    margin-bottom: 8px;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .host-name,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .host-name {
    font-size: 14px;
    font-weight: 700;
  }
  #MyPage .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #myWishlist .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #AppliedHostingMain .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #singleMyHosting .wishlist-host .host-img ~ div .name-with-flag .flag-wrap,
  #edit-profile-page .wishlist-host .host-img ~ div .name-with-flag .flag-wrap {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
  }
  #MyPage .wishlist-host .host-img ~ div .host-tag > div,
  #myWishlist .wishlist-host .host-img ~ div .host-tag > div,
  #AppliedHostingMain .wishlist-host .host-img ~ div .host-tag > div,
  #singleMyHosting .wishlist-host .host-img ~ div .host-tag > div,
  #edit-profile-page .wishlist-host .host-img ~ div .host-tag > div {
    height: 24px;
    font-size: 12px;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div label svg,
  #myWishlist .wishlist-host .host-img ~ div .heart-div label svg,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div label svg,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div label svg,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div label svg {
    width: 24px;
    height: 24px;
  }
  #MyPage .wishlist-host .host-img ~ div .heart-div input,
  #myWishlist .wishlist-host .host-img ~ div .heart-div input,
  #AppliedHostingMain .wishlist-host .host-img ~ div .heart-div input,
  #singleMyHosting .wishlist-host .host-img ~ div .heart-div input,
  #edit-profile-page .wishlist-host .host-img ~ div .heart-div input {
    display: none;
  }
  #MyPage .host-my-own-btn,
  #myWishlist .host-my-own-btn,
  #AppliedHostingMain .host-my-own-btn,
  #singleMyHosting .host-my-own-btn,
  #edit-profile-page .host-my-own-btn {
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 20px;
  }
  #MyPage .myOnly,
  #myWishlist .myOnly,
  #AppliedHostingMain .myOnly,
  #singleMyHosting .myOnly,
  #edit-profile-page .myOnly {
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #7e7e7e;
    margin-bottom: 20px;
  }
  #MyPage .myOnly.checked,
  #myWishlist .myOnly.checked,
  #AppliedHostingMain .myOnly.checked,
  #singleMyHosting .myOnly.checked,
  #edit-profile-page .myOnly.checked {
    color: #42b6e6;
  }
  #MyPage .myOnly input,
  #myWishlist .myOnly input,
  #AppliedHostingMain .myOnly input,
  #singleMyHosting .myOnly input,
  #edit-profile-page .myOnly input {
    display: none;
  }
  #myWishlist .page-title,
  #AppliedHostingMain .page-title,
  #singleMyHosting .page-title {
    border-bottom: none;
  }
  #myWishlist .myPage-tabs,
  #AppliedHostingMain .myPage-tabs,
  #singleMyHosting .myPage-tabs {
    margin: 4px 0 20px;
  }
  #myWishlist .myPage-tabs .my-host-check,
  #AppliedHostingMain .myPage-tabs .my-host-check,
  #singleMyHosting .myPage-tabs .my-host-check {
    margin-top: 12px;
  }
  #myWishlist #wishlist {
    margin-top: 0;
  }
  #myWishlist .page-title {
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 0;
  }
  #myWishlist .host-my-own-btn {
    margin-left: 12px;
    height: 32px;
    font-size: 12px;
  }
  #myWishlist .host-my-own-btn svg {
    width: 16px;
  }
  #edit-profile-page {
    padding: 8px 0 40px;
  }
  #edit-profile-page .basic-info {
    margin: 8px 0 20px;
  }
  #edit-profile-page .page-title {
    border-bottom: none;
  }
  #edit-profile-page .basic-info,
  #edit-profile-page label h3 {
    font-size: 14px;
    font-weight: 700;
  }
  #edit-profile-page label {
    display: flex;
  }
  #edit-profile-page .user-info {
    width: 100vw;
    margin-left: -16px;
    margin-top: 0;
    padding: 20px 16px 0;
  }
  #edit-profile-page .user-profile-area {
    padding: 0;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    margin-bottom: 24px;
  }
  #edit-profile-page .user-profile-area input {
    display: none;
  }
  #edit-profile-page .user-profile-area label {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  #edit-profile-page .basic-info-area {
    display: block;
    justify-content: space-between;
    grid-gap: 80px;
    gap: 80px;
  }
  #edit-profile-page .basic-info-area.mobile-info {
    display: block;
  }
  #edit-profile-page .basic-info-area.pc-info {
    display: none;
  }
  #edit-profile-page .basic-info-area * {
    box-sizing: border-box;
  }
  #edit-profile-page .basic-info-area .left,
  #edit-profile-page .basic-info-area .right {
    width: 100%;
  }
  #edit-profile-page .basic-info-area .left .input-field:nth-child(2) {
    padding-bottom: 20px;
  }
  #edit-profile-page .basic-info-area .input-field {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
  }
  #edit-profile-page .basic-info-area .input-field label {
    width: 100%;
    margin-bottom: 8px;
    gap: 4px;
  }
  #edit-profile-page .basic-info-area .input-field input {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #dcdcdc;
  }
  #edit-profile-page .basic-info-area .input-field input:disabled {
    background-color: #e7e7e7;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex {
    width: 100%;
    gap: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .d-flex input {
    width: calc(100% - 86px);
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type {
    padding: 0 16px;
    width: 196px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:active, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:focus, #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type:hover {
    height: 50px;
    border: 1px solid #dcdcdc;
    outline: none;
    box-shadow: none;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type > div {
    padding: 0;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type.css-1s2u09g-control * {
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  #edit-profile-page .basic-info-area .input-field .contry-code-profile > div:first-of-type .css-qc6sy-singleValue {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #edit-profile-page .basic-info-area .input-field button {
    height: 44px;
    font-size: 12px;
    font-weight: 700;
    width: 86px;
    padding: 0;
    border-radius: 8px;
  }
  #edit-profile-page form {
    border-top: 1px solid #e7e7e7;
    padding-top: 24px;
  }
  #edit-profile-page form .delete-profiletwo {
    padding: 0 0 28px;
    border-bottom: 1px solid #e7e7e7;
  }
  #edit-profile-page form .delete-profiletwo a {
    padding: 0;
    color: #1b1c13;
    text-decoration: underline;
    font-size: 14px;
  }
  #edit-profile-page form input,
  #edit-profile-page form select {
    font-size: 14px;
  }
  #edit-profile-page form .text-danger {
    position: absolute;
    font-size: 12px;
    bottom: 6px;
    left: 13px;
  }
  #edit-profile-page form .save-btn {
    margin-top: 28px;
  }
  #edit-profile-page form .save-btn button {
    height: 50px;
    width: 296px;
    font-weight: 700;
  }
  .roboto-text #MyPage .host-tag p,
  .roboto-text #myWishlist .host-tag p,
  .roboto-text #AppliedHostingMain .host-tag p,
  .roboto-text #singleMyHosting .host-tag p,
  .roboto-text #edit-profile-page .host-tag p {
    font-size: 10px;
  }
}