@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 133.333px;
}

::-webkit-scrollbar-button {
  display: none;
}

.welcom-black {
  color: #1b1c13;
  font-size: 16px;
  font-weight: 700;
}

.url {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  color: #7e7e7e;
}
.url:hover {
  color: #7e7e7e;
}

pre {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.sticky-top {
  z-index: 3 !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
b,
label {
  margin: 0;
}

button,
input,
select,
a {
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button:focus,
input:focus,
select:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

input[type=text] {
  cursor: text;
}

input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
  color: #aeaeae;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

.input-style__placeholder,
.dateArea-select__placeholder {
  color: #aeaeae !important;
}

.wrap {
  margin: 0 auto;
}

.pagination svg path {
  stroke: #42b6e6;
}
.pagination .disabled svg path {
  stroke: #dcdcdc;
}

.show-data-save {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: end;
}
.show-data-save p {
  background-color: #000;
  color: #fff;
  padding: 0 20px;
  width: 240px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1920px) {
  .ck-editor__editable_inline {
    min-height: 400px;
    margin-bottom: 40px;
  }
  .wrap {
    width: 1100px;
  }
  .mobileClose {
    display: none;
  }
  main.single-page {
    min-height: calc(100vh - 238px);
    padding-top: 75px;
  }
}
@media (max-width: 1199px) {
  .wrap {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .wrap {
    width: calc(100% - 32px);
  }
  .mobileClose {
    display: block;
  }
}
.host-category {
  background-color: #8362e2;
  color: #fff;
  border: none !important;
}

.travel-host-category {
  background-color: #ff5f5f;
  color: #fff;
  border: none !important;
}

.layout {
  display: flex;
}

.noto-text * {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.3px !important;
}

.roboto-text * {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2px;
}

.circle {
  aspect-ratio: 1/1;
  border-radius: 100%;
  overflow: hidden;
}
.circle img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  vertical-align: unset;
}

.imgArea {
  overflow: hidden;
}
.imgArea img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  vertical-align: unset;
}

.font-blue {
  color: #42b6e6 !important;
}

.font-green {
  color: #41c82c !important;
}

.font-red {
  color: #ff5f5f !important;
}

.btn-outline-blue,
.btn-cancelBtn {
  border-radius: 133.333px;
  border: 1px solid #42b6e6;
  color: #42b6e6;
}
.btn-outline-blue:hover,
.btn-cancelBtn:hover {
  color: #42b6e6;
}

.btn-cancelBtn:disabled {
  background-color: transparent !important;
  border-color: #dcdcdc;
  color: #dcdcdc;
}

.btn-outline-red {
  border-radius: 133.333px;
  border: 1px solid #ff5f5f;
  color: #ff5f5f;
}
.btn-outline-red:hover {
  color: #ff5f5f;
}

.btn-outline-black {
  border-radius: 133.333px;
  border: 1px solid #1b1c13;
  color: #1b1c13;
}
.btn-outline-black:hover {
  color: #1b1c13;
}

.btn-blackBtn {
  background-color: #1b1c13;
  color: #fff;
}
.btn-blackBtn:hover {
  color: #fff;
}

.btn-declienedBtn {
  border-radius: 133.333px;
  background-color: #ff5f5f;
  color: #fff;
  cursor: default;
}
.btn-declienedBtn:hover {
  color: #fff;
}

.btn-confirmBtn {
  border-radius: 133.333px;
  background-color: #42b6e6;
  color: #fff;
}
.btn-confirmBtn:hover {
  color: #fff;
}

.btn-no-line {
  color: #42b6e6;
  border: none;
}
.btn-no-line:hover {
  color: #42b6e6;
}

.btn-successBtn {
  border-radius: 133.333px;
  background-color: #ecf8fd;
  color: #42b6e6;
  pointer-events: none;
}

.btn-normalBtn {
  border: 1px solid #1b1c13;
}

.btn:disabled {
  cursor: default;
}
.btn:disabled:not(.btn-successBtn) {
  background-color: #dcdcdc;
}

.btn-mainTab {
  padding: 0 16px;
  border-radius: 20px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
}

.searchContainer,
.createContainer {
  position: absolute;
  min-width: 606px;
  width: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 28px;
  top: 72px;
  z-index: 1;
}
.searchContainer .searchFlex,
.createContainer .searchFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
}
.searchContainer .searc-bar-title,
.createContainer .searc-bar-title {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 12px;
}
.searchContainer .country_list,
.createContainer .country_list {
  margin-top: 23px;
  gap: 10px;
  flex-wrap: wrap;
}
.searchContainer .country_list input[type=radio],
.createContainer .country_list input[type=radio] {
  display: none;
}
.searchContainer .country_list input[type=radio]:checked ~ label,
.createContainer .country_list input[type=radio]:checked ~ label {
  border-color: #42b6e6;
  color: #42b6e6;
}
.searchContainer .country_list label,
.createContainer .country_list label {
  padding: 0 16px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1c13;
  cursor: pointer;
}
.searchContainer .country_list label:hover,
.createContainer .country_list label:hover {
  font-weight: 700;
  border-color: #42b6e6;
  color: #42b6e6;
}
.searchContainer .backBtn,
.createContainer .backBtn {
  color: #1b1c13;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 12px;
}
.searchContainer.mobile-calendar,
.createContainer.mobile-calendar {
  display: none !important;
}
.searchContainer.mobile-calendar .react-datepicker__month-container,
.createContainer.mobile-calendar .react-datepicker__month-container {
  width: 100%;
}
.searchContainer .custom-container,
.createContainer .custom-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  border: none;
}
.searchContainer .react-datepicker__month-container,
.createContainer .react-datepicker__month-container {
  width: calc(50% - 16px);
}
.searchContainer .react-datepicker__month-container .react-datepicker__header--custom,
.createContainer .react-datepicker__month-container .react-datepicker__header--custom {
  border: none;
  background-color: transparent;
  padding: 0;
}
.searchContainer .datepickerHeader,
.createContainer .datepickerHeader {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 10px;
}
.searchContainer .datepickerHeader .btn,
.createContainer .datepickerHeader .btn {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day {
  background-color: #fff;
}
.searchContainer .react-datepicker__day--keyboard-selected,
.createContainer .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #1b1c13 !important;
}
.searchContainer .react-datepicker__day--keyboard-selected.react-datepicker__day--weekend,
.createContainer .react-datepicker__day--keyboard-selected.react-datepicker__day--weekend {
  color: #ff5f5f;
}
.searchContainer .react-datepicker__day--keyboard-selected .react-datepicker__day,
.createContainer .react-datepicker__day--keyboard-selected .react-datepicker__day {
  color: #1b1c13;
}
.searchContainer .react-datepicker__day-names,
.searchContainer .react-datepicker__month,
.searchContainer .react-datepicker__week,
.createContainer .react-datepicker__day-names,
.createContainer .react-datepicker__month,
.createContainer .react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .searchContainer .react-datepicker__day-names,
  .searchContainer .react-datepicker__month,
  .searchContainer .react-datepicker__week,
  .createContainer .react-datepicker__day-names,
  .createContainer .react-datepicker__month,
  .createContainer .react-datepicker__week {
    width: 100%;
  }
}
.searchContainer .react-datepicker__month,
.createContainer .react-datepicker__month {
  flex-direction: column;
  margin: 0;
}
.searchContainer .react-datepicker__day-name,
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day-name,
.createContainer .react-datepicker__day {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 12px;
  line-height: 16px;
}
.searchContainer .react-datepicker__day--weekend:first-child,
.createContainer .react-datepicker__day--weekend:first-child {
  color: #ff5f5f;
}
.searchContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled,
.createContainer .react-datepicker__day--weekend:first-child.react-datepicker__day--disabled {
  opacity: 0.4;
}
.searchContainer .react-datepicker__day,
.createContainer .react-datepicker__day {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
}
.searchContainer .react-datepicker__day span,
.createContainer .react-datepicker__day span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled),
.createContainer .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  background-color: #42b6e6;
  color: #fff;
  border-radius: 100%;
}
.searchContainer .react-datepicker__day--outside-month,
.createContainer .react-datepicker__day--outside-month {
  visibility: hidden;
}
.searchContainer .react-datepicker__day--selected,
.createContainer .react-datepicker__day--selected {
  border-top-left-radius: 100% !important;
  border-bottom-left-radius: 100% !important;
  background-color: #ecf8fd;
}
.searchContainer .react-datepicker__day--selected span,
.createContainer .react-datepicker__day--selected span {
  background-color: #42b6e6;
  border-radius: 100%;
  color: #fff;
}
.searchContainer .react-datepicker__day--in-selecting-range,
.createContainer .react-datepicker__day--in-selecting-range {
  border-radius: 0;
  background-color: #ecf8fd;
  color: #1b1c13;
}
.searchContainer .react-datepicker__day--in-selecting-range:hover,
.createContainer .react-datepicker__day--in-selecting-range:hover {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  background-color: #ecf8fd !important;
}
.searchContainer .react-datepicker__day--in-selecting-range:hover span,
.createContainer .react-datepicker__day--in-selecting-range:hover span {
  border-radius: 100% !important;
  background-color: #42b6e6 !important;
}
.searchContainer .react-datepicker__day--range-start,
.createContainer .react-datepicker__day--range-start {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #ecf8fd;
}
.searchContainer .react-datepicker__day--range-start span,
.createContainer .react-datepicker__day--range-start span {
  border-radius: 100%;
  background-color: #42b6e6;
}
.searchContainer .react-datepicker__day--in-range,
.createContainer .react-datepicker__day--in-range {
  border-radius: 0;
  background-color: #ecf8fd;
  color: #1b1c13;
}
.searchContainer .react-datepicker__day--range-end,
.createContainer .react-datepicker__day--range-end {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}
.searchContainer .react-datepicker__day--range-end span,
.createContainer .react-datepicker__day--range-end span {
  background-color: #42b6e6;
  border-radius: 100%;
  color: #fff;
}

.createContainer {
  width: 328px;
  min-width: unset;
}
.createContainer .react-datepicker__month-container {
  width: 100%;
}
.createContainer .react-datepicker__month-container .react-datepicker__day--selected {
  border-radius: 100%;
  background-color: #42b6e6;
}
.createContainer .react-datepicker__week {
  width: 100%;
}

.modal .allchatMain {
  width: calc(100% - 32px);
  max-width: 1760px !important;
}
.modal #userDelete {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  font-size: 14px;
  color: #7e7e7e;
}
.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}
.modal .wrap {
  max-width: none;
}
.modal .modal-content {
  width: 460px;
}
.modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: none;
}
.modal .modal-content .modal-header .custom-modal-title {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  display: block;
  text-align: center;
}
.modal .modal-content .modal-header button {
  padding: 0;
}
.modal .modal-content .modal-header .right-close-btn {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-content.chatModal {
  width: 400px !important;
  height: 529px;
  top: 70px;
  right: 0;
  padding: 0;
  border-radius: 20px;
  border: none;
  overflow: hidden;
}
.modal .modal-content.chatModal .modal-header {
  border-bottom: 1px solid #e7e7e7;
  padding: 28px 28px 20px;
  align-items: center;
  margin-bottom: 0;
  height: 76px;
}
.modal .modal-content.chatModal .modal-header .modal-title {
  font-size: 16px;
  font-weight: 700;
  height: 28px;
  line-height: 28px;
}
.modal .modal-content.chatModal .modal-body {
  height: calc(100% - 76px);
}
.modal .modal-content.chatModal .modal-body .chat-scroll {
  height: 100%;
  overflow-y: auto;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 0;
}
.modal .modal-content.chatModal .modal-body .chat-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row {
  padding: 20px 28px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .chat-info {
  gap: 20px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right {
  display: flex;
  gap: 16px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .wishlistimg,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .wishlistimg {
  width: 48px;
  height: 48px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .user-name,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .user-name {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #42b6e6;
  margin-bottom: 8px !important;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .msg-content,
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right .msg-content {
  font-size: 14px;
  color: #1b1c13;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left {
  width: calc(100% - 40px);
  cursor: pointer;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .chat-message {
  width: calc(100% - 130px);
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .badge-with-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  align-items: end;
  width: 66px;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .badge {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 7px;
  min-width: 22px;
  height: 22px;
  background-color: #ff5f5f;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 8px !important;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-left .msg-time {
  font-size: 12px;
  color: #7e7e7e;
}
.modal .modal-content.chatModal .modal-body .chat-scroll .chat-list-row .modal-chat-right {
  gap: 20px;
  width: 20px;
  justify-content: end;
}
.modal .modal-content.chatModal .modal-chat-header {
  display: flex;
  height: 76px;
  margin-bottom: 0;
}
.modal .modal-content.chatModal .modal-chat-header .header-left {
  gap: 8px;
  align-items: center;
  width: calc(100% - 28px);
}
.modal .modal-content.chatModal .modal-chat-header .header-left .user-name {
  font-size: 16px;
  font-weight: 700;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .modal-content.chatModal .admin-msg-only {
  background-color: #ebebeb;
  padding: 20px 30px;
  border-radius: 0 0 20px 20px;
}
.modal .modal-content.chatModal .admin-msg-only p {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  color: #7e7e7e;
}
.modal .modal-content.chatModal .chat-scroll-two {
  height: calc(100% - 133px);
  overflow: hidden;
  padding: 0;
  font-size: 14px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat {
  height: 100%;
  overflow-y: scroll;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date {
  position: relative;
  font-size: 12px;
  color: #7e7e7e;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::before, .modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::after {
  content: "";
  display: block;
  position: absolute;
  width: 160px;
  height: 1px;
  background-color: #e7e7e7;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date::before {
  left: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .chat-date:after {
  right: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat {
  padding: 0 28px;
  gap: 8px;
  margin-bottom: 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .user-imgchat {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .font-blue {
  font-size: 14px;
  margin-bottom: 4px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .secChatHead {
  max-width: 208px;
  padding: 14px 16px;
  background: #efefef;
  border-radius: 0px 20px 20px 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .secChatHead p {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .single-chat {
  padding: 0;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .secUserChat .single-chat:first-child {
  margin-left: 48px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .custom-date-chat-two,
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .custom-date-chat {
  font-weight: 400;
  font-size: 10px;
  color: #7e7e7e;
  white-space: nowrap;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat {
  padding: 0 28px;
  margin-bottom: 20px;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat .chateContentMsg {
  max-width: 256px;
  padding: 14px 16px;
  background: #42b6e6;
  border-radius: 20px 0px 20px 20px;
  color: #fff;
}
.modal .modal-content.chatModal .chat-scroll-two .contentof-chat .single-chat .chateContentMsg p {
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.modal .modal-content.chatModal .modal-footer {
  min-height: 57px;
  padding: 17px;
}
.modal .modal-content.chatModal .modal-footer.submit-complet {
  margin: 0;
  padding: 16px 30px;
  border-top: 1px solid #dfdfdf !important;
}
.modal .modal-content.chatModal .modal-footer.submit-complet textarea {
  resize: none;
  font-size: 14px;
}
.modal .modal-content.chatModal .modal-footer.submit-complet .submit-chat {
  width: 100%;
  height: 100%;
  margin: 0;
}
.modal .tour-courses .modal-content .modal-header,
.modal .tour-courses .modal-content .modal-body {
  width: 100%;
}
.modal .custom-modal.terms-usecard, .modal .custom-modal.privacy-usecard {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .modal .custom-modal.terms-usecard, .modal .custom-modal.privacy-usecard {
    width: calc(100% - 32px);
    margin-left: 0;
  }
}
.modal .custom-modal.terms-usecard .modal-content, .modal .custom-modal.privacy-usecard .modal-content {
  width: 800px;
}
.modal .custom-modal.terms-usecard .modal-header, .modal .custom-modal.privacy-usecard .modal-header {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #7e7e7e;
}
.modal .custom-modal.terms-usecard .modal-header .custom-modal-title, .modal .custom-modal.privacy-usecard .modal-header .custom-modal-title {
  text-align: left;
}
.modal .custom-modal .modal-body {
  width: 100%;
}
.modal .custom-modal .body-scroll {
  height: 50vh;
  overflow-y: scroll;
  margin-top: 20px;
  padding-right: 20px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  height: 128px;
  width: 3px;
  border-radius: 0px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.modal .custom-modal .body-scroll::-webkit-scrollbar {
  width: 3px;
}
.modal .custom-modal .body-scroll h1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}
.modal .custom-modal .body-scroll p {
  font-size: 14px;
  word-break: keep-all;
}
.modal .custom-modal .body-scroll p:not(:last-child) {
  margin-bottom: 20px;
}
.modal .custom-modal .modal-content {
  padding: 50px 40px;
  border-radius: 20px;
}
.modal .custom-modal .modal-header {
  padding: 0;
}
.modal .custom-modal .modal-body {
  padding: 0;
}
.modal .custom-modal .modal-body .body-content {
  width: 380px;
  border-radius: 20px;
  background-color: rgba(126, 126, 126, 0.05);
  padding: 40px 20px;
  font-size: 14px;
  color: #7e7e7e;
  text-align: center;
  word-break: keep-all;
}
.modal .custom-modal .modal-body .body-content .body-title {
  font-weight: 700;
  font-size: 14px;
  color: #1b1c13;
  line-height: 25px;
  padding-bottom: 20px;
}
.modal .custom-modal .modal-body .body-content b {
  color: #1b1c13;
  font-weight: 500;
}
.modal .custom-modal .modal-body .body-content ul {
  margin-bottom: 0;
}
.modal .custom-modal .modal-body .body-content ul li {
  list-style-type: "∙ ";
  text-align: left;
  word-break: keep-all;
}
.modal .custom-modal .modal-body .pcReviewRating {
  margin: 30px auto;
}
.modal .custom-modal .modal-body .pcReviewRating > span {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: flex !important;
  gap: 4px !important;
  justify-content: center;
}
.modal .custom-modal .modal-body .pcReviewRating svg {
  width: 56px;
  height: 56px;
}
.modal .custom-modal .modal-body .mobileReviewRating {
  display: none;
}
.modal .custom-modal .modal-body textarea {
  resize: none;
  border-radius: 10px;
  border-color: #7e7e7e;
  padding: 30px 20px;
  font-size: 16px;
}
.modal .custom-modal .modal-body textarea::-moz-placeholder {
  color: #aeaeae;
}
.modal .custom-modal .modal-body textarea::placeholder {
  color: #aeaeae;
}
.modal .custom-modal .modal-body textarea:focus {
  outline: none;
}
.modal .custom-modal .modal-body .host-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #c9c9c9;
}
.modal .custom-modal .modal-body .host-info .user-local-host {
  gap: 16px;
}
.modal .custom-modal .modal-body .host-info .host-img {
  width: 54px;
  height: 54px;
  aspect-ratio: 1/1;
}
.modal .custom-modal .modal-body .host-info .join-pro {
  gap: 20px;
}
.modal .custom-modal .modal-body .host-info .join-pro input {
  display: none;
}
.modal .custom-modal .modal-body .host-info .join-pro svg {
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.modal .custom-modal .modal-body .host-info .name-with-flag {
  gap: 8px;
  margin-bottom: 8px;
}
.modal .custom-modal .modal-body .host-info .name-with-flag .flag-wrap {
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15));
}
.modal .custom-modal .modal-body .host-info .name-with-flag .user-name {
  font-size: 14px;
  font-weight: 700;
}
.modal .custom-modal .modal-body .host-info .host-tag {
  gap: 4px;
}
.modal .custom-modal .modal-body .host-info .host-tag > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  line-height: 16px;
  height: 26px;
  font-weight: 700;
  border-radius: 133.333px;
  border: 1px solid #1b1c13;
}
.modal .custom-modal .modal-body .userImgContentko {
  padding-top: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .host-info-title {
  font-size: 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
  line-height: 26px;
  margin-bottom: 12px;
}
.modal .custom-modal .modal-body .userImgContentko .now-hosting-blank {
  margin-top: 36px;
  justify-content: center;
  gap: 8px;
}
.modal .custom-modal .modal-body .userImgContentko .now-hosting-blank h6 {
  color: #aeaeae;
  font-size: 14px;
  font-weight: 400;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list {
  gap: 30px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div {
  right: 12px;
  top: 12px;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div input {
  display: none;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .imgArea .heart-div label {
  cursor: pointer;
  z-index: 1;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .hosting-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: keep-all;
  line-height: 22px;
  max-height: 44px;
  font-size: 16px;
  color: #1b1c13;
  font-weight: 700;
  margin-bottom: 6px;
  cursor: pointer;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .modalcountryname {
  width: 50%;
  font-size: 14px;
  color: #7e7e7e;
  height: 20px;
  line-height: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .modalcountryname p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .review-with-heart {
  gap: 4px;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 20px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .review-with-heart .star-review {
  gap: 4px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location {
  height: 24px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  font-size: 14px;
  color: #1b1c13;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .location .read-more-mobile {
  display: none;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 277px;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
@media (max-width: 767px) {
  .modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .createBy {
    width: 198px;
  }
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .modalImageContent .pax-font {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  right: 0;
}
.modal .custom-modal .modal-body .userImgContentko .hosting-list .date {
  font-size: 14px;
}
.modal .custom-modal .modal-body .userImgContentko .date-timeko .date {
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
}
.modal .custom-modal .modal-footer {
  margin-top: 50px;
  border-top: none;
  padding: 0;
  gap: 20px;
}
.modal .custom-modal .modal-footer button {
  width: calc(50% - 10px);
  height: 50px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 767px) {
  .modal .custom-modal {
    width: -moz-fit-content;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%) !important;
    margin: 0;
  }
  .modal .custom-modal .modal-content {
    min-width: 328px;
    width: calc(100vw - 32px);
    padding: 32px 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .modal .custom-modal .modal-content .modal-header {
    width: 100%;
    margin-bottom: 20px;
  }
  .modal .custom-modal .modal-content .modal-body .reviewRating span svg {
    width: 40px;
    height: 40px;
  }
  .modal .custom-modal .modal-content .modal-body textarea {
    font-size: 14px;
  }
  .modal .custom-modal .modal-content .modal-body .body-content {
    width: 100%;
    padding: 32px 20px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .host-img {
    width: 48px;
    height: 48px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .flag-wrap {
    width: 28px;
    height: 28px;
  }
  .modal .custom-modal .modal-content .modal-body .host-info .host-tag {
    gap: 4px;
  }
  .modal .custom-modal .modal-content .modal-body .join-pro {
    position: absolute;
    top: 0;
    right: 0;
    gap: 12px;
  }
  .modal .custom-modal .modal-content .modal-body .join-pro svg {
    width: 24px;
    height: 24px;
  }
  .modal .custom-modal .modal-content .modal-footer {
    width: 100%;
    margin-top: 20px;
  }
  .modal .custom-modal.review-modal .body-content {
    padding: 20px !important;
  }
  .modal .custom-modal.review-modal .reviewRating > span {
    gap: 0 !important;
  }
}

.tour-courses {
  width: calc(100% - 32px) !important;
  max-width: 1100px !important;
}
.tour-courses .modal-content {
  width: 100%;
  padding: 50px !important;
  margin: auto;
}
.tour-courses .modal-content .modal-header {
  width: 100%;
  margin-bottom: 20px;
}
.tour-courses .modal-content .modal-header h6 {
  font-weight: 700;
  font-size: 24px;
}
.tour-courses .modal-content .modal-body {
  color: #1b1c13;
}
.tour-courses .modal-content .modal-body .filter-title {
  font-size: 14px;
  display: block;
  min-width: 90px;
  line-height: 36px;
}
.tour-courses .modal-content .modal-body .top-serach-tour {
  padding: 20px 0;
  border-top: 1px solid #7e7e7e;
  border-bottom: 1px solid #7e7e7e;
}
.tour-courses .modal-content .modal-body .top-serach-tour .search-field:not(:last-child) {
  margin-bottom: 16px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title,
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  line-height: 20px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-title2 .font-blue {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  padding-top: 4px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items {
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items input:checked ~ label {
  border-color: #42b6e6;
  color: #42b6e6;
  font-weight: 700;
}
.tour-courses .modal-content .modal-body .top-serach-tour .filter-items label {
  height: 36px;
  padding: 0 16px;
  border: 1px solid #dcdcdc;
  border-radius: 133.333px;
  line-height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tour-courses .modal-content .modal-body .top-serach-tour input[type=radio],
.tour-courses .modal-content .modal-body .top-serach-tour input[type=checkbox] {
  display: none;
}
.tour-courses .modal-content .modal-body .search-keywords {
  padding: 20px 0;
}
.tour-courses .modal-content .modal-body .search-keywords input {
  width: calc(100% - 138px);
  border-radius: 133.333px;
  outline: none;
  border: 1px solid #dcdcdc;
  padding: 0 20px;
  height: 50px;
}
.tour-courses .modal-content .modal-body .search-keywords button {
  min-width: 110px;
  border-radius: 133.333px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.tour-courses .modal-content .modal-body .filter-results-items {
  display: grid;
  padding: 20px 0 40px;
  grid-gap: 20px 13px;
  grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card {
  overflow: hidden;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  border-radius: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .imgArea {
  width: 100%;
  aspect-ratio: 16/9;
  display: block;
  background-size: cover;
  background-position: center;
  position: unset;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details {
  position: unset;
  padding: 16px 16px 20px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details * {
  position: unset;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-title,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-region,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-category,
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-date {
  font-size: 14px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-title {
  font-weight: 700;
  margin-bottom: 8px !important;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .card-details .course-region {
  padding: 8px 0 20px;
  color: #7e7e7e;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check {
  top: 18px;
  right: 18px;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check input {
  display: none;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check input:checked ~ .check-marker {
  background-color: #42b6e6;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check .check-marker {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
}
.tour-courses .modal-content .modal-body .filter-results-items .course-card .tour-check .check-marker::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 14px;
  top: 4px;
  left: 8px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 1.5px;
}
.tour-courses .modal-content .modal-body .pagination {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #42b6e6;
  font-size: 14px;
  font-weight: 500;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom.activepage {
  border: 1px solid #42b6e6;
}
.tour-courses .modal-content .modal-body .pagination .page-link-custom:not(.page-arrow) {
  margin: 0 4px;
}
.tour-courses .modal-content .modal-body .pagination .page-arrow {
  visibility: hidden;
  position: relative;
}
.tour-courses .modal-content .modal-body .pagination .page-arrow:after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  visibility: visible;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}
.tour-courses .modal-content .modal-body .pagination .page-first-arrow:after {
  background-image: url("./img/first-arrow-color.svg");
}
.tour-courses .modal-content .modal-body .pagination .page-last-arrow:after {
  background-image: url("./img/last-arrow-color.svg");
}
.tour-courses .modal-content .modal-body .pagination .page-prev-arrow {
  margin-right: 6px;
  margin-right: 10px;
}
.tour-courses .modal-content .modal-body .pagination .page-prev-arrow:after {
  background-image: url("./img/prev-arrow-color.svg");
}
.tour-courses .modal-content .modal-body .pagination .page-next-arrow {
  margin-left: 6px;
  margin-right: 10px;
}
.tour-courses .modal-content .modal-body .pagination .page-next-arrow:after {
  background-image: url("./img/next-arrow-color.svg");
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-first-arrow:after {
  background-image: url("./img/first-arrow.svg");
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-last-arrow:after {
  background-image: url("./img/last-arrow.svg");
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-prev-arrow:after {
  background-image: url("./img/prev-arrow.svg");
}
.tour-courses .modal-content .modal-body .pagination .disabled .page-next-arrow:after {
  background-image: url("./img/next-arrow.svg");
}
.tour-courses .modal-content .modal-footer {
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  margin-top: 40px;
}
.tour-courses .modal-content .modal-footer button {
  width: 30%;
  margin: 0;
}
@media (max-width: 1920px) {
  .tour-courses .modal-content .modal-footer button {
    min-width: 296px;
  }
}
.tour-courses .modal-content .mobile-footer {
  display: none;
}
@media (max-width: 767px) {
  .tour-courses .modal-content .mobile-footer {
    display: block;
  }
}
.tour-courses .modal-content .res-button {
  display: none;
}
@media (max-width: 767px) {
  .tour-courses {
    width: 100% !important;
    margin: 0;
  }
  .tour-courses .modal-content {
    width: 100vw !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .tour-courses .modal-content .modal-header {
    height: 105px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .tour-courses .modal-content .modal-header .modal-title {
    flex-direction: column-reverse;
  }
  .tour-courses .modal-content .modal-header .modal-title .btn {
    text-align: right;
  }
  .tour-courses .modal-content .modal-header .modal-title h6 {
    padding: 0 16px;
    text-align: left;
    font-size: 18px;
    line-height: 49px;
    height: 49px;
  }
  .tour-courses .modal-content .modal-header button {
    height: 56px;
    padding: 0 16px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour {
    padding: 24px 16px;
    border-color: #d9d9d9 !important;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field {
    flex-direction: column;
    gap: 12px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field:not(:last-child) {
    margin-bottom: 24px !important;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-title2 {
    flex-direction: row;
    gap: 4px;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-title {
    font-size: 16px;
    font-weight: 700;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-items {
    gap: 8px;
    flex-wrap: wrap;
  }
  .tour-courses .modal-content .modal-body .top-serach-tour .search-field .filter-items label {
    font-size: 14px;
  }
  .tour-courses .modal-content .modal-body .search-keywords {
    padding: 24px 16px !important;
    gap: 8px;
  }
  .tour-courses .modal-content .modal-body .search-keywords input {
    min-width: calc(100% - 44px);
    max-height: 44px;
    font-size: 14px;
  }
  .tour-courses .modal-content .modal-body .search-keywords .search {
    min-width: 36px;
    min-height: 36px;
    height: 36px;
    aspect-ratio: 1/1;
    box-sizing: border-box;
  }
  .tour-courses .modal-content .modal-body .search-keywords .search span {
    display: none;
  }
  .tour-courses .modal-content .modal-body .filter-results-items {
    flex-direction: column;
    padding: 16px !important;
    gap: 16px;
  }
  .tour-courses .modal-content .modal-body .filter-results-items .course-card {
    width: 100% !important;
  }
  .tour-courses .modal-content .modal-body .filter-results-items .course-card .imgArea {
    width: 100%;
    aspect-ratio: 5/3 !important;
  }
  .tour-courses .modal-content .filter-total {
    padding: 0 16px;
    line-height: 16px;
    font-size: 16px !important;
    font-weight: 700;
  }
  .tour-courses .modal-content .filter-total span {
    font-size: 16px;
    line-height: 16px;
  }
  .tour-courses .modal-content .course-title {
    font-size: 16px !important;
  }
  .tour-courses .modal-content .course-region {
    padding: 0 0 8px !important;
  }
  .tour-courses .modal-content .course-category {
    padding-bottom: 4px;
  }
  .tour-courses .modal-content .pagination {
    display: none;
  }
  .tour-courses .modal-content .res-button {
    display: block;
    text-align: center;
    padding: 24px 16px 0;
  }
  .tour-courses .modal-content .res-button button {
    min-width: 328px;
    width: 100%;
    border-radius: 133.333px;
    height: 48px;
  }
  .tour-courses .modal-content .modal-footer {
    gap: 12px !important;
    padding: 0 16px;
    padding-bottom: 36px;
  }
  .tour-courses .modal-content .modal-footer button {
    min-width: unset !important;
    width: calc(50% - 6px) !important;
    border-width: 1px !important;
  }
}

@media (max-width: 1920px) {
  .tour-details-modal {
    min-width: 800px;
  }
}
.tour-details-modal .modal-content {
  width: 100%;
  padding: 50px;
  border-radius: 20px;
}
.tour-details-modal .modal-content .modal-header {
  width: 100%;
  align-items: center;
  background-blend-mode: 0 0 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-header {
    padding-bottom: 0;
  }
}
.tour-details-modal .modal-content .modal-header h6 {
  font-size: 24px;
  font-weight: 700;
}
.tour-details-modal .modal-content .modal-body table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.tour-details-modal .modal-content .modal-body table tr {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  display: table;
  min-height: 60px !important;
  height: -moz-max-content;
  height: max-content;
  background-color: #f9f9f9;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr {
    background-color: #fff;
  }
}
.tour-details-modal .modal-content .modal-body table tr th {
  display: inline-block;
  width: 180px;
  align-items: center;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr th {
    width: 100%;
  }
}
.tour-details-modal .modal-content .modal-body table tr th h6 {
  font-weight: 700;
  font-size: 14px;
}
.tour-details-modal .modal-content .modal-body table tr td {
  padding: 20px;
  display: inline-block;
  width: calc(100% - 180px);
  margin-right: 0 !important;
  background-color: #fff;
}
@media (max-width: 767px) {
  .tour-details-modal .modal-content .modal-body table tr td {
    width: 100%;
  }
}
.tour-details-modal .modal-content .modal-body table tr td h6,
.tour-details-modal .modal-content .modal-body table tr td p {
  color: #7e7e7e;
  font-weight: 400;
  font-size: 14px;
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.tour-details-modal .modal-content .modal-body table tr td .upload-pic {
  padding: 20px 0;
  gap: 7px;
}
.tour-details-modal .modal-content .modal-body table tr td .upload-pic .imgArea {
  width: calc(33.333% - 7px);
  aspect-ratio: 4/3;
  display: block;
}
@media (max-width: 767px) {
  .tour-details-modal {
    min-width: 328px;
    margin: 90px auto 0;
    margin-top: 0;
  }
  .tour-details-modal .modal-content {
    width: calc(100vw - 32px) !important;
    height: -moz-max-content;
    height: max-content;
    position: relative;
    overflow: hidden;
    top: 0;
    border-radius: 20px;
    padding: 30px 0;
    overflow-y: scroll;
  }
  .tour-details-modal .modal-content::-webkit-scrollbar {
    display: none;
  }
  .tour-details-modal .modal-content .modal-header {
    border-color: #e7e7e7;
    background-color: #fff;
    z-index: 1;
    margin-bottom: 0 !important;
  }
  .tour-details-modal .modal-content .modal-header .modal-title {
    align-items: center;
    padding-bottom: 8px;
  }
  .tour-details-modal .modal-content .modal-header .modal-title h6 {
    font-size: 16px;
  }
  .tour-details-modal .modal-content .modal-header .modal-title button {
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tour-details-modal .modal-content .modal-body {
    overflow-y: scroll;
    padding: 0 !important;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background: #c3c3c3;
    border-radius: 0;
  }
  .tour-details-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table {
    width: 100%;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr {
    display: block;
    min-height: 50px !important;
    padding: 20px 0 13px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th,
  .tour-details-modal .modal-content .modal-body .tour-details-table tr td {
    display: block;
    width: 100%;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    line-height: 20px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th h5,
  .tour-details-modal .modal-content .modal-body .tour-details-table tr td h5 {
    font-size: 14px;
    color: #1b1c13;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th {
    margin-bottom: 8px !important;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table tr th {
    background-color: transparent;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table .upload-pic {
    gap: 12px !important;
    padding: 8px 0 12px;
  }
  .tour-details-modal .modal-content .modal-body .tour-details-table .upload-pic .imgArea {
    overflow: hidden;
    border-radius: 8px;
    width: calc(50% - 6px);
  }
}

@media (max-width: 1920px) {
  .error-page {
    height: calc(100vh - 270px);
    display: flex;
    align-items: center;
  }
  .error-page label {
    width: 296px;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .error-page {
    height: calc(100vh - 210px);
  }
  .error-page label {
    width: calc(100vw - 32px);
    height: 48px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .modal .allchatmodal {
    width: 100vw !important;
    height: 100vh;
    left: 0;
  }
  .modal .allchatmodal .chatModal {
    width: 100% !important;
    height: 100vh !important;
    top: 0;
    border-radius: 0;
  }
  .modal .allchatmodal .chatModal .modal-header {
    flex-direction: column-reverse;
    padding: 0;
    height: 106px;
  }
  .modal .allchatmodal .chatModal .modal-header .footerClose {
    display: block;
    text-align: right;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .modal .allchatmodal .chatModal .modal-header .modal-title {
    display: block;
    padding: 0 16px;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header {
    flex-direction: row;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-right {
    display: none !important;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left {
    display: block !important;
    height: 106px;
    padding: 0 16px;
    gap: 0;
    width: 100%;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left svg {
    width: 28px;
    height: 28px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .chat-backgBtn {
    height: 56px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .w-100 {
    height: 56px;
  }
  .modal .allchatmodal .chatModal .modal-chat-header .header-left .user-name {
    height: 50px;
    line-height: 50px;
  }
}
@media (max-width: 1920px) {
  .roboto-text #Dashboard #searchBar .searchBtn {
    width: 134px;
  }
  .roboto-text #Dashboard #searchBar .search-calendar {
    min-width: 233px;
  }
  .roboto-text #Dashboard .searchContainer .backBtn {
    font-size: 14px;
    font-weight: 400;
  }
  .roboto-text .modalImageContent .hosting-title {
    line-height: 20px !important;
  }
}
@media (max-width: 767px) {
  .roboto-text.custom-modal .pcReviewRating {
    display: none;
  }
  .roboto-text.custom-modal .mobileReviewRating {
    display: block !important;
    margin: 10px auto !important;
  }
  .roboto-text.custom-modal textarea {
    padding: 10px 20px !important;
  }
}


.ReactModal__Overlay--after-open {
  z-index: 1500 !important;
}