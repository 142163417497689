@media (max-width: 1920px) {
  #Footer {
    background-color: #f9f9f9;
    padding: 60px;
    border-top: 1px solid #dcdcdc;
    height: 168px;
  }
  #Footer.main-footer {
    padding: 60px 0;
  }
  #Footer.main-footer .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
    padding-left: 304px;
  }
  #Footer h6 {
    font-weight: 400;
  }
  #Footer .footer-inner {
    gap: 48px;
  }
  #Footer .footer-inner .logo {
    width: 120px;
    display: block;
  }
  #Footer .footer-inner .logo svg path {
    fill: #42b6e6;
  }
  #Footer .footer-inner .list-of-links {
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
  }
  #Footer .footer-inner .list-of-links * {
    font-size: 14px;
    color: #1b1c13;
  }
  #Footer .footer-inner .list-of-links h6 {
    cursor: pointer;
  }
  #Footer .footer-inner .copy-section {
    font-size: 14px;
    color: #7e7e7e;
  }
}
@media (max-width: 767px) {
  #Footer {
    padding: 28px 16px 60px;
  }
  #Footer.main-footer {
    padding: 28px 16px 60px;
  }
  #Footer.main-footer .wrap {
    width: 100%;
    padding-left: 0;
  }
  #Footer .footer-inner {
    flex-wrap: wrap;
    margin: 0;
    gap: 16px;
    width: 100%;
  }
  #Footer .footer-inner .logo {
    width: 94px;
  }
  #Footer .footer-inner .copy-section {
    font-size: 12px;
  }
  #Footer .footer-inner .list-of-links {
    gap: 5px;
  }
}