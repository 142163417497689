@media (max-width: 1920px) {
  #Dashboard {
    margin-top: -70px;
  }
  #Dashboard .wrap {
    width: calc(100% - 32px);
    max-width: 1760px;
  }
  #Dashboard .main-banner {
    min-height: 330px;
    padding-top: 118px;
    padding-bottom: 54px;
    background-size: cover;
    background-position: center;
  }
  #Dashboard .main-banner h2 {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
    color: #fff;
    font-weight: 700;
    font-size: 46px;
    margin-bottom: 32px;
    line-height: 56px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Dashboard #searchBar {
    margin: 0 auto;
    max-width: 748px;
    min-height: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    border-radius: 133.333px;
    padding: 7px 7px 7px 24px;
  }
  #Dashboard #searchBar input {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  #Dashboard #searchBar input::-moz-placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar input::placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar .find-city-location {
    max-width: 349px;
    width: 50%;
  }
  #Dashboard #searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #Dashboard #searchBar .find-city-location svg {
    min-width: 20px;
    min-height: 20px;
  }
  #Dashboard #searchBar .find-city-location svg:last-child {
    margin-right: 30px;
  }
  #Dashboard #searchBar .find-city-location input {
    width: calc(100% - 70px);
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #Dashboard #searchBar .search-calendar {
    min-width: 255px;
  }
  #Dashboard #searchBar .search-calendar svg {
    min-width: 20px;
    min-height: 20px;
    margin-left: 30px;
  }
  #Dashboard #searchBar .search-calendar .dateArea {
    min-width: calc(100% - 50px);
  }
  #Dashboard #searchBar .search-calendar .dateArea input {
    width: 100%;
    padding: 0 8px;
  }
  #Dashboard #searchBar .searchBtn {
    min-width: 112px;
    min-height: 56px;
    border-radius: 133.333px;
    background-color: #42b6e6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    gap: 6px;
  }
  #Dashboard .main-contents-btn {
    gap: 20px;
    justify-content: center;
  }
  #Dashboard .main-contents-btn svg {
    width: 28px;
    height: 28px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents-btn button {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #ffffff;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 133.333px;
    min-height: 50px;
    padding: 0 20px;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
  }
  #Dashboard .mobileBtn {
    display: none;
  }
  #Dashboard .host-my-own.pcBtn {
    gap: 4px;
    position: absolute;
    border: 1px solid #ffffff;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 133.333px;
    width: 172px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    top: 50%;
    left: calc(50% + 472px);
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
  #Dashboard .viewHostBtn {
    width: 83px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    border-radius: 133.333px;
  }
  #Dashboard .main-contents {
    background-color: #fafafa;
    padding-top: 28px;
    padding-bottom: 90px;
  }
  #Dashboard .main-contents .layout {
    gap: 40px;
  }
  #Dashboard .main-contents .layout .main-left {
    color: #1b1c13;
    min-width: 264px;
    max-width: 264px;
  }
  #Dashboard .main-contents .layout .main-left > * {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
    padding: 20px;
  }
  #Dashboard .main-contents .layout .main-left .heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }
  #Dashboard .main-contents .layout .main-left .container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 16px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-number {
    font-size: 14px;
    font-weight: 700;
    width: 28px;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-pro-content {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    gap: 16px;
    color: #1b1c13;
    height: 40px;
    cursor: pointer;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-img {
    width: 36px;
    height: 36px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content {
    width: calc(100% - 92px);
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
    line-height: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h6 {
    font-size: 12px;
    color: #7e7e7e;
    line-height: 16px;
    max-height: 16px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-flag {
    width: 24px;
    height: 24px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  }
  #Dashboard .main-contents .layout .main-left .trending-now-section {
    margin-bottom: 20px;
  }
  #Dashboard .main-contents .layout .main-left .popular-host-section .position-absolute {
    right: 0;
  }
  #Dashboard .main-contents .layout .main-right {
    width: calc(100% - 286px);
  }
  #Dashboard .main-contents .layout .main-right .pagination {
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
    gap: 4px;
  }
  #Dashboard .main-contents .layout .main-right .pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Dashboard .main-contents .layout .main-right .pagination li a {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    color: #7e7e7e;
    box-sizing: border-box;
  }
  #Dashboard .main-contents .layout .main-right .pagination li.active {
    border-radius: 100%;
    border: 1px solid #42b6e6;
  }
  #Dashboard .main-contents .layout .main-right .pagination li.active a {
    color: #42b6e6;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll {
    margin-bottom: 28px;
    height: 36px;
    overflow: hidden;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn,
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn {
    position: absolute;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn:before {
    content: "";
    display: block;
    position: absolute;
    width: 98px;
    height: 36px;
    left: -1px;
    top: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 41.11%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .prevBtn svg {
    z-index: 1;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn {
    right: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn:before {
    content: "";
    display: block;
    position: absolute;
    width: 98px;
    height: 36px;
    right: -1px;
    top: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 41.11%);
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .nextBtn svg {
    z-index: 1;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list {
    gap: 8px;
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    transition: all 3s ease 0s;
    left: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-max-content;
    width: max-content;
    box-sizing: border-box;
    font-weight: 400;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-list button.active {
    background-color: #1b1c13;
    color: #fff;
    font-weight: 700;
    border: none;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider {
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-prev {
    display: none !important;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list {
    overflow: hidden;
    position: relative;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 98px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) -10%, #ffffff 55%);
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track {
    display: flex;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track .slick-slide {
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-right: 8px;
    cursor: pointer;
  }
  #Dashboard .main-contents .layout .main-right .slick-slider .slick-list .slick-track .slick-slide.slick-active button {
    background-color: #1b1c13 !important;
    color: #fff;
    opacity: 1;
  }
  #Dashboard .main-contents .layout .main-right .content-card-tour-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members-item {
    width: 100%;
    min-height: 160px;
    text-align: center;
    justify-content: center;
  }

  #Dashboard .main-contents .layout .main-right .content-card-tour-list:not(:last-child) {
    margin-bottom: 50px;
  }
  #Dashboard .searchContainer {
    z-index: 2;
    padding: 40px;
  }
  #Dashboard .searchContainer .custom-container {
    gap: 38px;
  }
}
@media (max-width: 767px) {
  #Dashboard {
    margin-top: 0;
  }
  #Dashboard #searchBar {
    width: 80%;
    max-width: 80%;
    padding: 6px 6px 6px 12px;
  }
  #Dashboard #searchBar.searchBar {
    min-width: 328px;
    position: relative;
    min-height: 48px;
    margin-bottom: 20px;
  }
  #Dashboard #searchBar.searchBar input {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #1b1c13;
  }
  #Dashboard #searchBar.searchBar input::-moz-placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar.searchBar input::placeholder {
    color: #aeaeae;
  }
  #Dashboard #searchBar.searchBar .find-city-location {
    min-width: 138px;
    width: 50%;
  }
  #Dashboard #searchBar.searchBar .find-city-location:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background-color: #e7e7e7;
  }
  #Dashboard #searchBar.searchBar .find-city-location svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
  }
  #Dashboard #searchBar.searchBar .find-city-location svg:last-child {
    margin-right: 12px;
  }
  #Dashboard #searchBar.searchBar .find-city-location input {
    width: calc(100% - 48px);
    margin-left: 4px;
    padding: 0;
  }
  #Dashboard #searchBar.searchBar .search-calendar {
    min-width: 138px !important;
    width: 50%;
  }
  #Dashboard #searchBar.searchBar .search-calendar svg {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    aspect-ratio: 1/1;
    margin-left: 12px;
    margin-right: 4px;
  }
  #Dashboard #searchBar.searchBar .search-calendar .dateArea {
    width: calc(100% - 32px);
  }
  #Dashboard #searchBar.searchBar .search-calendar .dateArea input {
    padding: 0;
    min-width: unset;
  }
  #Dashboard #searchBar.searchBar .searchIcon {
    min-width: 36px !important;
    min-height: 36px !important;
    max-width: 36px !important;
    max-height: 36px !important;
    background-color: #42b6e6;
    border-radius: 100%;
  }
  #Dashboard #searchBar.searchBar .searchIcon span {
    display: none;
  }
  #Dashboard #searchBar.searchBar .searchIcon svg {
    width: 30px;
    height: 30px;
    aspect-ratio: 1/1;
  }
  #Dashboard #searchBar.searchBar .searchBtn {
    min-width: 56px;
  }
  #Dashboard .main-banner-contents h2 {
    display: none;
  }
  #Dashboard .main-banner {
    padding: 56px 0 36px;
    min-height: unset;
  }
  #Dashboard .main-contents-btn {
    gap: 8px;
    justify-content: center;
  }
  #Dashboard .main-contents-btn svg {
    width: 20px;
    height: 20px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents-btn button {
    width: 130px;
    gap: 2px;
    min-height: 32px;
    font-size: 12px;
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    border: 0.8px solid #ffffff;
    -webkit-backdrop-filter: blur(1.65335px);
            backdrop-filter: blur(1.65335px);
  }
  #Dashboard .main-contents {
    padding: 0;
  }
  #Dashboard .main-contents .layout {
    background-color: #f9f9f9;
    width: 100%;
    flex-direction: column-reverse;
    gap: 12px;
  }
  #Dashboard .main-contents .layout .main-left,
  #Dashboard .main-contents .layout .main-right {
    width: 100%;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll {
    padding: 16px !important;
    padding-right: 0;
    background-color: #fff;
    width: 100vw;
    position: sticky;
    top: 56px;
    z-index: 1;
    margin-bottom: 0;
    height: 68px;
    gap: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .d-flex {
    gap: 8px;
    width: -moz-max-content;
    width: max-content;
    padding-right: 16px;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .d-flex .btn-mainTab.active {
    background-color: #1b1c13;
    color: #fff;
    font-weight: 700;
    border: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .slick-next {
    right: 16px;
    display: none !important;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .slick-list:after {
    display: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class {
    overflow-x: auto;
    height: 58px;
    padding: 16px 16px 0;
    background-color: #fff;
    grid-gap: 24px;
    gap: 24px;
    z-index: 1;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class::-webkit-scrollbar {
    display: none;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class li {
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding-bottom: 16px;
    font-size: 14px;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-ul-class li.tab-card-active:after {
    content: "";
    bottom: 0;
  }
  #Dashboard .main-contents .layout .main-right .tour-list-scroll .tab-line {
    margin-top: -2px;
  }
  #Dashboard .main-contents .layout .main-right .mobile-card {
    width: 100%;
    padding: 0 16px 20px;
    background-color: #fff;
  }
  #Dashboard .main-contents .layout .main-right .mobile-card .content-card-tour-list {
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    gap: 32px;
  }

  .popular-members {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 30.63px 24px;
  }

  .popular-members-item {
    width: 100%;
    min-height: 60px;
    text-align: center;
    justify-content: center;
  }

  #Dashboard .main-contents .layout .main-right .mobile-card .content-card-tour-list .tour-card {
    min-height: unset;
  }
  #Dashboard .main-contents .layout .main-left {
    width: 100%;
    max-width: unset;
  }
  #Dashboard .main-contents .layout .main-left > * {
    border-radius: 0;
    padding: 20px 16px;
  }
  #Dashboard .main-contents .layout .main-left .trending-now-section {
    margin-bottom: 12px;
  }
  #Dashboard .main-contents .layout .main-left .heading {
    padding: 0;
    border-bottom: none;
  }
  #Dashboard .main-contents .layout .main-left .container {
    padding: 0;
    margin-top: 20px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row,
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    border-radius: 20px;
    border-radius: 8px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row {
    padding: 0;
    gap: 12px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-number {
    font-size: 16px;
    width: 30px;
  }
  #Dashboard .main-contents .layout .main-left .container .single-trending-row .trending-pro-content {
    font-size: 14px;
    font-weight: 700;
    color: #1b1c13;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row {
    padding: 0;
    height: 40px;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-img {
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-host-flag {
    width: 25.45px;
    height: 25.45px;
    aspect-ratio: 1/1;
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content {
    width: calc(100% - 96.45px);
  }
  #Dashboard .main-contents .layout .main-left .container .popular-host-row .popular-pro-content h6 {
    font-size: 14px;
  }
  #Dashboard .main-contents .layout .mobile-more-btn {
    width: 100%;
    height: 44px;
    font-weight: 700;
    font-size: 14px;
    margin: 20px 0 12px;
  }
  #Dashboard .searchContainer {
    width: 100%;
    min-width: unset;
    top: 50px;
    padding: 20px;
  }
  #Dashboard .searchContainer .country_list label:hover {
    color: inherit !important;
    border-color: #dcdcdc;
    font-weight: inherit;
  }
  #Dashboard .pc-calendar {
    display: none;
  }
  #Dashboard .mobile-calendar {
    display: block !important;
  }
  #Dashboard .mobileBtn {
    display: flex;
    gap: 8px;
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
  }
  #Dashboard .mobileBtn button {
    border: 1px solid #fff;
    -webkit-backdrop-filter: blur(1.65335px);
            backdrop-filter: blur(1.65335px);
    width: 130px;
    height: 32px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 133.333px;
  }
  #Dashboard .pcBtn {
    display: none !important;
  }
}