@media (max-width: 1920px) {
  .login-modal .modal-content,
  .forgot-modal .modal-content {
    padding: 50px 30px !important;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }
  .login-modal .modal-content .modal-header,
  .forgot-modal .modal-content .modal-header {
    padding: 0;
    height: 24px;
  }
  .login-modal .modal-content .modal-header .mobileClose,
  .forgot-modal .modal-content .modal-header .mobileClose {
    display: none;
  }
  .login-modal .modal-content .modal-header .modal-title,
  .forgot-modal .modal-content .modal-header .modal-title {
    text-align: center;
  }
  .login-modal .modal-content .input-title,
  .forgot-modal .modal-content .input-title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 20px;
    display: block;
  }
  .login-modal .modal-content .input-group,
  .forgot-modal .modal-content .input-group {
    height: 88px;
  }
  .login-modal .modal-content .input-group:not(.mb-30),
  .forgot-modal .modal-content .input-group:not(.mb-30) {
    margin-bottom: 40px;
  }
  .login-modal .modal-content .input-group.mb-30,
  .forgot-modal .modal-content .input-group.mb-30 {
    margin-bottom: 30px;
  }
  .login-modal .modal-content input,
  .forgot-modal .modal-content input {
    border-radius: 133.333px;
    border: 1px solid #dcdcdc;
    width: 100%;
    padding: 0 20px;
    height: 50px;
    font-size: 14px;
  }
  .login-modal .modal-content input::-moz-placeholder, .forgot-modal .modal-content input::-moz-placeholder {
    color: #aeaeae;
  }
  .login-modal .modal-content input::placeholder,
  .forgot-modal .modal-content input::placeholder {
    color: #aeaeae;
  }
  .login-modal .modal-content .radio-row,
  .forgot-modal .modal-content .radio-row {
    margin: 30px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    font-weight: 700;
    color: #666;
  }
  .login-modal .modal-content .radio-row input,
  .forgot-modal .modal-content .radio-row input {
    display: none;
  }
  .login-modal .modal-content .radio-row .checkmark,
  .forgot-modal .modal-content .radio-row .checkmark {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-modal .modal-content .radio-row .checkmark svg,
  .forgot-modal .modal-content .radio-row .checkmark svg {
    width: 18px;
    height: 18px;
  }
  .login-modal .modal-content .radio-row > label,
  .forgot-modal .modal-content .radio-row > label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .login-modal .modal-content button,
  .forgot-modal .modal-content button {
    font-size: 14px;
    font-weight: 700;
  }
  .login-modal .modal-content .loginBtn,
  .forgot-modal .modal-content .loginBtn {
    margin: 40px 0;
    height: 50px;
  }
  .login-modal .modal-content .have-account-row,
  .forgot-modal .modal-content .have-account-row {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .login-modal .modal-content .body-content,
  .forgot-modal .modal-content .body-content {
    text-align: center;
    margin-bottom: 20px;
    padding: 28px 20px !important;
  }
  .login-modal .modal-content .login-form-error,
  .login-modal .modal-content .forgot-form-error,
  .forgot-modal .modal-content .login-form-error,
  .forgot-modal .modal-content .forgot-form-error {
    position: relative;
    color: #ce0000;
    font-size: 12px;
    margin-top: 4px;
    left: 20px;
  }
  .login-modal .modal-content .loading-dim,
  .forgot-modal .modal-content .loading-dim {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
  }
  .login-modal .modal-content .loading-dim .img-box,
  .forgot-modal .modal-content .loading-dim .img-box {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .login-modal .modal-content .loading-dim .img-box h5,
  .forgot-modal .modal-content .loading-dim .img-box h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #42b6e6;
    text-align: center;
  }
  .login-modal .btn-successBtn,
  .forgot-modal .btn-successBtn {
    margin-top: 20px !important;
  }
  .login-modal .btn-no-line,
  .forgot-modal .btn-no-line {
    height: 16px;
    padding: 0;
  }
  .login-modal button:not(.btn-no-line),
  .forgot-modal button:not(.btn-no-line) {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .modal.login-modal .custom-modal {
    margin: 0;
    padding: 0 !important;
  }
  .modal.login-modal .custom-modal .modal-content {
    width: 100vw;
    height: 100vh;
    padding: 0 16px !important;
    border-radius: 0;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header {
    height: 112px;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .pcClose {
    display: none;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .mobileClose {
    display: flex;
    height: 56px;
    align-items: center;
  }
  .modal.login-modal .custom-modal .modal-content .modal-header .custom-modal-title {
    text-align: left;
    display: flex;
    align-items: center;
    height: 56px;
  }
  .modal.login-modal .custom-modal .modal-content .input-title {
    padding-bottom: 8px;
  }
  .modal.login-modal .custom-modal .modal-content .input-group {
    height: 72px;
  }
  .modal.login-modal .custom-modal .modal-content .input-group:not(.mb-30) {
    margin-bottom: 24px;
  }
  .modal.login-modal .custom-modal .modal-content .radio-row {
    margin: 24px auto;
    gap: 40px;
  }
  .modal.login-modal .custom-modal .modal-content .radio-row label {
    gap: 8px;
  }
  .modal.login-modal .custom-modal .modal-content .forgotBtn {
    height: 20px;
  }
  .modal.login-modal .custom-modal .modal-content .loginBtn {
    margin: 24px 0;
  }
  .modal.login-modal .custom-modal .modal-content .login-form-error,
  .modal.login-modal .custom-modal .modal-content .forgot-form-error {
    font-size: 10px;
  }
  .modal.login-modal .custom-modal .modal-content .modal-body {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .modal.login-modal .custom-modal .modal-content input {
    height: 44px;
  }
  .modal.login-modal .custom-modal .modal-content button:not(.btn-no-line) {
    height: 48px;
  }
  .modal.login-modal .custom-modal .modal-content .body-content {
    padding: 20px !important;
  }
}